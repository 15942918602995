import {
  FormControl,
  TextField,
  TextFieldProps,
  useTheme
} from "@mui/material";
import React from "react";
// import { useTranslation } from "react-i18next";
import { InputBaseComponentInterface } from "./types";

const InputCst: React.FC<TextFieldProps & InputBaseComponentInterface> = (
  props
) => {
  const theme = useTheme();
  // const { t } = useTranslation(["input"]);

  const {
    id,
    label,
    value,
    onChange,
    customWidth,
    error = false,
    errorMessage = "",
    disabled = false,
    sx,
    maxValue,
    max,
    endAdornment
  } = props;

  return (
    <FormControl
      variant="standard"
      sx={ {
        "& .MuiFormLabel-root": {
          "&[data-shrink='true']": {
            maxWidth: "calc(133% - 40px)"
          },
          color: theme.customColors.textPrimary,
          maxWidth: "calc(100% - 40px)"
        },
        "& .MuiInputBase-root fieldset": {
          borderColor: theme.customColors.border
        },
        "& .MuiInputBase-root.Mui-focused fieldset": {

          borderColor: theme.customColors.borderPrimaryCta
        },
        "& .MuiInputLabel-root.Mui-focused.MuiInputLabel-shrink": {
          color: theme.customColors.borderPrimaryCta
        },
        alignItems: "center",
        display: "flex",
        minWidth: "200px",
        width: customWidth ? customWidth : "auto",
        ...sx
      } }
    >
      <TextField
        { ...props }
        sx={ {
          width: "100%"
        } }
        size={ "medium" }
        error={ error }
        helperText={
          <span
            style={ {
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 2
            } }
          >
            { error ? errorMessage : "" }
          </span>
        }
        id={ id }
        label={ label }
        InputProps={ {
          endAdornment: endAdornment
        } }
        value={ value }
        onChange={ onChange }
        disabled={ disabled }
        inputProps={ {
          max: max,
          maxLength: maxValue,
          min: 0
        } }
      />
    </FormControl>
  );
};

export default InputCst;
