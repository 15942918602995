import { Button as MuiButton, darken } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ButtonProps } from "./Button.types";


const StyledButton = styled(MuiButton)<ButtonProps>(({ theme, variant }) => ({
  alignItems: "center",
  borderRadius: theme.spacing(1),/*(variant === "contained" || variant === "outlined") ? "2px" : "4px",*/
  color: (variant === "contained")
    ? theme.customColors.textPrimaryCta
    : theme.customColors.backgroundPrimaryCta,
  display: "flex",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: 1.5,
  minWidth: "128px",
  padding: theme.spacing(1, 2),
  textTransform: "inherit",
  background: (variant === "contained") ? theme.customColors.backgroundPrimaryCta : "transparent",
  "&:hover": {
    backgroundColor: (variant === "text" ? "transparent" : darken(theme.customColors.backgroundPrimaryCta, 0.4))
  },
  "&.MuiButton-outlinedPrimary": {
    "&:hover": {
      backgroundColor: theme.customColors.backgroundPrimaryCta,
      color: theme.customColors.textPrimaryCta,
      "path":{
        "fill": theme.customColors.textPrimaryCta
      }
    }
  },
  "&.MuiButton-contained": {
    "path":{
      "fill": theme.customColors.textPrimaryCta
    }
  },

  "&.Mui-disabled": {
    background:theme.customColors.backgroundDisabled,
    color:theme.customColors.textDisabledAlternative,
    cursor:"not-allowed",
    pointerEvents:"auto"
  }
}));

export function Button({ ...props }: ButtonProps) {
  return (
    <StyledButton { ...props } />
  );
}
