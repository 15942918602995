import {
  useQuery,
  useMutation,
  // useQueryClient,
  type QueryKey,
  useQueryClient,
  useInfiniteQuery
} from "@tanstack/react-query";
import {
  LEVEL,
  ERRORS,
  STATUS_LO
} from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import {
  useAuthStore,
  useCoverStore
} from "../stores";
import type {
  Badge,
  ChatBotGetRes,
  ChatBotRes,
  Course,
  Edition,
  enrollType,
  eventItem,
  LearningActivity,
  LearningObject,
  Level,
  MetaData,
  Root,
  SessionEdition,
  StatusLO,
  UserAchievement,
  UserAchievementsRaw
} from "../types";
import {
  mapUserAchievements,
  sortUserAchievements
} from "../utils/achievements";
import { getTimezoneFormattedDate } from "../utils/datetime";
import {
  getEnrollmentType,
  getEnvironmentVariables,
  replacePathParams
} from "../utils/general";


type ContentResponse =
  & { [items: string]: Record<string, unknown>[] }
  & { metadata?: MetaData }

export type Format = {
  cover: string
  description: string
  id: number
  identifier: string
  label: string
}

type Teacher = {
  completeName: string
  description: string
  email: string
  image: string
}

type Topic = {
  icon: string
  id: number
  learning_object_associated: number
  name: string
  order: number
  url: {
    private_url: string
    public_url: string
  }
}

const { basePath } = getEnvironmentVariables();

//const { xapiChatBot } = getEnvironmentVariables();

const {
  aryannaUri
} = getEnvironmentVariables();

export function useChatBotQuery({
  client= "digit'ed",
  conversation = [],
  domain = "demo1",
  password = "272jpmFcdnmoiBoyYElNfZMasfoNo8zTySaSAaHUI7HFenRoIJ6ge",
  question,
  session_id = "string",
  username = "acngpt3",
  enabled=true,
  queryKey=[]

} : {
  client?:string
  conversation?:Record<string, string>[] | []
  domain?:string,
  password?:string
  question:string
  session_id?:string,
  username?:string,
  enabled?: boolean
  queryKey?: string[]
}) {
  const fetcher = fetcherFactory();

  const apiPath = "https://dynamic-webapp-be.azurewebsites.net/chatbot/";
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const body = {
    client: client ,
    conversation:conversation ,
    domain: domain,
    password: password,
    question: question,
    session_id: session_id ,
    username: username
  };

  return useQuery<
    unknown,
    unknown,
    ChatBotRes["response"],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && question
      && corporateId
      && initiativeId
      && idToken
      && sessionToken
    ),
    queryFn: () => fetcher(
      `${apiPath}`,
      {
        body:JSON.stringify(body),
        headers: {
          "accept": "application/json",
          "Content-Type": "application/json"
        },
        method:"POST"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ChatBotRes) => {
        return data.response;
      })
      .catch((error) => {

        throw error;

      }),
    queryKey: [apiPath, corporateId, initiativeId, idToken, sessionToken, { ...body }, ...queryKey]
  });
}

export function useGetChatBotQuery({
  question,
  topic,
  enabled = true,
  queryKey=[]
} : {
  question: string,
  topic: string,
  enabled?: boolean,
  queryKey?: string[]
}) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const userData = useAuthStore(state => state.userData);

  const uri = aryannaUri;
  // const params = `id_user=${userData?.id_user}`;
  const params = `ol_student_id=${userData?.id_user}&ol_corporate_id=${userData?.organization_id}&ol_domain=${userData?.initiative_id}`;

  const apiPath = `${uri}?${params}`;

  const body ={
    prompt: encodeURIComponent(question),
    topic: encodeURIComponent(topic)
  };

  return useQuery<
    unknown,
    unknown,
    ChatBotGetRes,
    QueryKey
  >({
    enabled,
    retry: 0,
    queryFn: () => fetcher(
      `${apiPath}`,
      {
        body: JSON.stringify(body),
        headers: {
          "authorization": idToken,
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ChatBotGetRes) => {
        return apiAdapter([data])[0];
      })
      .catch((error) => {
        const err = JSON.parse(error);
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else if (err?.detail?.code === ERRORS.ERR_DATASET_EMPTY){
          const notFound = {
            answer: {
              text: err.detail.error
            }
          };

          return notFound;
        }
      }),
    queryKey: [sessionToken, uri, params, { ...body }, ...queryKey]
  });
}


export function useAchievementsQuery({
  enabled=true,
  queryKey=[],
  pageNumber=0,
  pageSize=9
} : {
  enabled?: boolean
  queryKey?: string[]
  pageNumber?: number
  pageSize?: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);


  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learnerBadges`;
  const params = `pageNumber=${pageNumber}&pageSize=${pageSize}`;

  return useQuery<
    unknown,
    unknown,
    UserAchievement[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
    ) && !isSeedingSession,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: { badges: Badge[], certificates: Badge[] }) => {
        return {
          badges: apiAdapter(data.badges) as Badge[],
          certificates: apiAdapter(data.certificates) as Badge[]
        };
      })
      .then((data: UserAchievementsRaw) => {
        return mapUserAchievements({
          hasBadges: Boolean(data?.badges && data.badges.length > 0),
          hasCertificates: Boolean(data?.certificates && data.certificates.length > 0),
          rawAchievements: data
        });
      })
      .then((data: UserAchievement[]) => {
        return sortUserAchievements(data);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, params, isSeedingSession, ...queryKey]
  });
}


export function useEditionsQuery({
  classroomId,
  enabled=true,
  queryKey=[]
} : {
  classroomId: string
  enabled?: boolean
  queryKey?: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/v3/${corporateId}/${initiativeId}/classrooms/${classroomId}/editions`;

  return useQuery<
    unknown,
    unknown,
    Edition[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && classroomId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: { editions: Edition[]}) => {
        return apiAdapter(data.editions);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, ...queryKey]
  });
}
export function useInvalidateEditionsQuery({
  classroomId,
  queryKey=[]
} : {
  classroomId: string
  queryKey?: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const queryClient = useQueryClient();


  const uri = `/learning-catalogue/v3/${corporateId}/${initiativeId}/classrooms/${classroomId}/editions`;

  const invalidateEditions = ()=> {
    queryClient.invalidateQueries({
      queryKey:  [accessToken, sessionToken, uri, ...queryKey]
    });
    window.scrollTo(0, 0);
  };

  return { invalidateEditions };
}

export function useEditionSessionsQuery({
  editionId,
  type,
  enabled=true,
  queryKey=[]
} : {
  editionId: string
  type: string
  enabled?: boolean
  queryKey?: string[]
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();
  
  const getSessionType = () => {
    switch (type) {
    case LEVEL.DA_PHYSICAL_CLASS:
      return "physicalSessions";
    case LEVEL.DA_VIRTUAL_CLASS:
      return "virtualSessions";
    }
  };

  const uri = `/learning-catalogue/v3/${corporateId}/${initiativeId}/classrooms/${getSessionType()}/${editionId}`;

  return useQuery<
    unknown,
    unknown,
    SessionEdition[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && editionId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: { sessions: SessionEdition[]}) => {
        return apiAdapter(data.sessions);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, ...queryKey]
  });
}

export function useLearningObjectQuery({
  courseId=null,
  enabled=true,
  id,
  learningObjectTypology,
  onlyLearningActivity=false,
  queryKey=[],
  placeholderData,
  rootId=null,
  status=null
} : {
  courseId?: number | null
  enabled?: boolean
  id: number
  learningObjectTypology?: Level
  onlyLearningActivity?: boolean
  queryKey?: string[]
  placeholderData?: LearningActivity | Course | Root
  rootId?: number | null
  status?: StatusLO | null
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  // const queryClient = useQueryClient();
  const fetcher = fetcherFactory();

  const level = getDetailsLevel(learningObjectTypology);
  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/${level}/${id}`;
  const params = learningObjectTypology ? getParams(learningObjectTypology, status) : "";

  return useQuery<
    unknown,
    unknown,
    LearningActivity | Course | Root,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && learningObjectTypology
      && id
    ),
    placeholderData,
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken,
          "x-user-timezone": getTimezoneFormattedDate()
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: LearningActivity | Course | Root) => {
        return apiAdapter([data])[0];
      })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, params, ...queryKey],
    staleTime:1 * 60 * 1000
  });

  function getParams(learningObjectTypology: Level, status: StatusLO | null) {
    if (learningObjectTypology === LEVEL.COURSE) {
      return `onlyLearningActivity=${onlyLearningActivity}&rootId=${rootId ?? ""}`;
    } else if (learningObjectTypology === LEVEL.PATH) {
      const apiStatus = getApiStatus(status);
      // eslint-disable-next-line max-len
      return `onlyLearningActivity=${onlyLearningActivity}&status=${apiStatus}`;
    } else {
      return `onlyLearningActivity=${onlyLearningActivity}&rootId=${rootId ?? ""}&courseId=${courseId ?? ""}`;
    }
  }
}

export function useInvalidateLearningObjectQuery({
  courseId=null,
  id,
  learningObjectTypology,
  onlyLearningActivity=false,
  queryKey=[],
  rootId=null,
  status=null
} : {
  courseId?: number | null
  id: number
  learningObjectTypology?: Level
  onlyLearningActivity?: boolean
  queryKey?: string[]
  rootId?: number | null
  status?: StatusLO | null
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const queryClient = useQueryClient();


  const level = getDetailsLevel(learningObjectTypology);
  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/${level}/${id}`;
  const params = learningObjectTypology ? getParams(learningObjectTypology, status) : "";


  const invalidateLearningObject = ()=> {

    queryClient.invalidateQueries({
      queryKey: [accessToken, sessionToken, uri, params, ...queryKey]
    });
  };

  return { invalidateLearningObject };


  function getParams(learningObjectTypology: Level, status: StatusLO | null) {
    if (learningObjectTypology === LEVEL.COURSE) {
      return `onlyLearningActivity=${onlyLearningActivity}&rootId=${rootId ?? ""}`;
    } else if (learningObjectTypology === LEVEL.PATH) {
      const apiStatus = getApiStatus(status);
      // eslint-disable-next-line max-len
      return `onlyLearningActivity=${onlyLearningActivity}&status=${apiStatus}`;
    } else {
      return `onlyLearningActivity=${onlyLearningActivity}&rootId=${rootId ?? ""}&courseId=${courseId ?? ""}`;
    }
  }
}

export function useRelatedLearningObjectQuery({
  enabled=true,
  learningObjectId,
  queryKey=[],
  pageNumber=0,
  pageSize=9
}: {
  enabled?: boolean
  learningObjectId: string
  queryKey?: string[]
  pageNumber?: number
  pageSize?: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();

  const uri =
    `/learning-catalogue/${corporateId}/${initiativeId}/learningObjects/${learningObjectId}/relatedLearningActivities`;
  const params = `pageNumber=${pageNumber}&pageSize=${pageSize}`;

  return useQuery<
    unknown,
    unknown,
    LearningObject[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && learningObjectId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken,
          "x-user-timezone": getTimezoneFormattedDate()
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: { relatedLearningActivities: LearningObject[]}) => {
        return apiAdapter(data.relatedLearningActivities);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, ...queryKey]
  });
}
export function useInfiniteRelatedLearningObjectQuery<T extends { metadata: MetaData }>({
  enabled=true,
  pageSize=9,
  path,
  queryKey=[],
  ...otherParams
} : {
  enabled?: boolean
  pageSize?: number
  path: string
  queryKey?: Array<number | string>
} & Record<string, unknown>) {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();

  const apiPath = getBaseApiPath();

  return useInfiniteQuery<
    unknown,
    unknown,
    T,
    QueryKey
  >({
    enabled,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = pages.length;
      const totalPages = ((lastPage as T)?.metadata as MetaData)?.totalPages || 0;
      const res = (nextPage < totalPages) ? { nextPage } : undefined;
      return res;
    },
    queryFn: ({ pageParam }) => fetcher(
      `${basePath}${getApiPath({ pageNumber: pageParam?.nextPage ?? 0 })}`,
      {
        headers: {
          "authorization": idToken, // add to query key
          "x-ada-session-token": sessionToken // add to query key
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: ContentResponse) => {
        return Object.entries(data ?? {}).reduce((acc, [label, value]) => {
          if (label !== "metadata") {
            acc[label] = apiAdapter(value as Record<string, unknown>[]);
          } else {
            acc[label] = value ? (value as MetaData) : { topic_ids:[],totalElements: 0, totalPages: 0 };
          }
          return acc;
        }, {} as ContentResponse);
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [apiPath, ...queryKey]
  });

  function getBaseApiPath() {
    const pathParams = {
      corporateId: userData?.organization_id.toString() ?? "",
      initiativeId: userData?.initiative_id?.toString() ?? "",
      pageSize: pageSize?.toString(),
      ...otherParams
    };
    return replacePathParams(path ?? "", pathParams);
  }

  function getApiPath({ pageNumber=0 }) {
    if (!userData) return "";
    const pathParams = {
      corporateId: userData?.organization_id.toString() ?? "",
      initiativeId: userData?.initiative_id?.toString() ?? "",
      pageNumber: pageNumber?.toString() ?? 0,
      pageSize: pageSize?.toString(),
      ...otherParams
    };
    return replacePathParams(path ?? "", pathParams);
  }
}

export function useTeacherQuery({
  enabled=true,
  teacherId
}: {
  enabled?: boolean
  teacherId: number
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const fetcher = fetcherFactory();

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/teachers/${teacherId}`;

  return useQuery<
    unknown,
    unknown,
    Teacher,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && sessionToken
      && corporateId
      && initiativeId
      && teacherId
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers: {
          "Authorization": accessToken,
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: Teacher) => {
        return apiAdapter([data])[0];
      })
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri]
  });
}

export function useTopicsQuery({
  enabled=true
}) {
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const uri = "/usermgmt/learner/topics"; // TO CHANGE WITH
  const params = "generateUrl=true";

  return useQuery<
  unknown,
  unknown,
  Topic[],
  QueryKey
>({
  enabled: Boolean(enabled
    && accessToken
    && initiativeId
    && sessionToken
  ) && !isSeedingSession,
  queryFn: () => fetcher(
    `${basePath}${uri}?${params}`,
    {
      headers: {
        "accept": "application/json",
        "Authorization": accessToken,
        "x-ada-session-token": sessionToken
      }
    })
    .then((res) => {
      return res.json();
    })
    .then((data: {
      metadata: MetaData
      output: Topic[]
    }) => {
      return apiAdapter(data.output);
    })
    .catch((error) => {
      if (error === ERRORS.UNAUTHORIZED) {
        setUnauthorized(true);
        throw error; // rethrow so that react query doesn't complain about undefined return value
      } else {
        throw error;
      }
    }),
  queryKey: [basePath, sessionToken, isSeedingSession, uri, params]
});
}

export function useNewsFeed() {
  const fetcher = fetcherFactory();

  return useQuery<
  unknown,
  unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  QueryKey
  > ( {
    enabled:true,
    queryFn: () => fetcher(
      // eslint-disable-next-line max-len
      "https://api.rss2json.com/v1/api.json?rss_url=https://news.google.com/rss/search?q=economia,tecnologia&hl=it&gl=IT&ceid=IT:it",
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => {
        return res.json();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        return data;
      }),
    queryKey: ["news"]
  }
  );

  // const getNews = () => {
  //   const newsList: NewsCardProps = { news: [] };

  //   const newsParsed = newsXmlParsed();
  //   newsParsed.rss.channel.item.forEach(({
  //     title,
  //     link,
  //     pubDate,
  //     source
  //   }: {
  //     description: string,
  //     guid: string,
  //     link: string,
  //     pubDate: string,
  //     source: string,
  //     title: string
  //   }) => {
  //     newsList.news.push({
  //       coverPublicURL: "https://medias4b2sit.ispfdevelop.com/media/2023-03/01_stereotipi.jpg?VersionId=fEb_N7Hi4VLbbslJltALvWwAt6QqPUHO",
  //       date: format(new Date(pubDate ?? ""), "dd.MM.yyyy, HH:mm", { locale: it }),
  //       image:"https://medias4b2sit.ispfdevelop.com/media/2023-03/01_stereotipi.jpg?VersionId=fEb_N7Hi4VLbbslJltALvWwAt6QqPUHO",
  //       link: link ?? "missing link",
  //       newspaper: source ?? "missing source",
  //       title: title ?? "missing title"
  //     });
  //   });

  //   return newsList;

  // };
  // const newsList = getNews();
}

export function useEnrollmentMutation({
  enrollType,
  learningObjectId,
  learningObjectTypology
} : {
  enrollType:enrollType,
  learningObjectId: number,
  learningObjectTypology: string
}) {
  const objectType = getLoTypeEnrollment(learningObjectTypology);

  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();

  return useMutation({

    mutationFn: () => fetcher(
      `${basePath}/enrollment/enrollments`,
      {
        body: JSON.stringify([{
          domainId: Number(userData!.initiative_id )?? "",
          enrollDate: getDate(),
          enrollType:getEnrollmentType(enrollType),
          isMandatory: false,
          learningObject: {
            corporateId: userData!.organization_id,
            objectId: Number(learningObjectId),
            objectType
          },
          userId: Number(userData!.id_user)
        }]),
        headers:{
          "authorization": idToken,
          "content-type":"application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      })
  });

  function getDate() {
    return new Date().toLocaleString(
      "en-Ca",
      {
        day: "2-digit",
        hour: "2-digit",
        hour12: false,
        minute: "2-digit",
        month: "2-digit",
        second: "2-digit",
        year: "numeric"
      }).replace(",","");
  }

  function getLoTypeEnrollment (learningObjectTypology: string) {
    switch (learningObjectTypology) {
    case LEVEL.COURSE:
      return "COURSE";
    case LEVEL.PATH:
      return "ROOT";
    default:
      return "ACTIVITY";
    }
  }
}
export function usePatchMutation({
  editionId,
  learningObjectId,
  isToj
} : {
  editionId?:number,
  learningObjectId: number,
  learningObjectTypology: string
  isToj: boolean
}) {


  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const userData = useAuthStore(state => state.userData);
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();

  return useMutation({
    mutationFn: () => fetcher(
      `${basePath}/v3/enrollment/enrollments`,
      {
        body: JSON.stringify([{
          corporateId: userData!.organization_id,
          domainId: Number(userData!.initiative_id )?? "",
          editionId:editionId,
          mandatory: false,
          isToj: isToj,
          objectId: Number(learningObjectId),
          userId: [Number(userData!.id_user)]
        }]),
        headers:{
          "authorization": idToken,
          "content-type":"application/json",
          "x-ada-session-token": sessionToken
        },
        method: "PATCH"
      })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      })
  });
}

export function useGetFormats({
  enabled=true
} : {
  enabled?: boolean
}) {
  const authToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken());
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const covers = useCoverStore();
  const fetcher = fetcherFactory();
  const isSeedingSession = useAuthStore(state => state.hasSeedSession);

  const uri = "/learning-catalogue/formats";

  return useQuery<
    unknown,
    unknown,
    Format[],
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && sessionToken
      && authToken
    )
    && !isSeedingSession,
    queryFn: () => fetcher(
      `${basePath}${uri}`,
      {
        headers:{
          "Authorization":authToken ?? "",
          "x-ada-session-token": sessionToken
        }
      })
      .then((res) => {
        return res.json();
      })
      .then((data: Format[]) => (
        data.map((format) => {
          format.cover = covers.get();
          return format;
        })
      ))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [uri, isSeedingSession]
  });
}

export function useDownloadIcs() {
  const idToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);
  const fetcher = fetcherFactory();

  const corporateId = useAuthStore(state => state.userData?.organization_id) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id) ?? "";

  return useMutation({

    mutationFn: ({ date, events }:{date: string, events: eventItem[]}) => fetcher(
      `${basePath}/learning-catalogue/${corporateId}/${initiativeId}/downloadIcs`,
      {
        body: JSON.stringify({
          date: date,
          events: events
        }),
        headers:{
          "authorization": idToken,
          "content-type":"application/json",
          "x-ada-session-token": sessionToken
        },
        method: "POST"
      }).then(async (res) => {
      const response = await(res.text());

      const filename = res?.headers.get("content-disposition") ?
        res?.headers.get("content-disposition")?.split("filename=")[1] : `Agenda_Calendar_${date}`;

      return { filename, response };
    })
      .catch(async (error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
        } else {
          const errorRes = await error;
          throw errorRes;
        }
      })
  });
}

export function getDetailsLevel(level?: Level) {
  switch (level) {
  case LEVEL.COURSE:
    return "courses";
  case LEVEL.PATH:
    return "roots";
  default:
    return "learningActivities";
  }
}

export function getApiStatus(status: StatusLO | null) {
  switch(status){
  case STATUS_LO.C:
    return "COMPLETE";
  case STATUS_LO.E:
    return "ENROLLED";
  case STATUS_LO.N:
    return "NOT_ENROLLED";
  case STATUS_LO.P:
    return "PROGRESS";
  case STATUS_LO.R:
    return "REJECTED";
  case STATUS_LO.T:
    return "TOPDOWN";
  case STATUS_LO.W:
    return "WAITING";
  default:
    return "";
  }
}

// export const contentsQuery = {
//   queryFn: () => fetcher({ url: "http://localhost:3000/contents" }),
//   queryKey: ["contents"]
// };

// export const eventsQuery = {
//   queryFn: () => fetcher({ url: "http://localhost:3000/events" }),
//   queryKey: ["events"]
// };

// export const packagesQuery = {
//   queryFn: () => fetcher({ url: "http://localhost:3000/packages" }),
//   queryKey: ["packages"]
// };
