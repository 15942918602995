import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ModulesListGrid,
  ModuleWrapperStyled,
  ModulesListStyledComponent
} from "./ModulesList.styles";
import type { ModulesListProps } from "./ModulesList.types";
import {
  CardModule,
  CardModuleMobile,
  Icon
} from "../../components";

export function ModulesList({
  modules,
  resetFilters
}: ModulesListProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    (Array.isArray(modules) && modules.length > 0) ? (
      <ModulesListStyledComponent>
        <ModulesListGrid item container>
          {
            modules?.map((module, idx) => {
              const cardProps = {
                ...module,
                disabled: module.disabled
              };
              return (
                <ModuleWrapperStyled key={ `module-${idx}` }>
                  <div className="number">
                    {
                      module.isSurvey || module.isTest ?
                        <Icon
                          size={ 25 }
                          icon={ module.isTest ? "test" : "survey" }
                        /> :
                        <span>{ idx + 1 }</span>
                    }

                  </div>
                  {
                    isMobile ?
                      <CardModuleMobile { ...cardProps }/> :
                      <CardModule { ...cardProps }/>
                  }
                </ModuleWrapperStyled>
              );
            })
          }
        </ModulesListGrid>
      </ModulesListStyledComponent>
    ) : (
    // TEMPORARY
    /* eslint-disable max-len */
      <Typography
        paddingX={ theme.spacing(2) }
        paddingY={ theme.spacing(4) }
      >
        Non abbiamo trovato risultati per i filtri selezionati. Ti consigliamo di rimuovere i filtri o selezionare un altro valore. <Typography
          color="primary"
          onClick={ () => resetFilters() }
          sx={ { cursor: "pointer"  } }
        >
          Rimuovi filtri
        </Typography>
      </Typography>
      /* eslint-enable max-len */
    )
  );
}
