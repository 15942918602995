import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Popover,
  Stack,
  Typography,
  useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {

  useEffect,

  useState } from "react";

import { useTranslation } from "react-i18next";
import {
  AttachmentsBox,

  ContentBox,
  CreateContentModalBox,
  DescriptionTypography,

  TextContent,
  TitleInputBase,
  TitleTypography,

  ForbiddenLabelTypography,
  BodyInputBase,
  StyledError

} from "./CreateContentModal.styles";
import { CreateContentModalProps } from "./CreateContentModal.types";

import {
  ButtonText,
  Icon,
  CreateModalSuccess,
  Spinner,
  StyledSnackBarFavourite,
  Link
} from "../../components";
import { POST_TYPE } from "../../consts";
import { useGetProfileImageQuery } from "../../queries";
import {
  usePublishPostQuery
} from "../../queries/community";
import { useAuthStore, useNavbarStore } from "../../stores";
import { stringAvatar } from "../../utils/community";


export function CreateContentModal({
  closeDrawer,
  onClose,
  open,
  postType,
  selectedFilter,
  selectedTopic,
  ...props
}: CreateContentModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = useAuthStore(state => state.userData);
  const [bodyValue, setBodyValue] = useState<string>("");
  const [linkLabel, setLinkLabel] = useState<string>("");
  const [linkValue, setLinkValue] = useState<string>("");
  const [showLinkForbiddenLabel, setShowLinkForbiddenLabel]= useState(false);
  const [showBodyForbiddenLabel, setShowBodyForbiddenLabel]= useState(false);
  const [setUrl, setSetUrl]= useState(false);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  const { data: profileImage  } = useGetProfileImageQuery({});

  const {
    data,
    mutate: mutatePublish,
    isLoading: publishIsLoading,
    isError,
    isSuccess,
    error
  } = usePublishPostQuery(
    (postType === POST_TYPE.FREE) ? {
      description: bodyValue,
      linkLabel: linkLabel,
      linkValue: linkValue,
      postType,
      selectedFilter: selectedFilter,
      selectedTopic: selectedTopic
    } : {
      description: bodyValue,
      loAuthor: ("postData" in props && props.postData) ? props.postData.loAuthor : "",
      loId: ("postData" in props && props.postData) ? props.postData.loId : "",
      loType: ("postData" in props && props.postData) ? props.postData.loType : "",
      postType,
      selectedFilter: selectedFilter,
      selectedTopic: selectedTopic
    }
  );

  useEffect(()=> {
    if(isSuccess && postType === POST_TYPE.SHARED  ){
      // document.body.style.overflow = "auto";
      setTimeout(()=> {
        closeDrawer();
      },3000);
    }
  },[
    closeDrawer,
    isSuccess,
    postType]);
  //managing fobidden word
  useEffect(() => {
    if( !publishIsLoading && isError) {
      const errorObject = JSON.parse(error as string);
      if(errorObject.message == "Forbidden word has been detected.") {
        errorObject.errors.forEach((error: Record<string, string|Array<string>>) => {
          let errorBody = "" as string;
          switch (error.field) {

          case "body":

            errorBody = replaceForbidden(bodyValue, error.words as string[]);
            setBodyValue(errorBody);

            setShowBodyForbiddenLabel(true);
            break;

          case "attachments_link":
            setShowLinkForbiddenLabel(true);
            break;
          default:
            break;
          }
        });
      }
    }
  },[
    bodyValue,
    error,
    isError,
    publishIsLoading
  ]);

  const contentItems = getContentItems({ size: isMobile ? "15px" : "20px" });

  const postFreeStyle = postType === POST_TYPE.FREE ?  {
    height: "fit-content",
    inset: "0 !important",
    margin: "auto",
    width: "fit-content"
  } : {};


  return (
    <>

      <Popover
        className={ "popover-community" }
        anchorEl={ "anchorEl" in props ? props.anchorEl : null }
        open={ open }
        onClose={ onClose }
        sx={ {
          ".MuiModal-backdrop":{
            visibility: isSuccess && postType === POST_TYPE.SHARED ? "hidden" : "visible"
          },

          ".MuiPopover-paper":{
            background:"transparent",
            boxShadow:"none",
            overflow:"visible",
            ...postFreeStyle

          },

          ".MuiDialog-paper ": {
            background: "tranparent",
            borderRadius:"8px",
            boxShadow:"none",

            maxHeight:"calc(100% - 32px)",
            padding: 0
          }
        } }
        { ...props }
      >

        {
          publishIsLoading ?

            <CreateContentModalBox
              container
              { ...props }
            >
              <Spinner/>
            </CreateContentModalBox>
            :

            isSuccess  ?

              (
                postType === POST_TYPE.FREE
              ) ?

                (
                  <CreateContentModalBox
                    container
                    { ...props }>
                    <CreateModalSuccess
                      title={ t("added_post") }
                      onClose={ ()=> {
                        closeDrawer();
                      }
                      }
                    />
                  </CreateContentModalBox>

                ) :
                (
                  <StyledSnackBarFavourite
                    $visible={ isSuccess && postType === POST_TYPE.SHARED }
                    sx={ {
                      maxWidth:"unset"
                    } }
                  >
                    <Icon icon={ "icon_share" } size={ 19 }/>
                    <Typography
                      fontSize = { theme.spacing(1.625) }
                      fontWeight= { 500 }
                      whiteSpace={ "nowrap" }
                    >
                      { t("share_added") }
                      <Link href={ `/community?scrollTo=${data.id}` } fontWeight={ 700 }
                        onClick={ () => setNavbarItem("COMMUNITY") }>
                        { " " + t("community") }
                      </Link>
                    </Typography>
                    <Icon
                      color= { theme.palette.primary?.main }
                      icon={ "close" }
                      size={ 18 }
                      onClick={ ()=> {
                        if(closeDrawer){
                          closeDrawer();
                        }
                      } }
                    />
                  </StyledSnackBarFavourite>
                ) :
              (
                <CreateContentModalBox
                  container
                  { ...props }
                >
                  {
                    isError  ?

                      <StyledError>
                        <Icon
                          color={ theme.customColors.systemSecondary03 }
                          icon="Icons_obbligatorio"
                          size={ 20 }
                        />
                        { t("forbidden_label_topic") }
                      </StyledError>

                      :
                      null
                  }
                  <Box
                    sx={ {
                      marginRight: isMobile ? "20px" : 0
                    } }
                  >
                    <Icon
                      icon={ "close" }
                      size={ "19px" }
                      cursor="pointer"
                      color={ theme.palette.primary?.main }
                      onClick={ ()=> {
                        if(closeDrawer){
                          closeDrawer();
                        }
                      } }
                    />
                  </Box>
                  <Box
                    paddingRight={ "16px" }
                    maxWidth={ isMobile ? "335px" : "582px" }
                  >
                    <TextContent>
                      <TitleTypography>
                        { postType === POST_TYPE.SHARED ? t("share_post") :  t("add_new_post") }
                      </TitleTypography>
                      <DescriptionTypography>
                        {  postType === POST_TYPE.SHARED ? t("share_comment") : t("give_voice") }
                      </DescriptionTypography>
                    </TextContent>
                    <Divider
                      sx={ {
                        background:theme.customColors.border,
                        height:"0px",
                        margin:"16px 0",
                        width: isMobile ? "100%" : "582px"
                      } }
                    />
                    {
                      postType === POST_TYPE.FREE ?

                        <Box
                          sx={ {
                            alignItems: "center",
                            display: "flex",
                            gap:theme.spacing(2),
                            margin:"24px 0"
                          } }
                        >
                          <Avatar
                            alt={ `${userData?.name} ${userData?.surname} ` }
                            { ...stringAvatar(`${userData?.name} ${userData?.surname} `) }
                            sx={ {
                              height:isMobile ? "40px" : "56px",
                              width:isMobile ? "40px" : "56px"
                            } }
                            src={ profileImage }
                          />
                          <Typography
                            sx={ {
                              fontSize: isMobile ? "16px" : "20px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "21px"
                            } }
                          >
                            { userData?.name }  { userData?.surname }
                          </Typography>
                        </Box> :
                        null
                    }


                    <Stack gap={ "16px" }>

                      { showBodyForbiddenLabel &&
                        <ForbiddenLabelTypography key={ "forbidden-label-body" }>
                          { t("forbidden_label_topic") }
                        </ForbiddenLabelTypography>
                      }
                      {
                        postType === POST_TYPE.SHARED ?

                          <Box
                            display={ "flex" }
                            gap={ 2 }
                          >
                            <Avatar
                              { ...stringAvatar(`${userData?.name} ${userData?.surname} `) }

                              sx={ {
                                height: "40px",
                                width:"40px"
                              } }
                              src={ profileImage }
                            />
                            <BodyInputBase
                              multiline
                              value={ bodyValue }
                              onChange={ (changeEvent) => setBodyValue(changeEvent.target.value) }
                              placeholder={ t("enter_comment") }
                              hasMinHeight={ false }

                            />
                            <ButtonText
                              sx={ {
                                alignSelf:"flex-end",
                                borderRadius:"4px",
                                height:"40px",
                                minWidth:"42px"
                              } }
                              disabled={
                                !bodyValue
                              }
                              onClick={ ()=> handlePublish() }
                            >
                              <Icon
                                icon={ "arrow_right_horizontal" }
                                width={ "20px" }
                                height={ "16px" }
                                color={ theme.customColors.textPrimaryCta }
                              />
                            </ButtonText>
                          </Box>

                          :

                          <BodyInputBase
                            inputProps={ { "aria-label": t("talk_about") } }
                            multiline
                            value={ bodyValue }
                            onChange={ (changeEvent) => setBodyValue(changeEvent.target.value) }
                            placeholder={ `${t("talk_about")}` }

                          />
                      }

                    </Stack>

                    {
                      postType === POST_TYPE.FREE ?
                        <ContentBox marginTop={ "16px" }>

                          <Box
                            sx={ {
                              [theme.breakpoints.down("sm")]: {
                                width:"205px"
                              }
                            } }
                          >
                            <BottomNavigation

                              sx={ {
                                backgroundColor:"#0000",
                                color:theme.customColors.textPrimary,
                                gap: "32px",
                                justifyContent:" flex-start"
                              } }
                              showLabels
                            >
                              {
                                contentItems.map(({ icon, label }, index) => {
                                  return (
                                    <BottomNavigationAction
                                      sx={ {
                                        color:theme.customColors.textPrimary,
                                        flex:"unset",
                                        flexDirection:"row",
                                        fontSize:"16px",
                                        fontStyle: "normal",
                                        gap:"8px",
                                        lineHeight:"21px",
                                        padding:"0px"
                                      } }
                                      key={ `key_${index}`  }
                                      label={ label }
                                      icon={ icon }
                                    />
                                  );
                                })
                              }
                            </BottomNavigation>
                          </Box>
                          { setUrl ?
                            <>
                              { showLinkForbiddenLabel &&
                                <ForbiddenLabelTypography key={ "forbidden-label-link" }>
                                  { t("forbidden_text") }
                                </ForbiddenLabelTypography>
                              }


                              <AttachmentsBox
                                padding= "16px 0px"
                              >
                                <Stack
                                  flexDirection={ "column" }
                                  gap={ theme.spacing(2) }
                                  padding="0px 8px"
                                  flexGrow={ "1" }
                                >
                                  <TitleInputBase
                                    value={ linkValue }
                                    onChange={ (changeEvent) => {
                                      setShowLinkForbiddenLabel(false);
                                      setLinkValue(changeEvent.target.value);
                                    } }
                                    placeholder={ `${t("insert_url")}` }
                                    sx={ {
                                      [theme.breakpoints.down("sm")]:{
                                        width: "252px"
                                      }
                                    } }
                                  />
                                  <TitleInputBase
                                    value={ linkLabel }
                                    onChange={ (changeEvent) => {
                                      setShowLinkForbiddenLabel(false);
                                      const uri = changeEvent.target.value.replace(/.+\/\/|www.|\.+/g, "");
                                      const encoded = encodeURI(uri);
                                      const decoded = decodeURI(encoded);
                                      setLinkLabel(decoded);
                                    } }
                                    placeholder={ `${t("insert_link_title")}` }
                                    sx={ {
                                      [theme.breakpoints.down("sm")]:{
                                        width: "252px"
                                      }
                                    } }
                                  />
                                </Stack>
                                <Box
                                  padding={ "0px" }
                                >
                                  <Icon
                                    color={ theme.palette.primary?.main }
                                    icon={ "close" }
                                    width={ "27px" }
                                    height={ "19px" }
                                    cursor="pointer"
                                    onClick={ ()=> {
                                      setSetUrl(!setUrl);
                                    } }
                                  />
                                </Box>
                              </AttachmentsBox>


                            </> : null
                          }
                          <Stack
                            flexDirection="row"
                            justifyContent={ "center" }
                            marginTop={ "16px" }
                          >
                            <ButtonText
                              sx={ {
                                gap:theme.spacing(3),
                                maxWidth:"120px"
                              } }
                              disabled={
                                !bodyValue
                              }
                              onClick={ ()=> handlePublish() }
                            >
                              { t("publish") }
                            </ButtonText>
                          </Stack>
                        </ContentBox> :

                        null
                    }

                  </Box>
                </CreateContentModalBox>
              )
        }

      </Popover>
    </>
  );

  function handlePublish () {

    mutatePublish();
  }

  function replaceForbidden(html: string, forbiddenWords: Array<string>): string {
    let initialHtml = html;

    const resultHtml = forbiddenWords.reduce(
      (partialHtml, currentWord) => {
        var reg = new RegExp(currentWord, "gmi");
        return partialHtml.replace(reg, `<span style="color: red;"><ins><strong>${currentWord}</strong></ins></span>`);
      },
      initialHtml
    );

    return resultHtml;
  }

  function getContentItems({ size }: { size: string }) {
    return [
      {
        icon:(
          <Icon  color={ setUrl ? theme.palette.primary?.main : theme.customColors.textPrimary }
            icon={ "Icons_link" } size={ size } />
        ),
        label: (
          <Typography
            color={ setUrl ? theme.palette.primary?.main : theme.customColors.textPrimary }
            fontSize={ "16px" }
            onClick={ ()=> setSetUrl(true) }
          >
            { "Link" }
          </Typography> )
      }
    ];
  }
}
