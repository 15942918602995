import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  DetailTypography,
  HeroBannerStack,
  StyledBadgeHero,
  TitleTypography
} from "./HeroBanner.styles";
import { HeroBannerProps } from "./HeroBanner.types";
import {
  ButtonLink,
  CardCategories,
  CardCta,
  CardSignature,
  CtaOverlay,
  Icon,
  StatusBox,
  VideoCover
} from "../../components";
import {
  LEVEL, TAB_ITEMS
} from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import { useAccent, useIconStatus, useType } from "../../hooks";
import { formatDate } from "../../services";
import { useNavbarStore } from "../../stores";
import { hasPassedExpiration } from "../../utils/datetime";
import { TruncatedText } from "../TruncatedText";

export function HeroBanner({
  badge,
  certificate,
  booked,
  categories,
  coverPublicURL,
  coverVideoPublicUrl,
  courseId=undefined,
  duration,
  ecmRegistration,
  ecmType,
  editionNumber,
  editionVirtualLink,
  enrollType,
  expirationDate,
  id,
  ecm_specialization,
  isFavourite,
  isMandatory,
  isStandAlone,
  isSurvey,
  isTest,
  isToj,
  learningObjectType,
  learningObjectTypology,
  meetingDetails,
  percentageOfCompletion,
  rating,
  rootId=undefined,
  setOpenTojModal,
  shortDescription,
  startDate,
  endDate,
  setOpenModalEcm,
  status,
  title,
  isCurrentSession,
  completable,
  canAccessNow,
  credits,
  ...props
}: HeroBannerProps) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { state:routerState }=useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const isExpired = hasPassedExpiration(expirationDate);
  const iconStatus = useIconStatus({ status });
  const accent = useAccent({ isExpired, isMandatory, status });
  const [videoVisible, setVideoVisible] = useState(Boolean(coverVideoPublicUrl));
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const { navigation, setNavigation } = useContext(UserNavigationContext);

  const cardCtaVisibility = (() => {
    switch (learningObjectTypology) {
    case LEVEL.DA_PHYSICAL_CLASS:
      return {
        disabled: status === "C" || status === "W" || false,
        visibility: true
      };
    case LEVEL.DA_VIRTUAL_CLASS:
      return {
        disabled: false,
        visibility: true
      };
    case LEVEL.DA_MEETING:
      return {
        disabled:true,
        visibility: false
      };
    default:
      return {
        disabled: isTest && status === "C" || status === "W" || false,
        visibility: true
      };
    }
  })();
  const navigate = useNavigate();
  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;

  const isPAthOrCourse = learningObjectTypology === LEVEL.PATH || learningObjectTypology === LEVEL.COURSE;

  return (

    <Box
      sx={ {
        background:!videoVisible
          ? `${theme.linearGradients.gradientC}, url(${coverPublicURL})`
          : theme.linearGradients.gradientC,
        backgroundPosition: "center",
        backgroundRepeat:"no-repeat",
        backgroundSize: "cover",
        position:"relative"
      } }
    >
      {
        videoVisible && coverVideoPublicUrl ?
          <VideoCover
            sx={ {
              "&:before": {
                background: `${theme.linearGradients.gradientC}`
              }
            } }
            img={ coverPublicURL.toString() }
            onEnded={ ()=> { setVideoVisible(false)} }
            url={ coverVideoPublicUrl }
          /> : null
      }


      <HeroBannerStack
        component="section"
        gap={ 2 }
        position={ "relative" }
        { ...props }
        sx={ {
          padding: `${(
            (status === "C" || isMandatory) &&
            (isPAthOrCourse && !completable)
          ) ? "240px" : "160px"} 42px 77px`,
          [theme.breakpoints.down("sm")]: {
            padding: `${(
              (status === "C" || isMandatory) &&
              (isPAthOrCourse && !completable)
            ) ? "200px" : "120px"} 20px 20px`
          }
        } }
      >
        {
          ( status === "C" || isMandatory) ?
            (
              <StatusBox
                accent={ accent }
                icon={ iconStatus }
                message={ messageStatusBox }
                inHeroBanner={ true }
                sx={ {
                  height:"60px",
                  width: isExpired ? "fit-content" : "60px",

                  left:0,
                  padding:isExpired ? "0 16px" : 0,
                  top:"80px",

                  [theme.breakpoints.down("lg")]: {
                    padding: "0.5rem 1rem"
                  },

                  [theme.breakpoints.down("sm")]: {
                    padding: "0.5rem"
                  },

                  "svg":{
                    height:"30px !important",
                    width:"30px !important"
                  }
                } }
              />
            ) : null
        }

        { isPAthOrCourse && !completable && (
          <Box sx={ {
            position: "absolute",
            top: "150px",
            left: "0",
            background: theme.customColors.accentVariantC,
            color: theme.customColors.textPrimaryAlternative,
            padding: "1rem",
            borderRadius: "0 0.25rem 0.25rem 0",
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "600px",
            [theme.breakpoints.down("lg")]: {
              padding: "0.5rem 1rem"
            },
            [theme.breakpoints.down("sm")]: {
              top: "130px",
              padding: "0.5rem"
            }
          } }>
            <Icon
              color={ theme.customColors.textPrimaryAlternative }
              icon={ "Icons_info" }
              size={ isMobile ? "30px" : "30px" }
              sx={ {
                flexShrink: 0
              } }
            />
            <Typography sx={ {
              fontWeight: 500,
              [theme.breakpoints.down("sm")]: {
                display: "none"
              }
            } }>{ t("completable-text") }</Typography>
          </Box>
        ) }

        { (
          booked && (
            learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
              || learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
              || isToj
          ) ?
            <StyledBadgeHero
              sx={ {
                borderRadius: (status === "C" || isMandatory ) ? "0.25rem" :  "0 0.25rem 0.25rem 0",
                left:(status === "C" || isMandatory ) ? "68px" : "0",
                top:"80px"
              } }
            >
              { t("registered") }
            </StyledBadgeHero>
            : null
        )
        }
        <ButtonLink
          onClick={ () =>  {
            console.log("goBackPath >>", routerState?.goBackPath);
            if(routerState?.goBackPath){
              navigate(routerState.goBackPath);
            }else if(routerState?.isFromCommunity){
              navigate(routerState.isFromCommunity);
              setNavbarItem("COMMUNITY");
            }
            else {
              if (!navigation?.length){
                navigate(-1);
                return;
              }
              if (navigation[navigation?.length - 1] === location.pathname) {
                const newNavigation = navigation.filter((url: string) => url !== location.pathname);
                navigate(navigation[navigation?.length - 2], {
                  state: { courseId: routerState?.courseId, rootId: routerState?.rootId }
                });
                setNavigation && setNavigation(newNavigation);
              } else {
                const newNavigation = navigation.filter((url: string) => url !== location.pathname);
                navigate(navigation[navigation?.length - 1], {
                  state: { courseId: routerState?.courseId, rootId: routerState?.rootId }
                });
                setNavigation && setNavigation(newNavigation);
              }
            }} }
          disableRipple
          className={ "button-back" }
        >
          <Icon color={ theme.customColors.systemPrimary01 } className={ "icon" } icon={ "arrow_left" }/>
          { t("back") }
        </ButtonLink>

        {
          isTest ?

            <span className={ "type" } style={ { color: theme.customColors.textPrimary } }>
              {   t("test") }
            </span>
            :

            isSurvey ?
              <span className={ "type" } style={ { color: theme.customColors.textPrimary } }>
                {   t("survey") }
              </span> :
              null
        }
        {
          ecm_specialization && ecmType ?

            <span className={ "type" } style={ { color: theme.customColors.textPrimary } }>
              { ecmType.toUpperCase() }
            </span> :
            typeObjectLearning && !isTest && !isSurvey  ?
              <span className={ "type" } style={ { color: theme.customColors.textPrimary } }>
                { typeObjectLearning }
              </span>
              : null
        }
        <TitleTypography variant="h1" sx={ {
          maxWidth: "80%",
          [theme.breakpoints.down("md")]: {
            fontSize: "3rem"
          },
          [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem"
          }
        } }>
          <TruncatedText text={ title } maxLength={ 35 } />
        </TitleTypography>
        {
          categories?.length > 0 ?
            <CardCategories categories={ categories }/> :
            null
        }
        <DetailTypography>
          <TruncatedText text={ `${shortDescription}` } maxLength={ 60 } />
        </DetailTypography>
        <CardSignature
          ecm_specialization={ ecm_specialization }
          editionNumber={ editionNumber }
          expirationDate={ expirationDate }
          duration={ duration }
          startDate={ startDate }
          endDate={ endDate }
          gap={ 2 }
          isToj={ isToj }
          learningObjectTypology={ learningObjectTypology }
          meetingDetails={ meetingDetails }
          canAccessNow={ canAccessNow }
        />
        {
          cardCtaVisibility.visibility ?
            <CardCta
              disableFavourites={ Boolean(isTest || isSurvey) }
              badge={ badge }
              certificate={ certificate }
              disabled={ cardCtaVisibility.disabled || status === "R" || (!isCurrentSession && learningObjectTypology === "DA_VIRTUAL_CLASS") }
              ecmRegistration={ ecmRegistration }
              enrollType={ enrollType }
              className={ "card-cta" }
              courseId={ courseId }
              id={ id }
              ecm_specialization={ ecm_specialization }
              maxWidth={ "600px" }
              isStandAlone={ isStandAlone }
              learningObjectTypology={ learningObjectTypology }
              learningObjectType={ learningObjectType }
              linkVirtualClass={ editionVirtualLink }
              percentageOfCompletion={ percentageOfCompletion }
              isTest={ isTest }
              isSurvey={ isSurvey }
              rootId={ rootId }
              simple={ false }
              status={ status }
              triggerOpenTabs={ openEditions }
              isCard={ false }
              setOpenRegistrationModal={ setOpenModalEcm }
              setOpenTojModal={ setOpenTojModal }
              canAccessNow={ canAccessNow }
              credits={ credits }
            /> :
            null
        }

        <CtaOverlay
          isStandAlone={ isStandAlone }
          isFavourite={ isFavourite }
          id={ id }
          learningObjectTypology={ learningObjectTypology }
          rating={ rating }
          share={ true }
          courseId={ courseId }
          rootId={ rootId }
        />
      </HeroBannerStack>

    </Box>
  );

  function openEditions() {
    const tabsComponent = document.querySelector(".MuiTabs-flexContainer") as HTMLDivElement;

    if (tabsComponent) {
      const tabs = tabsComponent.querySelectorAll("button") as NodeListOf<HTMLButtonElement>;
      const labelToCheck =
        learningObjectTypology === LEVEL.COURSE ? TAB_ITEMS.ACTIVITIES
          : learningObjectTypology === LEVEL.PATH ? "modules"
            : TAB_ITEMS.EDITIONS;
      if (labelToCheck !== "modules"){
        tabs.forEach((tab) => {
          if (tab.id.includes(labelToCheck)) {
            tab.click();
            tab.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }
        });
      }
      else {
        const modules = document.querySelector(".modules-tab");
        if(modules){
          modules.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      }


    }
  }
}

