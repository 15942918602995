import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import { PreferencesContainer } from "./SignupSummary.styles";
import { ButtonLink } from "../../components/Button";
import { Carousel } from "../../components/Carousel";
import { ContentFormat } from "../../components/ContentFormat";
import { Icon } from "../../components/Icon";
import { PreferenceStack } from "../../components/PreferenceStack";
import { CAROUSEL_TYPE } from "../../consts";
import  { useGetLearnerGoalsConfigsQuery, type Format } from "../../queries";
import type { UserGoals } from "../../types";
import { ModifyLink, StyledGoalsButton } from "../PreferenceStack/PreferenceStack.styles";


export function SignupSummaryPreferences({
  isProfilePage,
  formats,
  userGoals
}:{
    formats:Format[] | undefined,
    isProfilePage?: boolean,
    userGoals:UserGoals | undefined
  }){
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    data: userGoalsConfig,
    isSuccess: userGoalConfigIsSuccess,
    isLoading:userGoalConfigIsLoading
  } = useGetLearnerGoalsConfigsQuery();

  const onBoardingEnabled =
  userGoalConfigIsSuccess &&
  !userGoalConfigIsLoading &&
  userGoalsConfig.enabled &&
  userGoalsConfig.yourGoals.enabled;

  return (
    <PreferenceStack
      title={ isProfilePage ? undefined :  t("your_preferences") }
      direction={ isProfilePage ? "column" : "row" }
      gap={ isProfilePage ? isMobile ? 3 : 4 : !isProfilePage && isMobile ? 2 : 3 }
      rowGap={ isProfilePage ? isMobile ? 3 : 5 : isMobile ? 1 : 5 }
      padding={ isProfilePage ? 0 : isMobile ? 2 : 3 }
      width="100%"
      justifyContent={ isProfilePage ? "space-between" : "unset" }
      flexWrap={ "wrap" }
      position={ "relative" }

      sx={ {
        ">p": {
          maxWidth: "calc(100% - 80px)"
        }
      } }
    >
      { !isProfilePage &&
        <ModifyLink
          sx={ {
            position: "absolute",
            right: "24px"
          } }
          href="/signup/preferences"
          state={ {
            isSummary:pathname.includes("summary")
          } }
        >
          <Typography >
            { t("modify") }
          </Typography>
        </ModifyLink>
      }


      {
        ( userGoals?.goal.plan  &&
          onBoardingEnabled
        ) ?
          (
            <Stack
              width={ isMobile || isProfilePage ? "100%" :  "calc(40% - 12px)" }
              gap={ isProfilePage ? isMobile ? 1 : 2 : !isProfilePage && isMobile ? 2 : 3 }
              sx={ {
                borderRight: isProfilePage ? "none" : isMobile ? "none" : `1px solid ${theme.customColors.border}`,
                padding: isMobile ? 0 : isProfilePage ? theme.spacing(0,0,3,0) : theme.spacing(0,4,3,0),

                ".top-stack": {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }
              } }
            >
              {
                !isProfilePage &&
                  <Typography
                    fontSize={ isMobile ? "1rem" : "1.5rem" }
                    fontWeight={ 700 }
                    lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
                  >
                    { t("your_goal") }
                  </Typography> }

              { isProfilePage &&
                <>
                  <div className="top-stack">
                    <Typography
                      fontSize={ isMobile ? "1rem" : "1.5rem" }
                      fontWeight={ 700 }
                      lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
                      width={ "100%" }
                    >
                      { t("your_goal") }
                    </Typography>
                    <ModifyLink
                      href="/signup/preferences"
                      position={ "static" }
                    >
                      <Typography >
                        { t("modify") }
                      </Typography>
                    </ModifyLink>
                  </div>
                  <Typography
                    fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                    fontWeight={ 400 }
                    lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  >
                    { t("your_goal_description") }
                  </Typography></>
              }

              <StyledGoalsButton sx={ {
                marginTop: isProfilePage || isMobile ? "16px" : "0",
                maxWidth: isProfilePage ? "233px" : "unset",
                minHeight: isProfilePage ? "56px" : "unset"
              } }>
                <Typography
                  fontSize={ "1rem" }
                  fontWeight={ 700 }
                  textTransform={ "capitalize" }
                >{ userGoals?.goal.plan.toLowerCase() }</Typography>
                <Typography>
                  {
                    t("hour",{ count:userGoals?.goal.hoursPerMonth ?? 0 })
              + " / " +  t("month") }
                </Typography>
              </StyledGoalsButton>
            </Stack>
          )
          :
          (isProfilePage &&
            onBoardingEnabled ) ?
            (
              <Stack
                flexDirection={ "column" }
                maxWidth={ isMobile ? "100%" : "60%" }
              >
                <Typography
                  fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  width={ "80%" }
                >
                  { t("no_preference_time") }
                </Typography>

                {
                  formats && formats.length ?
                    <ButtonLink
                      href={ "/signup/preferences" }
                      sx={ {
                        fontSize:theme.spacing(2.25),
                        gap:theme.spacing(1.25),
                        justifyContent:"flex-start",
                        marginTop: "10px",
                        padding:0,
                        width:"100%"
                      } }
                    >
                      { t("go_personalize") }
                      <Icon
                        icon={ "arrow_right_horizontal" }
                        color={ theme.customColors?.backgroundPrimaryCta }
                        size={ 20 }/>
                    </ButtonLink>  :
                    null
                }

              </Stack>
            ) :
            null
      }

      {
        (
          formats &&
          formats.length
        ) ?
          (
            <Stack
              gap={ isProfilePage ? isMobile ? 1 : 2 : isMobile && !isProfilePage ? 1 : 3 }
              width={ isProfilePage || isMobile ? "100%" : "calc(60% - 12px)" }
              marginTop={ isMobile && !isProfilePage ? "24px" : "0" }
              overflow={ isMobile ? "hidden" : "unset" }

              sx={ {
                ".top-stack": {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }
              } }
            >
              { !isProfilePage &&
                <Typography
                  fontSize={ isMobile ? "1rem" : "1.5rem" }
                  fontWeight={ 700 }
                  lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
                >
                  { t("your_formats") }
                </Typography>
              }

              { isProfilePage &&
                <div className="top-stack">
                  <Typography
                    fontSize={ isMobile ? "1rem" : "1.5rem" }
                    fontWeight={ 700 }
                    lineHeight={ isMobile ? (21 / 16) : (5 / 4) }
                    width={ "100%" }
                  >
                    { t("your_formats")  }
                  </Typography>
                  <ModifyLink
                    href="/signup/preferences"
                    position={ "static" }
                  >
                    <Typography >
                      { t("modify") }
                    </Typography>
                  </ModifyLink>
                </div>
              }

              { isProfilePage &&
                <Typography
                  fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  width={ "80%" }
                >
                  { t("preference_formats") }
                </Typography>
              }
              <PreferencesContainer
                isProfile = { isProfilePage }
                gap={ 3 }
                paddingTop={ isMobile ? 0 : "16px" }
              >

                { !isMobile ?
                  formats.map((format) => (
                    <ContentFormat
                      isProfile={ isProfilePage }
                      key={ `formats--${format.id}` }
                      active={ true }
                      minHeight={ isProfilePage ? "7rem" : "auto" }
                      image={ format.cover ?? "" }
                    >
                      { format.label }
                    </ContentFormat>
                  )) :
                  <Carousel
                    className="favourites-carousel"
                    carouselType={ CAROUSEL_TYPE.DEFAULT }
                    id={ "favourites" }
                    hasNavigation={ false }
                    isFetching= { !formats }
                    label={ "" }
                  >
                    { formats.map((format) => (
                      <ContentFormat
                        key={ `formats--${format.id}` }
                        active={ true }
                        image={ format.cover ?? "" }
                      >
                        { format.label }
                      </ContentFormat>
                    ))
                    }
                  </Carousel>
                }
              </PreferencesContainer>
            </Stack>
          ) :
          (isProfilePage) ?
            (
              <Stack
                flexDirection={ "column" }
                maxWidth={ isMobile ? "100%" : "60%" }
              >
                <Typography
                  fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  width={ "80%" }
                >
                  { t("no_preference_formats") }
                </Typography>
              </Stack>
            ) :
            null
      }

      {
        !formats  && !userGoals?.goal?.plan && isProfilePage ? (
          <Stack
            flexDirection={ "column" }
            maxWidth={ isMobile ? "100%" : "60%" }
          >
            <Typography
              fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
              fontWeight={ 400 }
              lineHeight={ isMobile ? (21 / 16) : 1.05 }
              width={ "80%" }
            >
              { t("no_time_format_preference") }
            </Typography>
          </Stack>
        ) : null
      }

      { isProfilePage &&
      ((!formats?.length && !userGoals?.goal?.plan) ||
      (!formats?.length && userGoals?.goal?.plan)) && <ButtonLink
        href={ "/signup/preferences" }
        sx={ {
          fontSize:theme.spacing(2.25),
          gap:theme.spacing(1.25),
          justifyContent:"flex-start",
          padding:0,
          width:"100%"
        } }
      >
        { t("go_personalize") }
        <Icon
          icon={ "arrow_right_horizontal" }
          color={ theme.customColors?.backgroundPrimaryCta }
          size={ 20 }/>
      </ButtonLink> }
    </PreferenceStack>
  );
}
