import {
  Grid
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledCardRanking = styled(Grid)<{
  $profilePage: boolean
}>(({
  theme,
  $profilePage
}) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: theme.spacing(1),
  color: theme.customColors.textWidget,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxHeight: "50rem",
  padding: $profilePage ? theme.spacing(3,4) : theme.spacing(3,2),

  [`${theme.breakpoints.down("sm")}`]: {
    padding: theme.spacing(2)
  }

}));

export const StyledGridUsers = styled(Grid)<{
  $profilePage: boolean
}>(({
  theme,
  $profilePage
}) => ({
  columnGap: theme.spacing(1.75),
  display: "flex",
  flexWrap: "wrap",
  maxHeight: $profilePage ? theme.spacing(90) : theme.spacing(50),
  overflowX: "hidden",
  overflowY: "auto",
  paddingRight: "10px",

  [theme.breakpoints.down("sm")]: {
    maxHeight: $profilePage ? theme.spacing(50) : theme.spacing(40)
  }
}));

export const StyledUserItem = styled(Grid)<{
  $isActive: boolean,
  $isUserPage: boolean
}>(({
  theme,
  $isActive,
  $isUserPage
}) => ({
  alignItems: "center",
  background: $isActive ? theme.customColors.systemSecondary01 : undefined,
  borderRadius: $isActive ? theme.spacing(1) : undefined,
  borderTop: `1px solid ${theme.customColors.border}`,
  color: $isActive ? theme.customColors.textPrimaryAlternative : undefined,
  display: "flex",
  fontSize: !$isUserPage ? theme.spacing(1.5) : undefined,
  // gap: theme.spacing(2),
  justifyContent: "space-between",
  padding: $isUserPage
    ? theme.spacing(2.5, 2, 3)
    : theme.spacing(2, 1.5, 2, 1),
  width: "100%",

  "img": {
    borderRadius: "50%"
  },

  "p": {
    fontWeight: $isActive ? 500 : undefined
  }
}));

