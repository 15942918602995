
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { CardTabsLinkStack } from "./CardTabsLink.styles";
import type { CardTabsLinkProps } from "./CardTabsLink.types";
import {
  Icon,
  Link
} from "../../components";

export function CardTabsLink({
  description,
  disabled,
  url_label,
  url,
  ...props
}: CardTabsLinkProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CardTabsLinkStack
      { ...props }
      className={ disabled ? "not-allowed" : "pointer" }
    >
      <Stack
        alignItems="center"
        gap={ 1 }
        direction="row"
        sx={ {
          ".icon": {
            "path": {
              fill: disabled ? theme.customColors.textDisabled : theme.customColors.textWidget
            }
          }
        } }

      >
        <Link
          color="primary"
          href={ url }
          fontSize={ isMobile ? "0.875rem" : "1rem" }
          target={ "_blank" }
          rel="noreferrer"
          display={ "flex" }
          alignItems={ "center" }
          gap={ 1 }
          style={ {
            color:disabled ? theme.customColors.textDisabled : theme.customColors.textWidget,
            cursor:disabled ? "not-allowed !important" : "pointer",
            pointerEvents:disabled ? "none" : "auto"
          } }
        >
          { url_label }
          <Icon
            color={ theme.customColors.textWidget }
            icon={ "Icons_link" }
            width={ "20px" }
            height={ "20px" }
          />
        </Link>

      </Stack>
      {
        description ? (
          <Typography
            fontWeight={ 300 }
            fontSize={ isMobile ? "0.875rem" : "1rem" }
            lineHeight={ 11 / 8 }
            sx={ {
              color: theme.customColors.textWidget
            } }
          >
            { description }
          </Typography>
        ) : null
      }
    </CardTabsLinkStack>
  );
}
