import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CategoriesSkills,
  GuardedLink,
  DndStack,
  Spinner,
  ModalOnboardingSkip
} from "../../components";
import { Areas, AssignedSkill } from "../../components/DndStack/DndStack.types";
import { StyledBottomBar, StyledModal } from "../../components/OnBoarding/OnBoarding.styles";
import { Progress } from "../../components/Progress/Progress.component";
import {
  SKILL_BUCKETS, 
  LEARNING_LEVEL
} from "../../consts";
import { 
  type knowledgeDomainType, 
  useUserJobsAreasQuery, 
  usePutExecutiveKnowledge,
  usePutPersonalKnowledge,
  OnBoardingItemType
} from "../../queries/onboarding";
import { useAuthStore, useLanguageStore } from "../../stores";
import  type { LearningLevel } from "../../types";


export function ProfileSkills() {
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { state:routerState }= useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const userInfo = useAuthStore(state => state.userInfo);
  const [executiveKnowledge,setExecutiveKnowledge]=useState<knowledgeDomainType[] | null>(null);
  const [personalKnowledge, setPersonalKnowledge]=useState<knowledgeDomainType[] | null>(null);
  const [areas, setAreas]=useState<Areas>(undefined);
  const [openModal, setOpenModal]=useState(false);
  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const pageSize = 6;
  const navigate = useNavigate();
  const refetchUserInfo = useAuthStore(state => state.refetchUserInfo);
  const [isRedirect, setIsRedirect]=useState(false);
  const[openModalSkip, setOpenModalSkip]=useState(false);
  const postOnBoardingCompleted = useAuthStore(state => state.postOnBoardingCompleted);
  const language = useLanguageStore(state => state.language);

  const jobState = { 
    id:routerState?.job?.id,
    name:routerState?.job?.name 
  } as Pick<OnBoardingItemType, "id" | "name"> ;

  const {
    data:userJobsAreas,
    hasNextPage: hasMoreAreas,
    fetchNextPage: fetchMoreAreas,
    // isError: isErrorAreas,
    isFetchingNextPage: isFetchingMoreAreas,
    isInitialLoading:isFetchingAreas,
    refetch:refetchAreas
  } = useUserJobsAreasQuery({
    jobId: jobState?.id ?? userInfo?.job?.id,
    pageSize:pageSize
  });

  useEffect(() => {
    if (!language) return;
    refetchAreas();
  }, [language]);
  
  
  useEffect(()=> {
    const areasApi = userJobsAreas?.pages?.reduce((acc, page) => {
      const contents = page?.output ?? [];
      return acc?.concat(contents);
    }, [] as OnBoardingItemType[]);

    setAreas(areasApi);
  }, [
    isFetchingAreas, 
    isFetchingMoreAreas, 
    userJobsAreas?.pages
  ]);


  const userInfoExecutivesSkills = userInfo?.executive_domains?.map((domain) => (
    {
      active:undefined,
      bucket:getLevelSkillsBucket(domain.learning_level),
      disabled:false,
      id: domain.id.toString(),
      name:domain.label,
      type:"executive"
    } 
  )) as unknown as AssignedSkill[];


  const userInfoPersonalSkills  = userInfo?.personal_domains?.map((domain) => (
    {
      active:undefined,
      bucket:getLevelSkillsBucket(domain.learning_level),
      disabled:false,
      id: domain.id.toString(),
      name:domain.label,
      type:"personal"
    } 
  )) as unknown as AssignedSkill[];

  
  const skillsState = useState<CategoriesSkills>(
    userInfoExecutivesSkills?.concat(userInfoPersonalSkills )
  );


  const [skills] = skillsState;

  const categoriesAreAssigned =
   skills?.some((skill) => (
     skill.bucket !== null
   ));


  useEffect(()=> {
    setPersonalKnowledge(skills?.flatMap((skill)=> {
      if(skill.bucket !== null && skill.type === "personal"){
        return {
          id:Number(skill.id),
          learning_level: setLevelSkillsBucket(skill.bucket)
        } as knowledgeDomainType;
      }
      else return [];
    }) ?? null);

    setExecutiveKnowledge(skills?.flatMap((skill)=> {
      if(skill.bucket !== null && skill.type === "executive"){
        return {
          id:Number(skill.id),
          learning_level: setLevelSkillsBucket(skill.bucket)
        } as knowledgeDomainType;
      }
      else return [];
    }) ?? null);
  },
  [
    skills
  ]);


  const {
    mutate:mutateExecutiveKnowledge,
    isError:mutateExecutiveKnowledgeIsError,
    isSuccess:mutateExecutiveKnowledgeIsSuccess
  } =  usePutExecutiveKnowledge({
    knowledge:executiveKnowledge
  });

  const {
    mutate:mutatePersonalKnowledge,
    isError:mutatePersonalKnowledgeIsError,
    isSuccess:mutatePersonalKnowledgeIsSuccess
  } =  usePutPersonalKnowledge({
    knowledge:personalKnowledge
  });

  useEffect(()=> {
   
    if(mutateExecutiveKnowledgeIsError && !mutatePersonalKnowledgeIsError){
      setOpenModal(true);
    }
    else if(!mutateExecutiveKnowledgeIsError && mutatePersonalKnowledgeIsError){
      setOpenModal(true);
    }
    else if(mutateExecutiveKnowledgeIsSuccess || mutatePersonalKnowledgeIsSuccess){
      refetchUserInfo();
      setIsRedirect(true);
      setTimeout(()=> {
        setIsRedirect(false);
        navigate( routerState?.isSummary ? "../summary"  : routerState?.first ? 
          corporateInfo?.onboarding_capability ? 
            "../capabilities" : 
            "../preferences" : 
          "/profile", {
          state:{
            ...routerState,
            onBoardingPercentage: "60%",
            step:1
          }
        });
      },2000);
      
    }
  
   
  },[
    categoriesAreAssigned,
    corporateInfo?.onboarding_capability, 
    routerState, 
    mutateExecutiveKnowledgeIsError, 
    mutateExecutiveKnowledgeIsSuccess, 
    mutatePersonalKnowledgeIsError, 
    mutatePersonalKnowledgeIsSuccess, 
    navigate, refetchUserInfo
  ]); 

  const { t } = useTranslation();

  return (

    <Stack
      gap={ isMobile ? 1 : 3 }
      height={ "100%" }
      width={ "100%" }
    >
      <Typography
        color="primary"
        fontSize={ isMobile ? theme.spacing(2.5) : theme.spacing(4) }
        fontWeight={ 700 }
        lineHeight={ 19 / 16 }
        textAlign={ "start" }
      >
        { t("skills_desc") }
      </Typography>
      <Typography
        fontSize={ isMobile ? theme.spacing(1.75): theme.spacing(2.5) }
        lineHeight={ 21 / 20 }
      >
        { t("skills_choose") }
      </Typography>

      {
        userJobsAreas?.pages[0].output.length || corporateInfo?.onboarding_personal_job ? 

          <DndStack
            callBack={ () => { hasMoreAreas && !isFetchingMoreAreas && fetchMoreAreas() } }
            height={ "550px" }
            buckets={ SKILL_BUCKETS }
            state={ skillsState }
            stateAreas={ areas }
          /> : 
          isFetchingAreas ? 
            <Spinner/> : 

            <Typography>{ t("no_executive_knowledge") }</Typography>
      }
  
     
      <StyledBottomBar 
        sx={ {
          justifyContent:!routerState?.first ? "center" : undefined,
          margin:0,
          position:"static",
          width:"100%"
        } }
      >

        {
          routerState?.first ? 
            <Progress percentageOfCompletion={ 
              !categoriesAreAssigned 
                ? routerState?.onBoardingPercentage  : "60%" }/> :
            null
        }

        <Box
          display={ "flex" }
          gap={ 2 }
        >

          {
            routerState?.first ? 

              <GuardedLink
                variant="outlined"
                href=""
                onClick={ () => {
                  setOpenModalSkip(true);
                  return false;
                } }
              >
                { t("later") }
              </GuardedLink> : null
          }
        

          <GuardedLink
            href={ routerState?.isSummary ? "../summary"  : !routerState?.first ? "/profile" :  "../capabilities" }
            onClick={ () => {
              if(executiveKnowledge || 
                personalKnowledge
              ){
                if(executiveKnowledge){
                  mutateExecutiveKnowledge();
                }
                if(personalKnowledge){
                  mutatePersonalKnowledge();
                }
              }
              else{
                return true;
              }
  
            } }
            state={ {
              ...routerState,
              onBoardingPercentage: "60%"
            } }
          >
            {
              isRedirect ? 
                <Spinner size={ 20 } padding={ 0 }/> : 

                t(routerState?.first ? "continue" : "confirm") 
            }
          
          </GuardedLink>
        </Box>
       
      </StyledBottomBar>

      <StyledModal
        aria-labelledby="modal"
        onClose={ ()=> {setOpenModal(false)} }
        open={ openModal }
      >
        <>
          <Typography>{ t("skills_error") }</Typography>
          <Box
            display={ "flex" }
            gap={ 2 }
          >
          
            <GuardedLink
              variant="outlined"
              href={  routerState?.isSummary ? "../summary"  : !routerState?.first ? "/profile" :  "../capabilities"  }
              onClick={ () => { 
                if(routerState?.first){
                  return categoriesAreAssigned;
                }
                else {
                  completeOnboarding();
                }
                
              } }
              state={ {
                ...routerState
              } }
            >
              { t("later") }
            </GuardedLink>
            <GuardedLink
              href={  routerState?.isSummary ? "../summary"  : !routerState?.first ? "/profile" :  "../capabilities"  }
              onClick={ () => {
                return true;
              } }
              state={ {
                ...routerState
              } }
            >
              { t("continue") }
            </GuardedLink>
          </Box>
        </>
        
      </StyledModal>

      {
        <ModalOnboardingSkip 
          open={ openModalSkip }
          onClose={ () =>  setOpenModalSkip(false) }
        />
      }
    
    </Stack>


  );
  function setLevelSkillsBucket(id:number){
    switch(id){
    case 0: return LEARNING_LEVEL.BASIC;
    case 1: return LEARNING_LEVEL.INTERMEDIATE;
    case 2: return LEARNING_LEVEL.ADVANCED;
    default: return LEARNING_LEVEL.BASIC;
    }
  }
  function getLevelSkillsBucket(level:LearningLevel){
    switch(level){
    case LEARNING_LEVEL.BASIC : return 0 ;
    case LEARNING_LEVEL.INTERMEDIATE : return 1 ;
    case LEARNING_LEVEL.ADVANCED : return 2;
    default: return 0;
    }
  }
  function completeOnboarding(){
    postOnBoardingCompleted();
    setTimeout(()=> {
      navigate("/");
    },2000);
    return false;
  }
}
