import { Box, Fade } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, Divider, Message, Overlay, Title } from "./style";
import { Button } from "../Button";

interface LearningActivityBlockOverlayProps {
  onClose: () => void
}

const LearningActivityBlockOverlay = ({ onClose }: LearningActivityBlockOverlayProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Fade in>
      <Overlay alignSelf={ "center" }>
        <Container gap={ "30px" } theme={ theme }>
          <Title theme={ theme }>{ t("scorm_session_timeout.title") }</Title>
          <Divider theme={ theme }/>
          <Message theme={ theme }>{ t("scorm_session_timeout.message") }</Message>
          <Divider theme={ theme }/>
          <Box>
            <Button onClick={ () =>  {
              navigate(-1);
              onClose();
            } } variant={ "contained" }>{ t("save_close") }</Button>
          </Box>
        </Container>
      </Overlay>
    </Fade>
  );
};

export default LearningActivityBlockOverlay;
