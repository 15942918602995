import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CapabilityItemContainer } from "./SignupSummary.styles";
import { ButtonLink } from "../../components/Button";
import { Chip } from "../../components/Chip";
import { Icon } from "../../components/Icon";
import { PreferenceStack } from "../../components/PreferenceStack";
import { ModifyLink, StyledChipsBox } from "../../components/PreferenceStack/PreferenceStack.styles";
import { CAPABILITY_TYPE } from "../../consts";
import type { CapabilityItem } from "../../types";

export function SignupSummaryCapabilities({
  capabilities,
  isProfilePage=false,
  onboardingAddText,
  noItemsText,
  noItemsToShow
}:{

    capabilities:CapabilityItem[] | undefined
    isProfilePage?:boolean,
    onboardingAddText?:string
    noItemsText:string
    noItemsToShow:boolean
  }){
  const{ pathname } = useLocation();

  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <PreferenceStack
      title={ t("your_capabilities") }
      direction="row"
      rowGap={ isMobile ? 1 : isProfilePage ? 2 : 5 }
      padding={ isMobile ? 2 : 3 }
      width="100%"
      flexWrap={ "wrap" }
      position={ "relative" }

      sx={ {
        ">p": {
          maxWidth: "calc(100% - 80px)"
        },

        ".styled-chip": {
          ">div": {
            background: isProfilePage ?  "transparent" : undefined
          }
        }
      } }
    >
      {
        !noItemsToShow ?

          <>
            <ModifyLink
              sx={ {
                position: "absolute",
                right: isMobile && isProfilePage ? "0" :  "24px"
              } }
              href="/signup/capabilities"
              state={ {
                isSummary:pathname.includes("summary")
              } }
            >
              <Typography >
                { t("modify") }
              </Typography>
            </ModifyLink>
            {
              isProfilePage ?

                <Typography
                  fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
                  fontWeight={ 400 }
                  lineHeight={ isMobile ? (21 / 16) : 1.05 }
                  width={ "80%" }
                >
                  { t("your_capabilities_description") }
                </Typography> : null
            }

            <Stack
              direction={ isMobile ? "column" : "row" }
              flexWrap="wrap"
              width={ "100%" }
              gap={ isMobile ? 1 : 2 }
              marginTop={ isProfilePage ? "16px" : 0 }
            >
              {
                capabilities?.some(item => item.purpose === CAPABILITY_TYPE.TRAINING) ?

                  <CapabilityItemContainer
                  >
                    <Stack
                      alignItems={ "center" }
                      flexDirection={ "row" }
                      gap={ 1 }>
                      <Icon
                        color={ theme.customColors.accentVariantB }
                        icon="allenamento"
                        size={ 20 }/>
                      <Typography
                      >{ t("training") }
                      </Typography>
                    </Stack>

                    <StyledChipsBox className="styled-chip">
                      {
                        capabilities.filter(
                          item => item.purpose === CAPABILITY_TYPE.TRAINING)?.map((capability) =>
                          (
                            <Chip
                              isDruggable={ false }
                              active={ false }
                              isDisabled={ false }
                              key={ `contentFormat--${capability.id}` }
                              name={ capability.label }
                              label={ capability.label }
                            />

                          ))
                      }
                    </StyledChipsBox>
                  </CapabilityItemContainer> :
                  null
              }

              {
                capabilities?.some(item => item.purpose === CAPABILITY_TYPE.IN_DEPTH) ?
                  <CapabilityItemContainer>
                    <Stack
                      alignItems={ "center" }
                      flexDirection={ "row" }
                      gap={ 1 }>
                      <Icon
                        color={ theme.customColors.accentVariantB }
                        icon="approfondimento"
                        size={ 20 }
                      />
                      <Typography>{ t("in_depth") }</Typography>
                    </Stack>

                    <StyledChipsBox className="styled-chip">

                      {
                        capabilities.filter(
                          item => item.purpose === CAPABILITY_TYPE.IN_DEPTH)?.map((capability) =>
                          (
                            <Chip
                              isDruggable={ false }
                              active={ false }
                              isDisabled={ false }
                              key={ `contentFormat--${capability.id}` }
                              name={ capability.label }
                              label={ capability.label  }
                            />

                          ))
                      }
                    </StyledChipsBox>
                  </CapabilityItemContainer> :
                  null
              }
            </Stack>

          </>   :

          <>
            <Typography
              fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
              fontWeight={ 400 }
              lineHeight={ isMobile ? (21 / 16) : 1.05 }
              width={ "80%" }
            >
              { noItemsText }
            </Typography>

            {
              onboardingAddText ?

                <ButtonLink href={ "/signup/capabilities" }
                  sx={ {
                    fontSize:theme.spacing(2.25),
                    fontWeight:500,
                    gap:theme.spacing(1.25),
                    justifyContent:"flex-start",
                    padding:0,
                    width:"100%"
                  } }
                >
                  { onboardingAddText }
                  <Icon
                    icon={ "arrow_right_horizontal" }
                    size={ 20 }
                    color={ theme.customColors.backgroundPrimaryCta }/>
                </ButtonLink> :
                null
            }
          </>

      }

    </PreferenceStack>
  );
}
