import {
  Grid,
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import {
  intervalToDuration } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  CarouselCardGrid,
  CarouselSlideBox,
  StyledStatusBox,
  StyledSwipeableDrawer
} from "./CarouselCardMobile.styles";
import type {
  CarouselCardMobileProps,
  StatusBoxProps
} from "./CarouselCardMobile.types";
import {
  CardCategories,
  CardCta,
  CardSignature,
  CardType,
  Icon,
  LinkWithInteractableOverlay
} from "../../components";
import { STATUS_LO } from "../../consts";
import { useAccent } from "../../hooks";
import { formatDate } from "../../services/i18n-format";
import { hasPassedExpiration } from "../../utils/datetime";
import {
  DetailTypography,
  StyledDurationBadge,
  TitleTypography
} from "../CarouselCard/CarouselCard.styles";
import { TruncatedText } from "../TruncatedText";


export function CarouselCardMobile({
  categories,
  coverPublicURL,
  courseId,
  duration,
  disabled,
  ecmRegistration,
  ecmType,
  endDate,
  editionNumber,
  enrollType,
  expirationDate,
  grandParentId,
  id,
  isStandAlone,
  ecm_specialization,
  iconStatus,
  isMandatory,
  isTest,
  isSurvey,
  learningObjectType,
  learningObjectTypology,
  madeAttempts,
  open,
  parentId,
  percentageOfCompletion,
  rootId,
  setOpen,
  setOpenModalEcm,
  shortDescription,
  startDate,
  status,
  title,
  canAccessNow,
  credits
}: CarouselCardMobileProps) {
  const theme = useTheme();
  const isExpired = hasPassedExpiration(expirationDate);
  const accentColor = useAccent({ isExpired, isMandatory, status });
  const { t, i18n } = useTranslation();
  const durationNumber = parseInt(`${duration}`);
  const interval = intervalToDuration({
    end: !isNaN(Number(duration) || 0) && duration ?  Number(duration) * 60 * 1000 : 60 * 1000,
    start: 0 }) as Duration ;

  const messageStatusBox = (isExpired && expirationDate && status !== "C" && isMandatory )
    ? `${ t("expired_on") } ${ formatDate(expirationDate.join("/"), i18n.language) }`
    : undefined;

  return (
    <StyledSwipeableDrawer
      id={ `drawer-${title}` }
      anchor="bottom"
      onClose={ () => setOpen({
        label: "",
        openId: "",
        state: false
      }) }
      onOpen={ () => {} }
      open={ open.state || title === open.label }
      variant={ "temporary" }
    >
      <CarouselSlideBox>
        <CarouselCardGrid
          container
          accent={ accentColor }
          className={ "carosel-card--active" }
          component="section"
          direction="column-reverse"
          $coverPublicURL={ coverPublicURL }
          padding="0 1.4rem 1.4rem"
        >
          <Grid
            item
            xs={ 6 }
            container
            direction="column"
          >
            {
              duration && !isNaN(durationNumber) && durationNumber > 0 ?
                <StyledDurationBadge
                  top= { theme.spacing(3) }
                  padding={ theme.spacing(0.56, 1) }
                  right= { theme.spacing(1) }
                  $completed={ status === STATUS_LO.C }>
                  <Icon icon={ "Icons_time" } size={ 20 }/>
                  { interval.days ? t(interval.days === 1 ? "day" : "day_other", { count: interval.days }) : null }
                  { interval.hours ? " " + t(interval.hours === 1 ? "hour" : "hour_other", { count: interval.hours }) : null }
                  {  interval.minutes ? " " + interval.minutes + " " + t("min") : null }
                </StyledDurationBadge> : null
            }

            { (status === "C" || isMandatory ) &&
              <StatusBox
                message={ messageStatusBox }
                accent={ accentColor }
                icon={ iconStatus }
              />
            }
            <CardType
              ecmType={ ecmType }
              ecm_specialization={ ecm_specialization }
              fontSize="0.875rem"
              lineHeight={ 3 / 2 }
              textTransform={ "uppercase" }
              fontWeight={ 700 }
              isSurvey={ Boolean(isSurvey) }
              isTest={ Boolean(isTest) }
              learningObjectTypology={ learningObjectTypology }
              learningObjectType={ learningObjectType }
            />
            <TitleTypography
              $clamp={ 1 }
              fontSize="1.5rem"
              lineHeight={ 5 / 4 }
              marginBottom={ "8px" }
            >
              <TruncatedText text={ title } maxLength={ 35 } />
            </TitleTypography>
            <CardCategories
              categories={ categories }
              marginBottom={ "8px" }/>
            <Stack gap={ 1 }>
              <DetailTypography
                $clamp={ 3 }
                fontSize="1rem"
                lineHeight={ "1.3125rem" }
              >
                <TruncatedText text={ shortDescription } maxLength={ 60 } />
              </DetailTypography>
              <CardSignature
                startDate={ startDate }
                endDate={ endDate }
                editionNumber={ editionNumber }
                alignItems="center"
                gap={ 2 }
                expirationDate={ expirationDate }
                duration={ duration }
                learningObjectTypology={ learningObjectTypology }
                canAccessNow={ canAccessNow }
              />
              <CardCta
                courseId={ courseId }
                disabled={ disabled }
                disableFavourites={ Boolean(isTest || isSurvey) }
                ecm_specialization={ ecm_specialization }
                ecmRegistration={ ecmRegistration }
                enrollType={ enrollType }
                gap={ theme.spacing(2) }
                grandParentId={ grandParentId }
                id={ id }
                isCard={ true }
                isStandAlone={ isStandAlone }
                isSurvey={ isSurvey }
                isTest={ isTest }
                learningObjectType={ learningObjectType }
                learningObjectTypology={ learningObjectTypology }
                madeAttempts={ madeAttempts }
                parentId={ parentId }
                marginTop={ theme.spacing(1) }
                percentageOfCompletion={ percentageOfCompletion }
                rootId={ rootId }
                setOpenModalEcm={ setOpenModalEcm }
                status={ status }
                title={ title }
                credits={ credits }
                vertical
                canAccessNow={ canAccessNow }
              />
            </Stack>
            <LinkWithInteractableOverlay
              $disableLink={ disabled }
              $hideCta
              href={ `/esplora/dettaglio/${id}` }
              tabIndex={ 0 }
            >
              { title }
            </LinkWithInteractableOverlay>
          </Grid>
        </CarouselCardGrid>
      </CarouselSlideBox>
    </StyledSwipeableDrawer>
  );
}

function StatusBox({
  accent,
  icon,
  message,
  ...props
} : StatusBoxProps) {
  const theme = useTheme();

  return (
    <StyledStatusBox
      $accent={ accent }
      width={ message ? "fit-content" : "2.5rem" }
      { ...props }

    >
      <Icon
        icon={ icon }
        color={ theme.customColors.systemSecondary05 }
        size={ "20px" }
      />
      {
        (message) ? (
          <Typography
            component="span"
            fontSize={  "1rem" }
            fontWeight="500"
            color={ theme.customColors.textPrimaryAlternative }
          >
            { message }
          </Typography>
        ): null
      }
    </StyledStatusBox>
  );
}
