import { Box, styled } from "@mui/material";

export const StakeholderCardContainer = styled(Box)(() => ({
  borderRadius: "15px",
  height: "180px",
  position: "relative"
}));

export const StakeholderCardAvatarOverlay = styled(Box)(() => ({
  position: "absolute",
  borderRadius: "15px",
  left: 0,
  bottom: 0,
  right: 0,
  top: 0,
  backgroundColor: "rgba(21,21,21,0.4)"
}));

export const StakeholderCardAvatarContainer = styled(Box)(() => ({
  minWidth: "300px",
  height: "100%",
  borderRadius: "15px"
}));

export const StakeholderCardInfoContainer = styled(Box)(() => ({
  position: "absolute",
  borderRadius: "15px",
  bottom: 0,
  left: 0,
  right: 0,
  paddingLeft: "40px",
  paddingRight: "40px",
  paddingBottom: "20px"
}));

export const StakeholderCardName = styled(Box)(() => ({
  fontSize: "16px",
  fontWeight: "bold"
}));

export const StakeholderCardType = styled(Box)(() => ({
  fontSize: "14px"
}));


