import {
  Grid,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardGrid,
  CardBodyGrid,
  ImageBox,
  TitleTypography,
  ImageContainer
} from "./CardCourseTab.styles";
import { CardCourseTabProps } from "./CardCourseTab.types";
import { useNavbarStore } from "../../stores";
import { ButtonLink } from "../Button";
import { CardCategories } from "../CardCategories";
import { CardSignature } from "../CardSignature";
import { CardType } from "../CardType";
import { Icon } from "../Icon";
import { TruncatedText } from "../TruncatedText";

export function CardCourseTab({
  categories,
  coverPublicURL,
  date,
  duration,
  id,
  learningObjectType,
  learningObjectTypology,
  shortDescription,
  title,
  canAccessNow,
  ...props
}: CardCourseTabProps) {

  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const{ state:routerState } = useLocation();

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  return (
    <CardGrid container { ...props }>
      <ImageContainer item xs={ "auto"  }
      >
        <ImageBox
          alt="card cover"
          // container="img"
          src={ coverPublicURL }
        />
      </ImageContainer>
      <CardBodyGrid item  container>
        <CardType
          isTest={ false }
          isSurvey={ false }
          learningObjectTypology={ learningObjectTypology }
          learningObjectType={ learningObjectType }
          sx={ {
            [theme.breakpoints.down("sm")]:{
              "p":{
                fontSize:"1rem"
              }
            }
          } }
        />
        <TitleTypography variant="h3">
          { title }
        </TitleTypography>
        <CardCategories categories={ categories }/>
        <Typography lineHeight={ 1.3125 } textAlign="start">
          <TruncatedText text={ shortDescription } maxLength={ 60 } />
        </Typography>
        <CardSignature
          xs={ 0 }
          direction="row"
          gap={ 1 }

          duration={ duration }
          expirationDate={ date }
          learningActivityNumber={ 0 }
          learningObjectTypology={ learningObjectTypology }
          resources={ 0 }
          canAccessNow={ canAccessNow }
        />
        <Grid item container alignItems="center" gap={ 2 } { ...props } >

          <ButtonLink
            sx={ {
              fontWeight:500,
              gap:"0.625rem",
              padding:0,

              ".icon path":{
                fill:theme.palette.primary?.main
              }
            } }
            onClick={ ()=> {
              navigate(`/esplora/dettaglio/${id}/${learningObjectTypology}`, { state:{
                ...routerState,
                isFromCommunity:"/community"
              } });

              setNavbarItem("EXPLORE");
            } }
          >
            { t("go_root") }
            <Icon icon={ "arrow_right_horizontal" } width={ "20px" } height={ "14px" }/>
          </ButtonLink>
        </Grid>
      </CardBodyGrid>
    </CardGrid>
  );
}
