
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledCardGoals,
  StyledGridBadges
} from "./CardGoals.styles";
import {
  BadgeGoals,
  ButtonLink,
  Link, Modal
} from "../../components";
import { NAVBAR_ITEMS } from "../../consts";
import { useAchievementsQuery, useDownloadCertificate } from "../../queries";
import { useAuthStore, useNavbarStore } from "../../stores";
import { getError } from "../../utils/error";
import { downloadBlobFile } from "../../utils/general";
import { CertificateInfo } from "../CertificateButton/CertificateButtonContainer.types";

export function CardGoals() {
  const MAX_ITEMS = 6;

  const theme = useTheme();
  const { t } = useTranslation();
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);
  const [certificatesInDownload, setCertificatesInDownload] = useState<number[] | null>(null);
  const [showModal, setShowModal] = useState(false);

  const {
    data: userAchievements
  } = useAchievementsQuery({});

  const {
    error: downloadCertificateError,
    isError: isDownloadCertificateError,
    mutateAsync: mutateDownloadCertificate } = useDownloadCertificate();

  useEffect(() => {
    isDownloadCertificateError && setShowModal(true);
  }, [isDownloadCertificateError]);

  const downloadCertificateHandler = async (certificateInfo: CertificateInfo)=> {
    setCertificatesInDownload(certificatesInDownload => {
      return certificatesInDownload ? [...certificatesInDownload, certificateInfo.certificateId] :
        [certificateInfo.certificateId];
    });

    try {
      const certificateResponseBlob = await mutateDownloadCertificate(certificateInfo);
      downloadBlobFile({
        blobFile: certificateResponseBlob,
        fileName: `issued_certificate_lo_${certificateInfo.learningObjectId}_learner_${certificateInfo.learnerId}.pdf`
      });
    } finally {
      setCertificatesInDownload(certificatesInDownload => {
        if (certificatesInDownload && certificatesInDownload.length > 0) {
          const filteredIds = certificatesInDownload.filter(id => id !== certificateInfo.certificateId);
          if (filteredIds.length < 1) {
            return null;
          }
        }
        return null;
      });
    }
  };

  return smartConfiguration?.widgetBadge === true && userAchievements && userAchievements?.length > 0 ? (
    <StyledCardGoals>
      {
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          position="relative"
        >
          <Typography
            fontWeight={ "700" }
            sx={ {
              color: theme.customColors.textWidget,
              maxWidth:"100%",
              overflowWrap:"break-word"
            } }
          >
            { t("your_goals") }
          </Typography>
          {
            ( userAchievements && userAchievements.length > MAX_ITEMS) ? (
              <Link href="/profile"
                onClick={ ()=> { setNavbarItem(NAVBAR_ITEMS.NONE) } }
              >
                <ButtonLink
                  disableRipple
                  sx={ {
                    cursor:"pointer",
                    fontSize:"1rem",
                    fontWeight:"700",
                    padding:0,
                    textDecoration:"underline",
                    zIndex:"2",

                    "&:hover": {
                      background:"none",
                      boxShadow:"none"
                    },

                    [theme.breakpoints.down("sm")]: {
                      fontSize:"0.75rem"
                    }
                  } }
                >
                  { t("view_all") }
                </ButtonLink>
              </Link>
            ) : null
          }
        </Stack>
      }
      <StyledGridBadges>
        { userAchievements?.slice(0,6).map((item, index)=> (
          <BadgeGoals
            key={ index.toString() }
            small={ true }
            badge={ item.badge }
            color={ theme.customColors.textWidget }
            certificate={ item.certificate }
            downloadCertificate={ downloadCertificateHandler }
            isDownloading={ !!( item.certificate?.id &&
              certificatesInDownload &&
              certificatesInDownload.indexOf(item.certificate?.id)>=0)
            }
          />
        )) }
        <Modal
          open={ showModal && isDownloadCertificateError }
          onClose={ ()=> { setShowModal(false) } }
          description={ getError(downloadCertificateError)?.[0]?.message ?? "" }
          title={ getError(downloadCertificateError)?.[0]?.name ?? "" }
        />
      </StyledGridBadges>
    </StyledCardGoals>
  ) : null;
}
