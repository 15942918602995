import {
  Box, Typography,
  useTheme
} from "@mui/material";
import { getHours, getMinutes } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CardGrid,
  CardBodyGrid,
  InfoTypography,
  SeatsTypography
} from "./CardEdition.styles";
import { CardEditionProps } from "./CardEdition.types";
import {
  Icon,
  Link, Modal, StyledBoxContact
} from "../../components";
import { ModalSessionDetail } from "../../components/ModalSessionDetail";
import { LEVEL, STATUS_LO } from "../../consts";
import {
  useEditionSessionsQuery,
  useEnrollmentMutation,
  useInvalidateEditionsQuery,
  useInvalidateLearningObjectQuery,
  usePatchMutation
} from "../../queries";
import { formatDate } from "../../services";
import type { SessionEdition } from "../../types";
import { getError } from "../../utils/error";
import { getLearningObjectType } from "../../utils/general";
import { ButtonText } from "../Button";

export function CardEdition({
  booked,
  bookedSeats,
  editionId,
  endTime,
  enrollType,
  enrollmentStatus,
  // gMapsUrl,
  hasEditionEnrolled,
  id,
  learningObjectTypology,
  link,
  // location,
  maxSeats,
  // rooms,
  courseId,
  rootId,
  startTime,
  status,
  statusEdition,
  selfEnrollment,
  currentLO,
  ...props
}: CardEditionProps) {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const today = new Date().getTime();
  const [bannerVisible, setBannerVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [showSessionDetails, setShowSessionDetails] = useState(false);
  const { data: sessions } = useEditionSessionsQuery({ 
    editionId: editionId?.toString() ?? "",
    type: learningObjectTypology
  });
  const [nextSession, setNextSession] = useState<SessionEdition | null>(null);
  const [isCurrentSession, setIsCurrentSession] = useState<boolean>(false);
  
  
  const isRejected = statusEdition === "R" || currentLO.status === "R";
  const isBookable = (!booked && !hasEditionEnrolled) && (
    status === "N" 
  || status === "T" 
  || status === "R" 
  || status === "E" 
  );

  const { invalidateLearningObject } = useInvalidateLearningObjectQuery({
    courseId:courseId,
    id:id,
    learningObjectTypology:learningObjectTypology,
    rootId:rootId
  });
  const { invalidateEditions } = useInvalidateEditionsQuery({
    classroomId:id.toString()
  });
  
  const seatsAvailable = maxSeats - bookedSeats > 0;
  const newStartDate = new Date(startTime);
  const newEndDate =  new Date(endTime);
  const startEdition = formatDate(newStartDate, i18n.language)?.replaceAll("/","-");
  const endEdition = formatDate(newEndDate, i18n.language)?.replaceAll("/","-");
  
  const idEnrollment = rootId ? 
    rootId : courseId ? 
      courseId : id;

  const typologyEnrollment = rootId ? 
    LEVEL.PATH : courseId ? 
      LEVEL.COURSE : 
      getLearningObjectType(learningObjectTypology);

  const currentDay =
    booked && (
      (today >= new Date(startTime).getTime()  -  (2*60*60*1000)) &&
      (today <=  new Date(endTime).getTime())
    );

  const roomsString = nextSession?.rooms && nextSession?.rooms.length > 0 ? nextSession?.rooms.toString().replaceAll(","," - ") : null;

  const {
    mutate: addEnrollmentMutation,
    error: enrollmentMutationError,
    isError: enrollmentMutationIsError,
    isSuccess: enrollmentMutationHadSuccess,
    isLoading:enrollmentIsLoading
  } = useEnrollmentMutation({
    enrollType:enrollType,
    learningObjectId: idEnrollment,
    learningObjectTypology:typologyEnrollment
  });
  

  const {
    mutate: addEnrollmentEditionMutation,
    error: enrollmentEditionMutationError,
    isError: enrollmentEditionMutationIsError,
    isSuccess: enrollmentEditionMutationHadSuccess,
    isLoading:enrollmentEditionIsLoading
  } = usePatchMutation({
    editionId:editionId,
    learningObjectId: id,
    learningObjectTypology:learningObjectTypology,
    isToj: !!currentLO.tojEditionIds
  });

  useEffect(()=> {
    if(enrollmentMutationHadSuccess){
      addEnrollmentEditionMutation();
    }
  },[
    addEnrollmentEditionMutation, 
    enrollmentIsLoading, 
    enrollmentMutationError, 
    enrollmentMutationHadSuccess, 
    enrollmentMutationIsError
  ]);

  useEffect(()=> {
    if(
      (enrollmentMutationIsError && enrollmentMutationError) || 
      (enrollmentEditionMutationIsError && enrollmentEditionMutationError)
    ){
      const maximumError = getError(enrollmentEditionMutationError)?.[0]?.message.includes("participants maximum number");
      const alreadyAssociatedError = getError(enrollmentEditionMutationError)?.[0]?.message.includes("already associated with user");

      if (getError(enrollmentEditionMutationError)?.[0]?.name === "FATAL") {
        setShowModal(true);
        setModalText(t("attention"));
        return;
      }

      setBannerVisible(true);
      if (maximumError) {
        setBannerText(t("maximum-participants-error"));
      } else if (alreadyAssociatedError) {
        setBannerText(t("already-associated-error"));
      } else {
        setBannerText(t("enrollment-generic-error"));
      }
      
    }

    if(enrollmentEditionMutationHadSuccess && !enrollmentEditionIsLoading){
      invalidateLearningObject();
      invalidateEditions();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    enrollmentEditionIsLoading, 
    enrollmentEditionMutationError, 
    enrollmentEditionMutationHadSuccess, 
    enrollmentEditionMutationIsError, 
    enrollmentIsLoading, 
    enrollmentMutationError, 
    enrollmentMutationHadSuccess, 
    enrollmentMutationIsError]);

  const getButtonLabel = () => {
    if (enrollmentStatus === "PA" || selfEnrollment) {
      return t("subscribe");
    } else {
      if (statusEdition === STATUS_LO.W) {
        return t("validation_text");
      } else if (statusEdition === STATUS_LO.E) {
        return t("registered");
      } else {
        return t("register_request");
      }
    }  
  };

  // const getArrayString = (array: {
  //   id: string | number,
  //   completeName: string,
  //   operationalField: string | null,
  //   image: string
  // }[]) => {
  //   if (!!array && !!array.length) {
  //     const newArray = array.map((item: {
  //       id: string | number,
  //       completeName: string,
  //       operationalField: string | null,
  //       image: string
  //     }) => {
  //       return item.completeName;
  //     } );
  //     return newArray.join();
  //   } else {
  //     return "-";
  //   }
  // };

  const getNextSession = () => {
    const today = new Date().getTime();
    if (!nextSession) return;
    const start = new Date(nextSession.start_date);
    const endNumber = nextSession.end_date;

    if (Number(today) > Number(endNumber)) {
      return "";
    }

    console.log(getHours(start));
    

    return `${formatDate(new Date(start), i18n.language)?.replaceAll("/","-")} ${getHours(start) < 10 ? `0${getHours(start)}` : getHours(start)}:${getMinutes(start) < 10 ? `${getMinutes(start)}0` : getMinutes(start)}`;
  };

  useEffect(() => {
    const now = Date.now();
    const orderedSessions = sessions?.sort((a, b) => a.id - b.id);

    const currentSession = orderedSessions?.find(
      session => now >= Number(session.start_date) && now <= Number(session.end_date)
    );

    const isNext = orderedSessions?.find(
      session => now <= Number(session.start_date)
    );

    const lastEdition: SessionEdition | null = orderedSessions?.reduce((max, obj) => (obj.id > max.id ? obj : max), orderedSessions[0]) || null;

    if (currentSession) {
      setNextSession(currentSession);
      setIsCurrentSession(true);
    } else if (isNext) {
      setNextSession(isNext);
      setIsCurrentSession(false);
    } else {
      setNextSession(lastEdition);
      setIsCurrentSession(false);
    }
  }, [sessions]);

  const getEditionTeachers = () => {
    const teacherNamesSet = new Set();

    sessions?.forEach(item => {
      item.teachers.forEach(teacher => {
        if (teacher.completeName) {
          teacherNamesSet.add(teacher.completeName);
        }
      });
    });

    return Array.from(teacherNamesSet).join(",");
  };

  return (
    <>

      <CardGrid
        container
        $booked={ booked }
        { ...props }
      >
        <CardBodyGrid
          container
          item
          $booked={ booked }
          $currentDay={ currentDay }
          gap="1rem"
          width="50%"
        >
          {
            startEdition === endEdition ?
              <InfoTypography fontWeight={ 700 }>
                <Icon
                  icon="Icons_scadenza-e-edizioni"
                  size="20px"
                />
                { /* { formatDate(date, i18n.language) } */ }
                { startEdition }
              </InfoTypography> :
              <InfoTypography fontWeight={ 700 }>
                <Icon
                  icon="Icons_scadenza-e-edizioni"
                  size="20px"
                />
                { t("from") + " " +  startEdition + " " + t("to") + " " + endEdition }

              </InfoTypography>
          }
          { !!getNextSession() && (
            <InfoTypography className="location">
              <Icon
                icon={ link ? "Icons_meeting" : "Icons_location" }
                size="20px"
              />
              {
                link ? (
                  <Link
                    href={ link }
                    target={ "_blank" }
                    style={ {
                      pointerEvents:!currentDay? "none" : "auto"
                    } }
                  >
                    { t("meeting_online") }
                  </Link>
                ) : (
                  nextSession?.gmaps_url ? (
                    <p>
                      { isCurrentSession ? t("current-session") : t("next-session") }:
                      <Link
                        href={ nextSession?.gmaps_url }
                        target={ "_blank" }
                        style={ {
                          textDecoration:"underline"
                        } }
                      >
                        { nextSession?.location + " " }
                      </Link>
                      { roomsString
                        ?  ` - ${ t("room") } : ${ roomsString }`
                        : ""
                      }
                    </p>
                  ) : (
                    <p>
                      { nextSession?.location }{ roomsString
                        ?  ` - ${ t("room") } : ${ roomsString }`
                        : ""
                      }
                    </p>
                  )
                )
              }
            </InfoTypography>
          ) }
          { !!getNextSession() && (
            <InfoTypography className="time">
              <Icon
                icon="Icons_time"
                size="20px"
              />
              { isCurrentSession ? t("current-session") : t("next-session") }: { getNextSession() }
            </InfoTypography>
          ) }
          {
            !!getEditionTeachers() && (
              <InfoTypography className="teachers">
                <Icon icon="Icons_Profilo"
                  size="20px"/>
                { `${getEditionTeachers()}` }
              </InfoTypography>
            )
          }
          
        </CardBodyGrid>
        <div className="separator"  ></div>
        <CardBodyGrid
          item
          container
          alignItems="center"
          $booked={ booked }
          $currentDay={ currentDay }
          gap={ theme.spacing(1) }
          width="40%"
        >
          <StyledBoxContact $visible={ bannerVisible }
            sx={ {
              left:"unset",
              right:0,
              top:0
            } }>
            <Typography
              display={ "flex" }
              fontWeight= { 500 }
              color= { theme.customColors.textInput }
              maxWidth={ "calc(100% - 44px)" }
            >
              { bannerText }
            </Typography>
            <Icon
              icon="close"
              size={ 19 }
              color= { theme.palette.primary?.main }
              onClick={ ()=> { setBannerVisible(false) } }
            />
          </StyledBoxContact>

          <Box>
            <SeatsTypography component="span">{ bookedSeats || 0 }</SeatsTypography>
            <SeatsTypography
              component="span"
              fontWeight={ 600 }
            > / </SeatsTypography>
            <SeatsTypography component="span" fontWeight={ 700 }>{ maxSeats }</SeatsTypography>
          </Box>
          <InfoTypography color={ theme.customColors.systemSecondary03 }>
            <Icon
              color={ theme.customColors.systemSecondary03 }
              icon={ "Icons_obbligatorio" }
              size="20px"

            />
            { t("places_available", { count:maxSeats - bookedSeats }) }
          </InfoTypography>
        
          <ButtonText 
            sx={ {
              [theme.breakpoints.down("sm")]:{
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "129px"
              }
            } }
            disabled={ isRejected || !seatsAvailable || !isBookable }
            onClick={ ()=> {
              if(!booked){
                if(isBookable && (enrollType === "REQUESTED_AUTO_ENROLL" || enrollType === "AUTO_ENROLL" || selfEnrollment)) {
                  addEnrollmentMutation();
                }
                else {
                  setBannerText(t("contact_text"));
                  setBannerVisible(true);
                }
              }
            } }>
            { getButtonLabel() }
          </ButtonText>
          { sessions && sessions?.length > 1 && (
            <ButtonText
              sx={ {
                [theme.breakpoints.down("sm")]:{
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "129px"
                }
              } }
              onClick={ ()=> setShowSessionDetails(true) }>
              { t("show-sessions") }
            </ButtonText>
          ) }
          

        </CardBodyGrid>
      </CardGrid>
      <Modal
        open={ showModal }
        onClose={ ()=> {
          setShowModal(false);
        } }
        description="Generic error"
        title={ modalText }
      />
      <ModalSessionDetail
        open={ showSessionDetails }
        handleClose={ () => setShowSessionDetails(false) }
        title={ "Dettaglio sessioni" }
        data={ sessions }
        isVirtual={ learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS }
      />
    </>

  );
}
