import { Typography,
  Stack,
  Grid
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Fragment,
  PropsWithChildren
} from "react";
import { useTranslation } from "react-i18next";
import {
  ImageBox,
  TitleTypography,
  InfoStack,
  KeepLearningStack,
  CourseStack,
  LabelTypography,
  ProgressTypography
} from "./KeepLearningCard.styles";
import { KeepLearningCardCourse } from "./KeepLearningCard.types";
import {
  Progress,
  Carousel
} from "../../components";
import {
  CAROUSEL_TYPE,
  CONTENTS_NAME,
  NAVBAR_ITEMS,
  PAGE_NAME,
  STATUS_LO
} from "../../consts";
import {
  useContentQuery,
  useStructuresQuery
} from "../../queries";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";
import { useNavbarStore } from "../../stores/navbarStore";
import type { LearningObject } from "../../types";
import { TruncatedText } from "../TruncatedText";

export function KeepLearningCard() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const { data: pageStructureWidgetKeepLearning } = useStructuresQuery({
    pageName: PAGE_NAME.WIDGET_ONGOING
  });

  const { data: onGoing } = useContentQuery<Record<string, LearningObject[]>>({
    pageSize: 100,
    path: pageStructureWidgetKeepLearning?.relativePaths?.[0]?.apiPath
  });

  const courses = onGoing
    ?.[CONTENTS_NAME.ON_GOING]
    ?.map((onGoingObject) => {
      const card: KeepLearningCardCourse = {
        categories: onGoingObject.topicTags,
        coverPublicURL: onGoingObject.coverPublicURL,
        id: onGoingObject.id,
        learningObjectTypology: onGoingObject.learningObjectTypology,
        percentageOfCompletion: onGoingObject.percentageOfCompletion,
        status: onGoingObject.status,
        title: onGoingObject.title
      };
      return card;
    }) ?? [];

  const keepLearningCards = getKeepLearningCards(courses);
  if(smartConfiguration?.widgetContinueLearning === false){
    return(
      <></>
    );
  }
  return (
    <KeepLearningStack display={ "flex" } gap={ isMobile ? 2 : 1 } sx={ { color: theme.customColors.textWidget } }>
      <LabelTypography>
        { t("keep_learning") }
      </LabelTypography>
      { keepLearningCards.length == 0 &&
        <Typography>
          { t("no_courses_available") }
        </Typography>
      }
      { /* <GridBox
        columns={ isMobile ? 2 : 1 }
        gap={ 1 }
        sx={ {
          // display: "flex",
          // flexDirection: "column",

          [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "inherit",

            "& a": {
              width: "48%"
            }
          } } }
      > */ }
      <Carousel
        carouselType={ CAROUSEL_TYPE.DEFAULT }
        label={ "keep-learning" }
        isCalendar={ false }
      >
        { keepLearningCards.map((courses, index) => (
          <Stack
            key={ `kl-carousel-card-${index}` }
            alignItems={ "center" }
            direction={ "column" }
            gap={ "8px" }
            width={ "281px" }
            height={ index == 0 && courses.length < 5 ? "auto" :  "495px" }
            sx={ {
              [theme.breakpoints.down("sm")]:{
                height: "auto",
                width: "auto"
              }
            } }
          >
            { isMobile ?
              <Grid container columns={ { xs: 2 } } spacing={ { xs: 1 } }>
                { courses.map((course, idx) => (
                  <Grid item xs={ 1 } key={ idx }>
                    <CourseCard
                      key={ `keep-learning-course--${idx}` }
                      { ...course }
                    >
                      <Fragment>
                        <TitleTypography>
                          <TruncatedText text={ course.title } maxLength={ 20 }/>
                        </TitleTypography>
                        {

                          "percentageOfCompletion" in course
                          && (
                            course.status === STATUS_LO.P
                            || course.status === STATUS_LO.C
                          ) ?
                            (
                              <Progress
                                flexGrow={ 1 }
                                percentageOfCompletion={ course.percentageOfCompletion ?
                                  course.percentageOfCompletion : "0.0%" }
                              />
                            ) : null
                        }
                      </Fragment>

                    </CourseCard>
                  </Grid>

                )) }
              </Grid> :
              courses.map((course, idx) => (
                <CourseCard
                  key={ `keep-learning-course--${idx}` }
                  { ...course }
                >
                  <Fragment>
                    <TitleTypography>
                      <TruncatedText text={ course.title } maxLength={ 20 }/>
                    </TitleTypography>
                    {

                      "percentageOfCompletion" in course
                      && (
                        course.status === STATUS_LO.P
                        || course.status === STATUS_LO.C
                      ) ?
                        (
                          <>
                            <ProgressTypography sx={ { color: theme.customColors.textWidget } }>
                              { course.percentageOfCompletion ?? "0%" }
                            </ProgressTypography>
                            <Progress
                              percentageOfCompletion={ course.percentageOfCompletion ?
                                course.percentageOfCompletion :  "0.0%" }
                              flexGrow={ 1 }
                            />
                          </>
                        ) : null
                    }
                  </Fragment>
                </CourseCard>
              )) }
          </Stack>
        )) }
      </Carousel>
    </KeepLearningStack>
  );

  function getKeepLearningCards(courses: KeepLearningCardCourse[]) {
    let allCards = [];

    for (let i = 0; i < courses.length; i = i + 5) {
      let currentCard: KeepLearningCardCourse[] = courses.slice(i, (i + 5));
      allCards.push(currentCard);
    }

    return allCards;
  }
}


function CourseCard({
  children,
  coverPublicURL,
  id,
  learningObjectTypology,
  title
}: PropsWithChildren<KeepLearningCardCourse>) {
  const navigate = useNavigate();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  return (
    <CourseStack direction="row"
      onClick={ () => {
        navigate(`/per-te/dettaglio/${id}/${learningObjectTypology}`);
        setNavbarItem(NAVBAR_ITEMS.EXPLORE);
      } }
    >
      <ImageBox
        alt={ `card cover--${title}` }
        component="img"
        src={ coverPublicURL }
      />
      <InfoStack gap={ 0.5 } display={ "flex" } flexDirection="column">
        { children }
      </InfoStack>
    </CourseStack>
  );
}
