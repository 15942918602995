import {
  Stack,
  Avatar,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";


import type { TopicCardProps } from "./TopicCardPost.types";

import {
  Icon,
  CtaCommunityOverlay,
  ModifyContentModal,
  CommentsSection
  ,Link } from "..";
import { DeleteContentModal } from "../../components/DeleteContentModal";
import {
  ActionLink,
  BodyTypography,
  LinkTypography,
  OwnerNameTypography,
  TopicCardBody,
  TopicCardContainerStack,
  TopicCardTop,
  TopicTypography
} from "../../components/TopicCard/TopicCard.styles";
import { TopicCardSignature } from "../../components/TopicCardSignature";
import { POST_TYPE, ROLES } from "../../consts";
import { useAuthStore } from "../../stores";
import { stringAvatar, useGetCreatedLabel } from "../../utils/community";

export function TopicCardPost({
  attachments,
  body,
  changed,
  commentsNumber,
  created,
  id,
  likes,
  ownerImage,
  ownerName,
  topicOwner,
  selectedFilter,
  selectedTopic
}: TopicCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isModifyOpen, setIsModifyOpen] = useState(false);
  const[showComments,setShowComments]=useState(false);
  const userData = useAuthStore(state => state.userData);

  const isModerator = userData?.roles.some(role => role === ROLES.BUSINESS_ADMIN || role === ROLES.PRODUCT_OWNER) ;
  const userId = useAuthStore(state => state.userData?.id_user);
  const isEditable =  (userId && topicOwner == userId.toString());

  const isModified = created !== changed;

  return (
    <TopicCardContainerStack
    >

      <TopicCardTop
        direction={ "row" }
        gap={ "16px" }
        alignItems={ "center" }
      >
        <Avatar
          alt={ ownerName }
          { ...stringAvatar(ownerName) }
          src={ ownerImage }
          sx={ {
            height: isMobile ? "32px" : "56px",
            width: isMobile ? "32px" : "56px"
          } }
        />
        <Stack
          gap={ 1 }
          flexGrow={ 1 }
        >
          <Stack
            direction={ "row" }
            justifyContent={ "space-between" }
            alignItems={ "center" }>
            <OwnerNameTypography>{ ownerName }</OwnerNameTypography>
            <TopicTypography
              sx={ {
                fontWeight: 600
              } }>
              <Icon
                icon={ "free_post" }
                size={ isMobile ? 15 : 18 }
                color={ theme.customColors.textWidget }
              />
              { t("post") }
            </TopicTypography>
          </Stack>
          <Stack
            flexWrap={ "wrap" }
            direction={ "row" }
            justifyContent={ "space-between" }
            alignItems={ "center" }>

            <TopicCardSignature
              created={  useGetCreatedLabel(created)  }
              isModified={ isModified }
              size={ "medium" }
            />
            {
              isEditable || isModerator ?
                <Stack
                  direction="row"
                  alignItems={ "flex-end" }
                  gap={ "1rem" }
                >
                  {
                    isEditable ?
                      <ActionLink
                        onClick={ () => setIsModifyOpen(true) }
                      >
                        { t("modify") }
                      </ActionLink>  : null
                  }

                  <ActionLink
                    onClick={ () => setIsDeleteOpen(true) }
                  >
                    { t("delete") }
                  </ActionLink>
                </Stack>  :
                null
            }
          </Stack>
        </Stack>
      </TopicCardTop>

      <TopicCardBody>
        <BodyTypography>
          { body }

        </BodyTypography>
        {
          attachments?.["link"]?.[0]?.content  ?

            <Link
              href={ attachments?.["link"]?.[0]?.content }
              target={ "_blank" }
              rel="noreferrer"
              paddingBottom={ isMobile ? "16px" : "0" }
            >

              <Stack direction={ "row" } gap={ "8px" }>
                <LinkTypography>
                  {
                    attachments?.["link"]?.[0]?.name ||
                    getUrlname(attachments?.["link"]?.[0]?.content )
                  }
                </LinkTypography>
                <Icon
                  icon={ "Icons_link" }
                  width={ "20px" }
                  height={ "20px" }
                  color={ theme.customColors.backgroundPrimaryCta }
                />
              </Stack>
            </Link>

            :
            null
        }

        <CtaCommunityOverlay
          id={ id }
          comments={ commentsNumber }
          likes={ likes }
          commentsOpened={ false }
          commentsClickAction={ () => {
            setShowComments(!showComments);
          } }
          selectedFilter={ selectedFilter }
          selectedTopic={ selectedTopic }
        />

      </TopicCardBody>


      <CommentsSection
        topicOwnerId={ topicOwner }
        selectedFilter={ selectedFilter }
        selectedTopic={ selectedTopic }
        discussionId={ id }
        className={ showComments ? "visible" : "" }
      />

      {
        isDeleteOpen ?

          <DeleteContentModal
            selectedFilter={ selectedFilter }
            selectedTopic={ selectedTopic }
            id={ id }
            isModerator={ Boolean(isModerator) }
            postType={ POST_TYPE.FREE }
            onClose={ ()=> {
              setIsDeleteOpen(false);
            } }
            closeDrawer={ ()=> {
              setIsDeleteOpen(false);
            } }
            open={ isDeleteOpen }
            sx = { {

              [theme.breakpoints.down("sm")]:{
                ".MuiStack-root-ehwYJj": {
                  ">div":{
                    alignItems: "center"
                  }
                }
              }
            }
            }


          /> : null
      }

      {
        isModifyOpen ?
          <ModifyContentModal
            selectedFilter={ selectedFilter }
            selectedTopic={ selectedTopic }
            open={ isModifyOpen }
            onClose={ () => setIsModifyOpen(false) }
            id={ id }
            postType={ POST_TYPE.FREE }
            linklabel={ attachments?.["link"]?.[0]?.name ?? "" }
            linkvalue={ attachments?.["link"]?.[0]?.content ?? "" }
            body={ body }
            closeDrawer={ () => setIsModifyOpen(false) }
          /> : null

      }

    </TopicCardContainerStack>
  );
  function getUrlname(url: string) {

    return url.replace(/^https?:\/\//, "");
  }


}
