import {
  Box,
  BoxProps,
  Grid,
  SwipeableDrawer
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { CarouselCardGridProps } from "components/CarouselCard/CarouselCard.styles";

const cardHeight = "100%";
const cardWidth = "100%";

export const CarouselSlideBox = styled(Box)<
  BoxProps
>(() => ({
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  maxWidth: cardWidth,

  ":has(.carosel-card--active)": {
    padding: 0
  }
}));

export const CarouselCardGrid = styled(Grid)<CarouselCardGridProps & {accent: string}>(({
  accent,
  $coverPublicURL,
  theme
}) => ({
  background: `${theme.linearGradients.gradientCarouselCard}, url(${$coverPublicURL})`,
  backgroundPosition: "top",
  backgroundSize:"100% 100%",
  border: `0.125rem solid ${accent}`,
  borderRadius: "0.5rem",
  filter: `drop-shadow(0px 0px 3px ${accent})`,
  height: cardHeight,
  //zIndex: 10,

  [theme.breakpoints.down("sm")]: {
    backgroundSize: "cover"
  },

  transition: getAnimationParams([
    { duration: 200, property: "max-height", timing: "ease-in" },
    { duration: 200, property: "min-width", timing: "ease-in" }
  ])
}));

export const StyledStatusBox = styled(Box)<
  BoxProps & { $accent: string}
  >(({ $accent, theme }) => ({
    alignItems: "center",
    backgroundColor: $accent,
    borderRadius: "0 0.25rem 0.25rem 0",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: theme.customColors.systemSecondary05,
    display: "flex",
    gap:"1rem",
    height: "2.5rem",
    justifyContent: "center",
    left: 0,
    padding:"10px 8px",
    position: "absolute",
    top: "1.375rem"
  }));

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    background: "transparent",
    bottom:0,
    boxSizing: "border-box",
    height: "70%"
  }
}));

function getAnimationParams(
  entries: {
    duration: number
    property: string,
    timing: string
  }[]
) {
  return entries.map(({ duration, property, timing }) => (
    `${property} ${duration}ms ${timing}`
  )).join(", ");
}
