import {
  Typography,
  useMediaQuery,
  type TypographyProps
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useTranslation } from "react-i18next";
import { Icon } from "../../components";
import {
  useLevel,
  useType
} from "../../hooks";
import type { LearningObject } from "../../types";

type CardTypeProps =
  // & Omit<
  &  TypographyProps
  //   | "id"
  // >
  & Pick<
    LearningObject,
      // | "id"
      | "isSurvey"
      | "isTest"
      | "learningObjectTypology"
      | "learningObjectType"
    >
& Partial<
  Pick<
    LearningObject,
    | "ecmType"
    | "ecm_specialization"
  >
>

export function CardType({
  ecm_specialization,
  ecmType,
  isTest,
  isSurvey,
  learningObjectType,
  learningObjectTypology,
  ...props
}: CardTypeProps ) {
  const typeObjectLearning  = useType(learningObjectType, learningObjectTypology);

  const learningObjectTypologyType = useLevel(learningObjectTypology);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t }=useTranslation();

  return (
    <Typography
      alignItems={ "center" }
      color={ props.color || theme.customColors.exploreCardText }
      display={ "flex" }
      flexWrap={ "wrap" }
      gap={ .5 }
      width={ "100%" }
      fontWeight={ "400"  }
      textTransform={ "uppercase" }
      { ...props }
    >
      <Typography
        alignItems={ "center" }
        fontWeight={ isMobile ? "400" : "700" }
        component="span"
        textTransform={ "uppercase" }
        display={ "flex" }
        gap={ 1 }
        { ...props }
      >
        {
          isTest || isSurvey ?
            <Icon
              icon={ isTest ? "test" : "survey" }
              size={ 16 }/> : null
        }
        {
          isTest ?
            t("test") :

            isSurvey ?
              t("survey") :
              ecmType && ecm_specialization ?
                ecmType :
                typeObjectLearning || ""
        }

      </Typography  >
      {
        !isTest && !isSurvey ?

          " | " + learningObjectTypologyType  : null
      }

    </Typography>
  );
}
