import {
  Box,
  Grid,
  Modal,
  Stack,
  Typography,
  styled
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { t } from "i18next";
import {
  ButtonText,
  Icon
} from "..";
import {
  useAuthStore
} from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";

const StyledModalContent = styled(Grid)(({ theme }) => ({
  background: theme.linearGradients.gradientB ,
  borderRadius:theme.spacing(0.5),
  gap:theme.spacing(2),
  height:"fit-content",
  margin:"auto",
  maxHeight:"400px",
  maxWidth:"670px",
  padding:theme.spacing(3,5),
  width:"100%",

  ".icon":{
    cursor:"pointer"
  },

  [`${theme.breakpoints.down("sm")}`]:{
    padding:theme.spacing(2.5)
  }
}));

export function ImpersonationModal({
  close,
  open=false
} : {
  close: () => void
  open: boolean
}) {
  const theme = useTheme();
  /*const stopImpersonation = useAuthStore(state => state.stopImpersonation);*/
  const { adminLink } = getEnvironmentVariables();
  const sessionToken = useAuthStore(state => state.sessionToken);
  const authenticationMode = useAuthStore(state => state.mode);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const inititiveUrl =  smartConfiguration?.urlDomain
    ? `${smartConfiguration?.urlDomain}.${smartConfiguration.urlCorporateDomain}`
    : smartConfiguration?.urlCorporateDomain
      ?  `${smartConfiguration?.urlCorporateDomain}`
      : window.location.host;

  function deleteCookie(cname: string) {
    document.cookie =
    cname + "=delete;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/";
  }

  return (
    <Modal open={ open }
      sx={ {
        "&.MuiModal-root":{
          alignItems:"center",
          display:"flex",
          justifyContent:"center",
          zIndex:99999
        }
      } }
    >

      <StyledModalContent
      >
        <Box sx={ {
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "32px"
        } }>
          <Typography
            color={ theme.customColors.textPrimary }
            fontWeight={ 600 }
            fontSize={ 24 }
          >
            { t("end-impersonation-title") }
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
          >
            <Icon
              color={ theme.customColors.systemPrimary02 }
              icon="close"
              onClick={ close }
              size={ 20 }
            />
          </Stack>
        </Box>
        <Typography
          color={ theme.customColors.textPrimary }
          fontWeight={ 400 }
          fontSize={ 16 }
        >
          { t("end-impersonation-description") }
        </Typography>
        <Box sx={ {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "16px",
          mt: "40px"
        } }>
          <ButtonText
            variant="outlined"
            onClick={ () => {
              close();
            } }
          >
            { t("annul") }
          </ButtonText>
          <ButtonText
            onClick={ () => {
              /*stopImpersonation();*/
              setTimeout(() => {
                deleteCookie("activeLangCode");
                deleteCookie("isImpersonation");
                window.location.href = `${adminLink}?sessionId=${sessionToken}&mode=${authenticationMode}&initiativeUrl=${inititiveUrl}`;
              }, 1000);
            } }
          >
            { t("confirm") }
          </ButtonText>
        </Box>
      </StyledModalContent>

    </Modal>
  );
}
