import {
  Stack,
  Avatar,
  Box,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  useNavigate
} from "react-router-dom";

import {
  TopicCardContainerStack,
  OwnerNameTypography,
  TitleTypography,
  BodyTypography,
  TopicTypography,
  TopicCardTop,
  TopicCardBody } from "./TopicCard.styles";
import type { TopicCardProps } from "./TopicCard.types";
import {
  Icon,
  CardCategories,
  CtaCommunityOverlay,
  ButtonLink,
  TopicCardSignature
} from "../../components";
import {
  useGetCreatedLabel,
  getReadingTime,
  secondsToMinuteslabel,
  stringAvatar
} from "../../utils/community";

export function TopicCard({
  ownerName,
  created,
  title,
  topic,
  body,
  ownerImage,
  image,
  commentsNumber,
  likes,
  id,
  subtitle,
  attachments,
  selectedFilter,
  selectedTopic
}: TopicCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();


  return (
    <TopicCardContainerStack >

      <TopicCardTop>
        <Avatar
          alt={ ownerName }
          { ...stringAvatar(ownerName) }
          src={ ownerImage }
          sx={ {
            height: isMobile ? "32px" : "56px",
            width: isMobile ? "32px" : "56px"
          } }
        />
        <Stack
          gap={ 1 }
          flexGrow={ 1 }>
          <Stack
            direction={ "row" }
            justifyContent={ "space-between" }
            alignItems={ "center" }
          >
            <OwnerNameTypography>{ ownerName }</OwnerNameTypography>
            <TopicTypography
              sx={ {
                fontWeight: 600
              } }>
              <Icon
                icon={ "Icons_Advertising" }
                size={ isMobile ? 15 : 18 }
                color={ theme.customColors.textWidget }
              />
              { t("talk") }
            </TopicTypography>
          </Stack>
          <Stack
            direction={ "row" }
            justifyContent={ "space-between" }
            alignItems={ "center" }>

            <TopicCardSignature
              created={ useGetCreatedLabel(created) }
              readingTime={  secondsToMinuteslabel(
                getReadingTime(body)
                + Number(attachments.brightcove ? attachments.brightcove?.[0].duration : "0")
                + Number(attachments.document ? attachments.document?.[0].duration : "0")
              ) }
              size={ "medium" }
            />
          </Stack>
        </Stack>
      </TopicCardTop>

      {
        title || topic ?

          <Stack
            gap={ 1 }

          >
            <TitleTypography>{ title }</TitleTypography>
            <CardCategories categories={ topic }/>
          </Stack> :
          null
      }

      <TopicCardBody
        gap={ isMobile ? 3 : 1 }
      >
        {
          subtitle ?
            <BodyTypography >
              { subtitle }
            </BodyTypography> :
            null
        }


        <ButtonLink
          sx={ { color: theme.customColors.backgroundPrimaryCta } }
          onClick={ () => {
            navigate(
              `/community/${id}`,
              {
                state: {
                  viewComment: false
                }
              }
            );
          }
          }
        >
          { t("read_more") }
          <Icon
            color={ theme.customColors.backgroundPrimaryCta }
            icon={ "arrow_right_horizontal" }
            size={ 20 }
          />
        </ButtonLink>


        { image && image.length > 0 &&
          <Box
            component="img"
            alt="image"
            src={ image }
            sx={ {
              borderRadius: "8px",
              height:" auto",
              marginTop:"8px",
              maxHeight: "fit-content",
              maxWidth: "calc(100% - 91px)",
              objectFit: "contain",
              width:"100%"

            } }
          />
        }
        <CtaCommunityOverlay
          id={ id }
          comments={ commentsNumber }
          likes={ likes }
          commentsOpened={ false }
          commentsClickAction={ () => {
            navigate(
              `/community/${id}`,
              {
                state: {
                  viewComment: true
                }
              }
            );
          } }
          selectedFilter={ selectedFilter }
          selectedTopic={ selectedTopic }
        />
      </TopicCardBody>


    </TopicCardContainerStack>
  );


}
