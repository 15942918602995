import { Box, Grid, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useOutletContext
} from "react-router-dom";
import {
  ButtonText,
  GuardedLink,
  Icon,
  Modal,
  Progress,
  Spinner
} from "../../components";
import { ENROLL_TYPE, LEVEL, STATUS_LO } from "../../consts";
import {
  useButtonStatusMap,
  useResumeLink
} from "../../hooks";
import {
  useEnrollmentMutation,
  useFavouritesMutation,
  useInvalidateLearningObjectQuery,
  useRatingQuery } from "../../queries";
import { OutletContext } from "../../routes";
import { useAuthStore } from "../../stores/authStore";
import { getError } from "../../utils/error";
import { getLearningObjectType } from "../../utils/general";
import type { CardCtaProps } from "../CardCta/CardCta.types";
import { CtaOverlayIcon } from "../CtaOverlay/CtaOverlay.styles";

export function CardCtaModule({
  badge,
  certificate,
  ecmRegistration,
  enrollType,
  ecm_specialization,
  isCard = true,
  isTest,
  isSurvey,
  madeAttempts,
  id,
  isStandAlone,
  courseId=undefined,
  disabled,
  // disableFavourites,
  learningObjectTypology,
  percentageOfCompletion,
  learningObjectType,
  rootId=undefined,
  parentId,
  grandParentId,
  status,
  canAccessNow,
  ...props
}: CardCtaProps) {
  const { sectionUrl } = useOutletContext<OutletContext>();
  const { t } = useTranslation();
  const navigate= useNavigate();
  const [showModal, setShowModal] = useState(false);

  const buttonText = useButtonStatusMap({
    ecm_specialization:ecm_specialization,
    ecmRegistration:ecmRegistration,
    enrollType,
    isCard,
    isSurvey:isSurvey,
    isTest:isTest,
    learningObjectType,
    learningObjectTypology,
    madeAttempts:madeAttempts,
    percentageOfCompletion:percentageOfCompletion
  });
  const isLoading = useAuthStore(state => state.isLoading);
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const theme = useTheme();
  const resumeLink = useResumeLink({
    ecm_specialization:false,
    ecmRegistration:false,
    enrollType,
    id,
    learningObjectType,
    learningObjectTypology,
    sectionUrl,
    status
  });

  const { invalidateLearningObject } = useInvalidateLearningObjectQuery({
    courseId:courseId,
    id:id,
    learningObjectTypology:learningObjectTypology,
    rootId:rootId
  });

  // eslint-disable-next-line max-len
  const idEnrollment =  grandParentId ? grandParentId : parentId ? parentId : rootId ? rootId : courseId ? courseId : id;
  const typologyEnrollment = rootId ? LEVEL.PATH : courseId ? LEVEL.COURSE : learningObjectTypology;

  const resumeLinkLinkedin = learningObjectTypology === LEVEL.DA_LTI || learningObjectTypology === LEVEL.DA_LINKEDIN ?
    `/esplora/scorm/${id}/${learningObjectTypology}?courseId=${courseId}&rootId=${rootId}` :
    undefined;

  const {
    mutate: addEnrollmentMutation,
    error: enrollmentMutationError,
    isError: enrollmentMutationIsError,
    isLoading:enrollmentIsLoading,
    isSuccess: enrollmentMutationHadSuccess
  } = useEnrollmentMutation({
    enrollType:enrollType,
    learningObjectId: idEnrollment,
    learningObjectTypology:typologyEnrollment
  });
  const { data: ratingStatistic } = useRatingQuery({
    learningObjectId: id,
    learningObjectTypology: getLearningObjectType(learningObjectTypology)
  });

  // TO ADD IN PARAMS IN FAVOURITES RETRIEVED FROM LO
  const favParentId = !isStandAlone ? rootId ? rootId : courseId ?? null : null;

  const favGrandParentId = (!isStandAlone
    && favParentId
    && favParentId  !== rootId
  ) ? rootId : null ;

  const {
    mutate: addFavouriteMutation,
    isLoading:isAddingFavouriteLoading
  } = useFavouritesMutation({
    grandParentId:favGrandParentId,
    learningObjectId: id ?? "",
    learningObjectTypology,
    method: "POST",
    parentId:favParentId
  });

  const {
    mutate: removeFavouriteMutation,
    isLoading:isRemovingFavouriteLoading
  } = useFavouritesMutation({
    learningObjectId: id ?? "",
    learningObjectTypology,
    method: "DELETE"
  });

  const resumeContent = useCallback(() => {
    navigate(
      resumeLink,
      {
        state: { courseId, rootId }
      }
    );
  }, [courseId, navigate, resumeLink, rootId]);

  useEffect(() => {
    if (enrollmentMutationHadSuccess ) {
      if(resumeLinkLinkedin){
        window.open(resumeLinkLinkedin, "_blank", "noreferrer");
      }
      else {
        resumeContent();
      }
      invalidateLearningObject();
    }
  }, [
    enrollmentMutationHadSuccess,
    invalidateLearningObject,
    navigate,
    resumeContent,
    resumeLinkLinkedin
  ]);

  useEffect(() => {
    enrollmentMutationIsError && setShowModal(true);
  }, [enrollmentMutationIsError]);

  const loStatistic = {
    //TO DO check favouritesMutation add old in all response
    userFavourite: Boolean(ratingStatistic?.userRating.isFavouriteForLearner &&
      ratingStatistic?.objectStatistics?.learningObjectId)
    // userFavourite: favouriteStatistic?.learningObject.find((isFavourite: Favourite) => (
    //   favourite.learningObjectId === (id ?? "") &&
    //   favourite.learningObjectType === learningObjectType
    // )),
  };


  return (
    <Grid
      item
      container
      alignItems="center"
      gap={ 1 }
      { ...props }
    >
      { (
        (status === STATUS_LO.P || status === STATUS_LO.C )
        && percentageOfCompletion)

        ? (
          <Progress
            percentageOfCompletion={ percentageOfCompletion }
            flexGrow={ 1 }
            maxWidth={ "293px" }
          />
        ) : null }

      <GuardedLink
        disabled={ disabled || !canAccessNow }
        variant={ status === STATUS_LO.C ? "outlined" : "contained" }
        href={ disabled ? "" : resumeLink.includes("guarda") && resumeLinkLinkedin ? resumeLinkLinkedin : resumeLink }
        onClick={ onClickLink }
        sx={ { flexGrow: 1 } }
        state={ {
          courseId:courseId,
          rootId:rootId
        } }
        target={ resumeLink.includes("guarda") && resumeLinkLinkedin ? "_blank" :  "_self" }
      >

        {
          enrollmentIsLoading && (!enrollmentMutationHadSuccess || enrollmentMutationError) ?

            <Spinner size={ 20 } padding={ 0 }/>  :
            t(buttonText[status]?.label)
        }
      </GuardedLink>

      {

        ( smartConfiguration?.funcFavourites && !isLoading  && !rootId) ?
          <ButtonText
            disabled={ disabled }
            title={ t("item_added_favourites") }
            className={ "bookmark" }
            variant={ "outlined" }
            sx={ {
              minWidth: "unset",
              padding: "10px"
            } }
          >

            {

              (isAddingFavouriteLoading || isRemovingFavouriteLoading ) ?
                <Spinner size={ 20 } padding={ 0 }/> :
                (loStatistic.userFavourite) ? (
                  <CtaOverlayIcon
                    icon={  "Icons_salva-preferito_glow" }
                    size={ 20 }
                    onClick={ () => {
                      removeFavouriteMutation();
                    } }/>
                ) : (
                  <CtaOverlayIcon
                    icon={ "plus" }
                    size={ 20 }
                    onClick={ () => {
                      addFavouriteMutation();
                    } }/>
                )
            }
          </ButtonText> : null
      }
      {

        (badge || certificate) ?

          <Box
            onClick={ (e)=> {

              if(badge?.isEnable || certificate?.isEnable){
                navigate("/profile");
              }
              else {
                e.preventDefault();
              }
            } }
            sx={ {
              cursor: (badge?.isEnable || certificate?.isEnable) ? "pointer" :"not-allowed",
              position:"relative"
            } }
          >
            <Icon
              color={ (badge?.isEnable || certificate?.isEnable) ?
                theme.customColors.systemPrimary02 :
                theme.customColors.systemPrimary01 }
              icon={ "Icons_security_03" }
              size={ 24 }
              style={ {
                marginLeft: theme.spacing(0.2)
              } }

            />

          </Box>  :

          null
      }
      <Modal
        open={ showModal && enrollmentMutationIsError }
        onClose={ ()=> { setShowModal(false) } }
        description={ getError(enrollmentMutationError)?.[0]?.message ?? "" }
        title={ getError(enrollmentMutationError)?.[0]?.name ?? "" }
      />

    </Grid>
  );
  function onClickLink() {
    if(status === "N" && (
      enrollType === ENROLL_TYPE.AUTO_ENROLL ||
      enrollType === ENROLL_TYPE.REQUESTED_AUTO_ENROLL
    )){
      addEnrollmentMutation();
      return false;
    }
    else {
      return true;
    }
  }
}
