import { Box, BoxProps, Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "../Icon";

export const ScrollToTopButton = styled(Button)<
  ButtonProps & {isVisible: boolean }>(({ theme, isVisible }) => ({
    bottom: "90px",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.7)",
    minWidth: "3rem",
    opacity: isVisible ? 1 : 0,
    padding: "0rem",
    pointerEvents: isVisible ? "auto" : "none",
    position: "fixed",
    right: "30px",
    transition: "opacity 0.15s ease-in-out",
    width: "3rem",
    zIndex: "1200",

    [theme.breakpoints.down("sm")]:{
      bottom: "120px",
      minWidth: "2.5rem",
      width: "2.5rem"
    }
  }));

export const ScrollToTopButtonContent = styled(Box)<
  BoxProps
>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.customColors.backgroundPrimaryCta,
  borderRadius:  "0.25rem",
  color: theme.palette.common?.white,
  display: "flex",
  height: "3rem",
  justifyContent: "center",
  transition:"none",
  width: "3rem",

  [theme.breakpoints.down("sm")]:{
    height: "2.5rem",
    width: "2.5rem"
  }
}));

export const ScrollToTopButtonIcon = styled(Icon)(({ theme }) => ({
  "&":
    { "path":
        { fill: theme.customColors.systemSecondary05 }
    },
  rotate: "90deg"
}));
