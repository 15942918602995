import { useTheme } from "@mui/material/styles";
import { PickersDayProps } from "@mui/x-date-pickers";
import {
  isAfter,
  isSameDay
} from "date-fns";
import { CustomRegex } from "./regex";
import { CONTENT_TYPE } from "../consts";
import type {
  CalendarDateObject,
  CalendarLearningObject,
  JavaBasicDate
} from "../types";

export function contentIsExpiring(
  course: CalendarLearningObject | null,
  date: Date
) {
  return (
    course?.isMandatory
    && (
      course?.learningObjectType == "ASYNC"
      || course?.learningObjectType == "BLENDED"
    )
    && isSameDay(
      date,
      new Date(course.expirationDate.join("/"))
    )
  );
}

export function hasPassedCurrentDate({
  date,
  time
} : {
  date: JavaBasicDate,
  time: string | null
}) {
  const slotDate = calendarTimeAsDateTime({ date, time: time ?? "00 : 00" });
  const currentDate = new Date();
  return isAfter(currentDate, slotDate);
}

export function calendarTimeAsDateTime({
  date,
  time
} : {
  date: JavaBasicDate
  time: string | null // "0 : 0", "10:2"
}) {
  const splitTime = CustomRegex.matchTimeOfDay(time ?? "00 : 00");
  return new Date(
    date[0],                //year
    date[1] - 1,            //monthIndex
    date[2],                //day
    parseInt(splitTime[1]), //hours
    parseInt(splitTime[2])  //minutes
  );
}

export function useDayColor(thisDayObjects: CalendarDateObject[]) {
  const theme = useTheme();

  const hasExpiring = thisDayObjects.some((dayObject) =>
    dayObject.dateObjectType === "learningObject"
    && contentIsExpiring(dayObject.learningObject, new Date(dayObject.date.join("/")))
  );

  const hasMandatory = thisDayObjects.some((dayObject) =>
    dayObject.dateObjectType === "learningObject"
    && dayObject.learningObject.learningObjectType === CONTENT_TYPE.SYNC
    && dayObject.learningObject.isMandatory
  );

  const hasLearningObject = thisDayObjects.some((dayObject) =>
    dayObject.dateObjectType === "learningObject"
  );

  const hasSmartSlot = thisDayObjects.some((dayObject) =>
    dayObject.dateObjectType === "smartLearning"
  );

  if (thisDayObjects?.length == 0) { //no courses
    return theme.customColors.textWidget;
  } else if (hasExpiring) { //expiring courses
    return theme.customColors.backgroundWarning;
  } else if (hasMandatory) { //mandatory courses
    return theme.customColors.backgroundAccent;
  } else if (hasLearningObject) {
    return theme.customColors.backgroundAccent;
  } else if (hasSmartSlot) {
    return theme.customColors.systemSecondary04;
  } else {
    return theme.customColors.backgroundAccent;
  }
}

export function withPickersDayProps<T>({
  component: Component,
  ...outerProps
} : { component: (props: PickersDayProps<Date> & T) => JSX.Element } & T) {
  return function ( props: PickersDayProps<Date>) {
    return Component({ ...outerProps as T, ...props });
  };
}
