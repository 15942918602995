import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AddSkillBookTemplate from "./AddSkillBookTemplate";
import { Button, Icon } from "../../../components";
import { ModalBasic } from "../../../components/ModalBasic";

export const AddSkillBook = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => setIsOpen(!isOpen);

  return (
    <>
      <Box
        sx={ {
          alignItems: "center",
          borderBottom: `1px solid ${theme.customColors.border}`,
          borderTop: `1px solid ${theme.customColors.border}`,
          display: "flex",
          marginY: "20px",
          paddingY: "10px",
          width: "100%"
        } }
      >
        <Typography variant="h6" color={ theme.customColors.textPrimary } sx={ { marginRight: "auto" } }>
          { t("profile_register_skillBook") }
        </Typography>
        <Button
          id={ "button" }
          variant="contained"
          color="primary"
          onClick={ handleModal }
        >
          <Box sx={ { marginRight: "10px" } }>{ t("profile_upload") }</Box>
          <Icon
            icon="Icons_upload"
            size={ 15 }
          />
        </Button>
      </Box>
      <ModalBasic
        open={ isOpen }
        handleClose={ handleModal }
        title={ t("upload_skillBook") }
        content={ <AddSkillBookTemplate closeModal={ handleModal } /> }
      />
    </>
  );
};
