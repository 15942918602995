import {
  Divider,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CardsBox,
  HeroBox,
  LastSavedTypography
} from "./Bookmarks.styles";
import {
  BigCarouselCard,
  Button,
  Card,
  Carousel,
  CarouselCard,
  GridMain,
  Icon,
  Spinner,
  TabsFilters,
  WidgetDrawer
} from "../../components";
import { HeroCard } from "../../components/HeroCard/HeroCard.component";
import { HeroCardBookmarkVariants } from "../../components/HeroCard/HeroCard.variants";
// import * as NewsCardVariants from "../../components/NewsCard/NewsCard.variants";
import {
  CAROUSEL_TYPE,
  CONTENTS_NAME,
  PAGE_NAME,
  STATUS_LO
} from "../../consts";
import { UserNavigationContext } from "../../context/UserNavigationContext";
import {
  useContentQuery,
  useInfiniteContentQuery,
  useStructuresQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningObject,
  MetaData,
  StatusLO
} from "../../types";
import { createEndDate, createStartDate } from "../../utils/general";
import { CarouselBox } from "../Explore/Explore.styles";

export function Bookmarks() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  /* const [pageSize, setPageSize] = useState<number>(12); */
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = useState<StatusLO | null>(null);
  const { data: pageStructureBookmarks, isFetching:loadingPageStructure } = useStructuresQuery({
    pageName: PAGE_NAME.FAVOURITES_OVERVIEW
  });
  const lastFavouritesObject = pageStructureBookmarks?.relativePaths?.[0];
  const lastFavouritesPath = lastFavouritesObject?.apiPath ?? "";
  const favouritesObject = pageStructureBookmarks?.relativePaths?.[1];
  const favouritesPath = favouritesObject?.apiPath ?? "";
  const ratedObject = pageStructureBookmarks?.relativePaths?.[2];
  const ratedObjectPath = ratedObject?.apiPath ?? "";
  const { setNavigation } = useContext(UserNavigationContext);

  useEffect(() => {
    setNavigation && setNavigation([]);
    setNavigation && setNavigation(["/bookmarks"]);
  }, [location.pathname]);

  const {
    data: lastFavourites,
    isFetching: loadingLastFavourites
  } = useContentQuery<{
    lastFavouriteLearningObjects: LearningObject[]
    metadata: MetaData
  }>({
    pageSize: 4,
    path: lastFavouritesPath,
    status: ""
  });

  const {
    data: favourites,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching: favouritesAreLoading
  } = useInfiniteContentQuery<{
    favouriteLearningObjects: LearningObject[],
    metadata: MetaData
  }>({
    pageSize: 12,
    path: favouritesPath,
    status: statusFilter
  });

  const {
    data: topRated,
    isFetching: topRatedIsLoading
  } = useContentQuery<{
    topRatedLearningObjects: LearningObject[]
    metadata: MetaData
  }>({
    path: ratedObjectPath,
    status: ""
  });

  const hasFavourites = favourites?.pages?.some((page) =>
    page?.favouriteLearningObjects?.length > 0
  );
  const learnerFavourites = favourites?.pages?.reduce((acc, page) => {
    const contents = page?.[CONTENTS_NAME.FAVOURITES] ?? [];
    return acc.concat(contents);
  }, [] as LearningObject[]);

  return (
    <GridMain>
      <Stack
        maxWidth="calc(100vw - (100vw - 100%))"
        width={ "100%" }
        sx={ {
          ".button-back": {
            color: theme.customColors.textPrimary,
            fontSize: "1.141rem",
            fontWeight: "400",
            gap: "9px",
            justifyContent: "flex-start",
            padding: "32px 16px 37px",
            textAlign: "start",

            "&:hover": {
              background: "transparent"
            },

            ".icon": {
              height: "18px",
              width: "13px"
            }
          }
        } }
      >
        <WidgetDrawer />
        {
          ( loadingLastFavourites ) ?
            (
              <Spinner
                size={ isMobile? 100 : 200 }
                thickness={ 3 }
              />
            ) :
            (
              (
                lastFavouritesObject
                && lastFavourites?.lastFavouriteLearningObjects
                && lastFavourites?.lastFavouriteLearningObjects.length > 0
              ) ?
                (
                  <HeroBox>
                    <LastSavedTypography>
                      { t("last_saved") }
                    </LastSavedTypography>

                    <Carousel
                      carouselType={ CAROUSEL_TYPE.HERO }
                      label="bookmark-hero-carousel"
                    >
                      {
                        lastFavourites?.lastFavouriteLearningObjects.map((card, index) => (
                          <BigCarouselCard
                            // onClickFavourite={ (value) => {
                            //   setTimeout(()=> {
                            //     setRefetchFavourite(`${value}-${card.id}-${card.isFavourite}`);
                            //   },200);

                            // } }
                            key={ `explore-hero--card-${index}` }
                            enrollType={ card.enrollType }
                            coverPublicURL={ card.coverPublicURL }
                            duration={ card.duration }
                            parentId={ card.parentId }
                            editionNumber={ card?.editionNumber }
                            endDate={ createEndDate(card) }
                            expirationDate={ card.expirationDate }
                            grandParentId={ card?.grandParentId }
                            id={ card.id }
                            isHighLightEdition={ card?.editions?.[0]?.isHighlightEdition ?? false }
                            isBookmarkPage={ true }
                            isStandAlone={ card.isStandAlone }
                            isToj={ card.isToj }
                            learningObjectType={ card.learningObjectType }
                            learningObjectTypology={ card.learningObjectTypology }
                            percentageOfCompletion={ card.percentageOfCompletion }
                            shortDescription={ card.shortDescription }
                            simple={ true }
                            startDate={ createStartDate(card) }
                            status={ card.status || "N" }
                            title={ card.title }
                            canAccessNow={ card.canAccessNow }
                          />
                        ))
                      }
                    </Carousel>
                  </HeroBox>
                ) :  !lastFavourites?.lastFavouriteLearningObjects?.length &&
                !loadingLastFavourites &&
                !favouritesAreLoading &&
                !topRatedIsLoading &&
                !loadingPageStructure &&  (
                  <HeroBox>
                    <HeroCard { ...HeroCardBookmarkVariants } />
                  </HeroBox>
                )
            )
        }
        {
          (
            hasFavourites || statusFilter !== null
          ) ?
            (
              <TabsFilters<StatusLO>
                contents={ [
                  {
                    components: [
                      /* eslint-disable react/jsx-key */
                      <Stack>
                        <CardsBox $hasFavourites={ hasFavourites }>
                          {
                            learnerFavourites ? (
                              learnerFavourites.map((card, index) => (
                                <CarouselCard
                                  key={ `carousel-explore-zoom-5-card-${index}` }
                                  booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                                  categories={ card.topicTags || [] }
                                  coverPublicURL={ card?.coverPublicURL }
                                  coverVideoPublicURL={ card.coverVideoPublicUrl }
                                  disabled={ false }
                                  duration={ card.duration }
                                  editionNumber={ card?.editionNumber }
                                  ecmRegistration={ card.ecmRegistration }
                                  ecm_specialization={ card.ecm_specialization }
                                  endDate={ createEndDate(card)  }
                                  enrollType={ card.enrollType }
                                  expirationDate={ card.expirationDate }
                                  grandParentId={ card.grandParentId }
                                  id={ card.id }
                                  isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                                  isFavourite={ card.isFavourite }
                                  isStandAlone={ card.isStandAlone }
                                  isToj={ card.isToj }
                                  learningObjectType={ card.learningObjectType }
                                  learningObjectTypology={ card.learningObjectTypology }
                                  parentId={ card.parentId }
                                  percentageOfCompletion={ card.percentageOfCompletion }
                                  shortDescription={ card.shortDescription }
                                  startDate={ createStartDate(card)  }
                                  status={ card.status || "N" }
                                  title={ card.title }
                                  canAccessNow={ card.canAccessNow }
                                />
                              ))

                            ) : (
                              // TEMPORARY
                              /* eslint-disable max-len */
                              <Typography>
                                Non abbiamo trovato risultati per i filtri selezionati. Ti consigliamo di rimuovere i filtri o selezionare un altro valore.
                                <Typography
                                  color="primary"
                                  onClick={ () => { setStatusFilter(null)} }
                                  sx={ { cursor: "pointer " } }
                                >
                                  { t("remove_filters") }
                                </Typography>
                              </Typography>
                              /* eslint-enable max-len */
                            )
                          }
                        </CardsBox>
                        {
                          (hasNextPage && !isFetchingNextPage) ? (
                            <Button
                              disabled={ !hasNextPage }
                              disableRipple
                              onClick={ () => fetchNextPage() }
                              sx={ {
                                gap:"0.5rem",
                                margin:"0 auto",
                                width:"fit-content"
                              } }
                            >
                              { t("load_more") }
                              <Icon
                                color={ theme.customColors.systemPrimary02 }
                                icon="dropdown"
                                size={ 20 }
                              />
                            </Button>
                          ) : null
                        }
                        {
                          isFetchingNextPage ? (
                            <Spinner />
                          ) : null
                        }
                      </Stack>
                    ],
                    iconTab: "grid"
                  },
                  {
                    components: [
                      /* eslint-disable react/jsx-key */
                      <Stack
                        divider={ <Divider/> }
                        gap={ isMobile ? "1.5rem" : "1rem" }
                        padding={ isMobile ? "30px 0 16px" : "64px 0 16px" }
                        maxWidth="calc(100vw - 2rem)"
                      >
                        {
                          learnerFavourites?.length ? (
                            learnerFavourites.map((card, index) => (
                              <Card
                                key={ `carousel-explore-zoom-4--card-${index}` }
                                booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                                categories={ card.topicTags || [] }
                                coverPublicURL={ card?.coverPublicURL }
                                duration={ card.duration }
                                editionNumber={ card?.editionNumber }
                                endDate={ createEndDate(card)  }
                                ecmRegistration={ card.ecmRegistration }
                                ecm_specialization={ card.ecm_specialization }
                                enrollType={ card.enrollType }
                                expirationDate={ card.expirationDate }
                                grandParentId={ card.grandParentId }
                                parentId={ card.parentId }
                                id={ card.id }
                                isFavourite={ card.isFavourite }
                                learningObjectType={ card.learningObjectType }
                                learningObjectTypology={ card.learningObjectTypology }
                                percentageOfCompletion={ card.percentageOfCompletion }
                                shortDescription={ card.shortDescription }
                                startDate={ createStartDate(card) }
                                status={ card.status || "N" }
                                title={ card.title }
                                canAccessNow={ card.canAccessNow }
                              />
                            ))

                          ) : (
                            // TEMPORARY
                            /* eslint-disable max-len */
                            <Typography>
                              Non abbiamo trovato risultati per i filtri selezionati. Ti consigliamo di rimuovere i filtri o selezionare un altro valore. <Typography
                                color="primary"
                                onClick={ () => { setStatusFilter(null)} }
                                sx={ { cursor: "pointer" } }
                              >
                                Rimuovi filtri
                              </Typography>
                            </Typography>
                            /* eslint-enable max-len */
                          )
                        }
                        {
                          (hasNextPage && !isFetchingNextPage) ? (
                            <Button
                              disabled={ !hasNextPage }
                              disableRipple
                              onClick={ () => fetchNextPage() }
                              sx={ {
                                gap:"0.5rem",
                                margin:"0 auto",
                                width:"fit-content"
                              } }
                            >
                              { t("load_more") }
                              <Icon
                                color={ theme.customColors.systemPrimary02 }
                                icon="dropdown"
                                size={ 20 }
                              />
                            </Button>
                          ) : null
                        }
                        {
                          isFetchingNextPage ? (
                            <Spinner />
                          ) : null
                        }
                      </Stack>
                    ],
                    iconTab: "list"
                  }
                ]
                }
                count={ favourites?.pages?.[0]?.metadata.totalElements.toString() }
                // count={ favourites?.pages?.reduce((acc, page) => (
                //   acc + page?.favouriteLearningObjects.length ?? 0
                // ), 0).toString() }
                filter= { statusFilter }
                options={ [
                  {
                    children: t("completed"),
                    value: STATUS_LO.C
                  },
                  {
                    children: t("in_progress"),
                    value: STATUS_LO.P
                  },
                  {
                    children: t("to_start"),
                    value: STATUS_LO.E
                  }
                ] }
                setFilter={ setStatusFilter }
                title={ t("favourite_text") }
              />
            ) : (
              (
                !lastFavourites?.lastFavouriteLearningObjects?.length &&
                !loadingLastFavourites &&
                !favouritesAreLoading &&
                !topRatedIsLoading &&
                !loadingPageStructure &&
                topRated?.topRatedLearningObjects &&
                topRated?.topRatedLearningObjects?.length > 0
              ) ?
                (
                  <CarouselBox>
                    <Carousel
                      carouselType={ CAROUSEL_TYPE.ZOOM }
                      id="bookmarks-empty-zoom-1"
                      label={ ratedObject?.title ?? "" }
                      title={ t("most_rated") }
                      itemsLength={ topRated?.metadata?.totalElements || 0 }
                    >
                      {
                        topRated?.topRatedLearningObjects?.map((card, index) => (
                          <CarouselCard
                            key={ `carousel-explore-bookmarks--card-${index}` }
                            booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                            categories={ card.topicTags || [] }
                            coverPublicURL={ card?.coverPublicURL }
                            coverVideoPublicURL={ card.coverVideoPublicUrl }
                            ecmRegistration={ card.ecmRegistration }
                            ecm_specialization={ card.ecm_specialization }
                            disabled={ false }
                            duration={ card.duration }
                            editionNumber={ card?.editionNumber }
                            endDate={ createEndDate(card) }
                            enrollType={ card.enrollType }
                            id={ card.id }
                            isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                            isFavourite={ card.isFavourite }
                            isToj={ card.isToj }
                            grandParentId={ card.grandParentId }
                            learningObjectType={ card.learningObjectType }
                            learningObjectTypology={ card.learningObjectTypology }
                            parentId={ card.parentId }
                            percentageOfCompletion={ card.percentageOfCompletion }
                            shortDescription={ card.shortDescription }
                            startDate={ createStartDate(card)  }
                            status={ card.status || "N" }
                            title={ card.title }
                            canAccessNow={ card.canAccessNow }
                          />
                        ))
                      }
                    </Carousel>
                  </CarouselBox>
                ) :
                null
            )
        }
      </Stack>
    </GridMain>
  );
}
