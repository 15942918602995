import BrightcoveReactPlayerLoader from "@brightcove/react-player-loader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useRef,
  useState,
  useEffect,
  useCallback
} from "react";
// import { useLocation } from "react-router-dom";

import { PlayerLoaderBox } from "./PlayerLoader.styles";
import type {
  BrightcovePlayer,
  PlaybackStatus,
  PlayerLoaderProps
} from "./PlayerLoader.types";
import {
  videoSave,
  // putActivity,
  videoStatement, videoTracker
} from "./queries";
import { useAuthStore } from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";

/**
 * use a wrapper to check if a player instance is already initialized
 * @param options add from official @brightcove/player-loader docs as required
 * @returns
 */
export function PlayerLoader({
  autoplay=false,
  canPlayBack,
  canProceed,
  className,
  courseId,
  coverFallBack,
  id,
  onEndAction,
  parentId,
  shortDescription,
  skipTracking=false,
  // startFrom=0,
  title,
  triggerPause=false,
  tentativeId,
  videoId,
  ...props
}: PlayerLoaderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const location = useLocation();
  const userData = useAuthStore(state => state.userData);
  const session = useAuthStore(state => state.session);
  const sessionToken = useAuthStore(state => state.sessionToken);
  const [playbackStatus, setPlaybackStatus] = useState<PlaybackStatus>("stop");
  const [renderError, setRenderError] = useState(false);
  const hasStarted = useRef<boolean>(false);
  const playerRef = useRef<BrightcovePlayer>();
  const { brightcoveAccountId, brightcovePlayerId } = getEnvironmentVariables();
  const [isPaused, setIsPaused] = useState(false);
  // const [isTabVisible, setIsTabVisible] = useState(true);


  const saveStatementAndVideo = useCallback( () => {
    if (!userData) return;
    if (playerRef.current) {
      videoStatement({
        accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
        corporateId:userData.organization_id ?? "",
        courseId: courseId,
        description: shortDescription,
        duration: playerRef?.current?.cache_?.currentTime,
        id: id,
        idUser: userData.id_user,
        organizationId: userData.organization_id,
        parentId: parentId,
        sessionToken:sessionToken ?? "",
        tentativeId: tentativeId,
        title: title,
        type: "terminated",
        userEmail: userData.email,
        userName: userData.external_id,
        initiativeId: userData.initiative_id
      });
      videoSave({
        accessToken: session?.getAccessToken()?.getJwtToken() ?? "",
        corporateId: userData.organization_id ?? "",
        courseId: courseId,
        duration: playerRef?.current?.cache_?.currentTime,
        id: id,
        idUser: userData.id_user,
        organizationId: userData.organization_id,
        parentId: parentId,
        sessionToken: sessionToken ?? "",
        tentativeId: tentativeId,
        userEmail: userData.email,
        userName: userData.external_id
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      if(playerRef?.current?.cache_?.currentTime !== 0){
        saveStatementAndVideo();
      }
    };
  }, []);

  // useLayoutEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       console.log("hidden");
  //       setIsTabVisible(false);
  //     } else {
  //       console.log("visible");
  //       setIsTabVisible(true);
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  useEffect(()=> {
    if (
      playerRef.current
      && playerRef?.current?.currentTime() > 0.01
      && userData && triggerPause
    ) {
      playerRef.current.pause();
      videoSave({
        accessToken: session?.getAccessToken()?.getJwtToken() ?? "",
        corporateId: userData.organization_id ?? "",
        courseId: courseId,
        duration: playerRef.current.currentTime(),
        id: id,
        idUser: userData.id_user,
        organizationId: userData.organization_id,
        parentId: parentId,
        sessionToken: sessionToken ?? "",
        tentativeId: tentativeId,
        userEmail: userData.email,
        userName: userData.external_id
      });
    }
  }, [
    courseId,
    id,
    tentativeId,
    parentId,
    playerRef,
    session,
    sessionToken,
    triggerPause,
    userData
  ]);

  return (
    <PlayerLoaderBox
      className={ `${className} ${getPlaybackClass(playbackStatus)} ${canPlayBack ? "canPlayBack" : ""}` }
      $image={ renderError ? coverFallBack : undefined }
      { ...props }
    >
      {
        !renderError ? (
          <>
            <BrightcoveReactPlayerLoader
              accountId={ brightcoveAccountId }
              poster={ coverFallBack }
              playerId={ brightcovePlayerId }
              videoId={ videoId }
              onFailure={ onFailure }
              onSuccess={ onSuccess }
              options={ {
                autoplay:false
              } }

            />
            { props.children }
          </>
        ) : (
          <>
            { props.children }
          </>
        )
      }
    </PlayerLoaderBox>
  );

  function onFailure(error: Error) {
    // eslint-disable-next-line no-console
    console.error(error, "error");
    setRenderError(true);
  }

  function onSuccess(
    success: { ref: { player_: BrightcovePlayer}}
  ) {
    const {
      player_
      // controlBar
    } = success.ref;

    player_?.on("error", ()=> {
      setRenderError(true);
    });

    if(canPlayBack){
      player_.controlBar.progressControl.disable();
    }

    player_?.on("loadedmetadata", () => {
      if (userData){
        videoTracker({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        })
          ?.then(res => {
            if (res && player_ && res.attemptDuration > 0.01 && autoplay){
              player_?.currentTime(res.attemptDuration);
              if(playbackStatus !== "pause"){
                player_?.play();
              }
            } else {
              playerRef.current?.currentTime(0);
              if(autoplay){
                if(playbackStatus !== "pause"){
                  playerRef.current?.play();
                }
              }

            }
          });
      }
    });

    const setDataPlaying = () => {
      if(userData && !isPaused && !!player_.currentTime()){
        videoSave({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        });
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let saveDataInterval: any;

    player_?.on("playing", ()=> {
      window.addEventListener("blur", ()=> {
        if(player_){
          player_?.pause();
          player_?.muted(true);
        }
      });

      saveDataInterval = setInterval(setDataPlaying, 3000);
    });

    player_?.on("play", () => {
      if (isMobile){
        player_.requestFullscreen();
        // screen.orientation.lock("landscape");
      }
      player_?.muted(false);
      setPlaybackStatus("play");

      if (skipTracking) return;
      if (!userData) return;
      if (hasStarted.current) return;
      videoStatement({
        accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
        corporateId:userData.organization_id ?? "",
        courseId: courseId,
        description: shortDescription,
        duration: player_.currentTime(),
        id: id,
        idUser: userData.id_user,
        organizationId: userData.organization_id,
        parentId: parentId,
        sessionToken:sessionToken ?? "",
        tentativeId: tentativeId,
        title: title,
        type: "initialized",
        userEmail: userData.email,
        userName: userData.external_id,
        initiativeId: userData.initiative_id
      });

      // if (!hasStarted.current && userData) {
      //   console.log("arriva dove deve");


      //   videoStatement({
      //     accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
      //     corporateId:userData.organization_id ?? "",
      //     courseId: courseId,
      //     description: shortDescription,
      //     duration: player_.currentTime(),
      //     id: id,
      //     idUser: userData.id_user,
      //     organizationId: userData.organization_id,
      //     parentId: parentId,
      //     sessionToken:sessionToken ?? "",
      //     tentativeId: tentativeId,
      //     title: title,
      //     type: "initialized",
      //     userEmail: userData.email,
      //     userName: userData.external_id,
      //     initiativeId: userData.initiative_id
      //   });
      //   hasStarted.current = true;

      //   // putActivity({
      //   //   body: { val: player_.currentTime().toString() }
      //   // });
      // }
    });

    player_?.on("pause", () => {
      player_?.muted(true);
      setPlaybackStatus("pause");
      setIsPaused(true);
      clearInterval(saveDataInterval);

      if(player_.currentTime() > 0.01 && userData){
        videoStatement({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          description: shortDescription,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          title: title,
          type: "terminated",
          userEmail: userData.email,
          userName: userData.external_id,
          initiativeId: userData.initiative_id
        });
        videoSave({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        });
      }

    });

    player_?.on("ended", () => {
      if (onEndAction) { onEndAction() }
      if (isMobile) {
        player_.exitFullscreen();
        // screen.orientation.lock("natural");
      }
      setPlaybackStatus("stop");
      if (skipTracking) return;
      if (player_.currentTime() > 0.01 && userData) {
        videoStatement({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          description: shortDescription,
          duration: player_.currentTime(),
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          title: title,
          type: "completed",
          userEmail: userData.email,
          userName: userData.external_id,
          initiativeId: userData.initiative_id
        });
        videoSave({
          accessToken:session?.getAccessToken()?.getJwtToken() ?? "",
          corporateId:userData.organization_id ?? "",
          courseId: courseId,
          duration: 0,
          id: id,
          idUser: userData.id_user,
          organizationId: userData.organization_id,
          parentId: parentId,
          sessionToken:sessionToken ?? "",
          tentativeId: tentativeId,
          userEmail: userData.email,
          userName: userData.external_id
        });
        canProceed && canProceed(true);
      }
    });
    playerRef.current = player_;
  }

  function getPlaybackClass(status: PlaybackStatus) {
    switch(status) {
    case "pause":
    case "stop":
      return " brightcove-is-paused";
    case "play":
      return " brightcove-is-playing";
    default:
      return "";
    }
  }
}
