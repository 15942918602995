import {
  Grid,
  Stack,
  StackProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Icon } from "../../components";
import { StyledPreferenceStack } from "../../components/PreferenceStack/PreferenceStack.styles";
import { StyledTabContent } from "../../components/Tabs/Tabs.styles";

export const ArrowIcon = styled(Icon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  position: "absolute",

  path: {
    fill: theme.palette.primary?.main
  }
}));

export const ProfileStack = styled(Stack)<
  StackProps & { src?: string }
  >(({ src, theme }) => {

    return {
      // eslint-disable-next-line max-len
      background: src ? `${theme.linearGradients.gradientDAlternative09}, url(${src})` : "transparent",
      backgroundRepeat:"no-repeat",
      backgroundSize:"100% 800px",
      flexGrow: 1,
      width:"100%",

      [`${theme.breakpoints.down("sm")}`]:{
        backgroundSize: "cover",
        paddingTop: "0"
      },

      [`${StyledTabContent}`]:{
        padding:"33px 0 0",

        [theme.breakpoints.down("sm")]:{
          padding:"24px 0 0"
        }
      },

      [`${StyledPreferenceStack}`]:{
        background:"transparent",
        borderRadius:0,
        padding:0,

        [theme.breakpoints.down("sm")]:{
          ">p":{
            fontSize:theme.spacing(2),

            "&:nth-child(2)":{
              display:"none"
            }
          }
        }
      },

      ".widget-item": {
        maxWidth: "50%",

        [theme.breakpoints.down("md")]:{
          maxWidth: "100%",
          minWidth: "100%"
        }
      },

      ".card-ranking:empty": {
        display: "none"
      },

      ".widget-item:only-child": {
        maxWidth: "100%",
        minWidth: "100%"
      }
    };
  });

export const WidgetGrid = styled(Grid)(({ theme }) => {
  return {
    display:"flex",
    flexWrap:"wrap",
    gap:theme.spacing(2),
    justifyContent:"space-between",
    margin:theme.spacing(5,0),
    width:"100%",

    [`${theme.breakpoints.down("sm")}`]:{
      gap: "0",
      margin:`${theme.spacing(3)} auto 0`,
      width:"100%"
    }
  };
});

export const PreferencesContainer = styled(Stack)<
  StackProps>(({ theme }) => {
    return {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      maxWidth: "650px",
      minWidth: "650px",

      [`${theme.breakpoints.down("sm")}`]:{
        maxWidth: "100%",
        minWidth: "100%",
        width:"100%"
      },

      ".favourites-carousel": {
        ".swiper-wrapper": {
          alignItems:"center",
          display: "flex",
          minHeight: "116px"
        },

        ".swiper-slide": {
          marginRight: "8px !important",
          maxHeight: "77px",

          ">div": {
            border:  `${theme.spacing(0.25)} solid ${ theme.customColors.borderSecondaryComplete }`,
            borderRadius: "8px",
            boxShadow: `0px 0px 4px 0px ${ theme.customColors.borderSecondaryComplete }`,
            maxHeight: "calc(112px - 32px)",
            maxWidth: "103px",
            minWidth: "103px",
            outline: "none",

            [theme.breakpoints.down("sm")]: {
              maxHeight: "calc(77px - 32px)"
            }
          }
        },

        ".swiper-pagination": {
          marginTop: "0px"
        }
      }
    };
  });

