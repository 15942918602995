import {
  Autocomplete,
  FormControl,
  Checkbox,
  ListItemText,
  TextField, MenuItem, Chip, Popper
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { MultiSelectProps } from "./types";

const MultiSelectCst: React.FC<MultiSelectProps> = ({ options, selectedOptions, handleChange, label, disabled, isFilterActive, optionsToDisable }) => {
  const theme = useTheme();

  return (
    <FormControl
      fullWidth
      sx={ {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: theme.customColors.border
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.customColors.borderPrimaryCta
          }
        },
        "& .MuiFormLabel-root": {
          color: theme.customColors.textPrimary
        },
        "& .MuiSvgIcon-root": {
          color: theme.customColors.textPrimary
        },
        "& .ddihpQ.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.customColors.border
        },
        "& .kjpuir.Mui-disabled": {
          color: theme.customColors.textDisabled
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
          color: theme.customColors.borderPrimaryCta
        }
      } }
    >
      <Autocomplete
        multiple
        options={ options }
        value={ selectedOptions }
        getOptionDisabled={ (option) => {
          return optionsToDisable?.includes(`${option.value}`) || false;
        } }
        sx={ {
          width: "100%",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: isFilterActive ? theme.customColors.borderTag : ""
          }
        } }
        limitTags={ 1 }
        disabled={ disabled }
        onChange={ (event, newValue) => handleChange(newValue) }
        getOptionLabel={ (option) => option.label }
        isOptionEqualToValue={ (option, value) => option.value === value.value }
        renderInput={ (params) => (
          <TextField
            { ...params }
            variant="outlined"
            label={ label }
          />
        ) }
        renderTags={ (value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index }); // Destructure to exclude key
            return(
              <Chip
                key={ `$${ key }` }
                variant="outlined"
                label={ option.label }
                { ...tagProps } // Spread remaining props without the key
              />
            );
          })
        }
        renderOption={ (props, option, { selected }) => (
          <MenuItem { ...props }>
            <Checkbox checked={ selected } />
            <ListItemText primary={ option.label } />
          </MenuItem>
        ) }
        PopperComponent={ (props) => (
          <Popper { ...props } style={ { zIndex: 1500, width: "auto" } } sx={ { "& .MuiPaper-root": {
            backgroundColor: theme.customColors.backgroundPrimary
          } } } placement="bottom-start" />
        ) }
      />
    </FormControl>
  );
};

export default MultiSelectCst;
