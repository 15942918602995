import {
  Stack,
  Typography,
  useTheme
} from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { CardsBox } from "./SmartLearning.styles";
import {
  Button,
  CarouselCard,
  GridMain,
  GuardedLink,
  Icon,
  Spinner
} from "../../components";
import {
  PAGE_NAME,
  STATUS_LO
} from "../../consts";
import {
  useInfiniteContentQuery,
  useStructuresQuery
} from "../../queries";
import { useAuthStore } from "../../stores";
import type {
  LearningObject,
  MetaData
} from "../../types";
import { createEndDate, createStartDate } from "../../utils/general";

export function SmartLearning() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [queryParams] = useSearchParams();
  const endTime = queryParams.get("endTime");
  const startTime = queryParams.get("startTime");

  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  //pageStructure
  const { data: pageStructureAgenda } = useStructuresQuery({
    pageName: PAGE_NAME.AGENDA_OVERVIEW
  });
  const smartLearningPath =
    pageStructureAgenda?.relativePaths?.find((path) =>
      path.serviceType === "smartLearning")?.apiPath;

  //learnersSmartLearningObjects
  const {
    data: smartLearning,
    fetchNextPage: smartLearningFetchNextPage,
    hasNextPage: smartLearningHasNextPage,
    isFetchingNextPage: smartLearningIsFetchingNextPage,
    isInitialLoading: smartLearningIsInitialLoading
  } = useInfiniteContentQuery<{
    smartLearningObjects: LearningObject[]
    metadata: MetaData
  }>({
    enabled: Boolean(
      endTime
      && smartLearningPath
      && startTime
    ),
    pageSize: 12,
    path: smartLearningPath ?? "",

    endTime,
    startTime
  });

  const date = startTime ? format(new Date(startTime), "dd/MM/yyyy") : null;
  const end = endTime ? format(new Date(endTime), "kk:mm") : null;
  const start = startTime ? format(new Date(startTime), "kk:mm") : null;

  return (
    <GridMain>
      <Stack
        gap={ 2 }
        maxWidth="calc(100vw - (100vw - 100%))"
        minWidth={ "100%" }
      >
        <GuardedLink
          alignSelf="flex-start"
          color="info"
          href={ "/agenda" }
          startIcon={
            <Icon
              icon="arrow_left"
              size={ 20 }
            />
          }
          variant="text"
          sx={ {
            fontSize: ("1.125rem"),
            fontWeight: 400,
            justifyContent:"flex-start",
            lineHeight: (11 / 9),
            paddingTop:theme.spacing(4),
            paddingX:0

          } }
        >
          { t("back_to_agenda") }
        </GuardedLink>
        <Typography
          fontSize="2rem"
          fontWeight={ 700 }
          lineHeight={ 19 / 16 }
          sx={ { paddingTop: theme.spacing(1) } }
        >
          Smart Learning
        </Typography>
        {
          (date && end && start) ? (
            <Stack
              alignItems="center"
              direction="row" gap={ 1 }
            >
              <Icon icon="Icons_time_mobile" size={ 20 }/>
              <Typography>{ start } - { end }</Typography>
              <Icon icon="Icons_scadenza-e-edizioni" size={ 20 }/>
              <Typography>{ date }</Typography>
            </Stack>
          ) : null
        }
        {
          smartLearning?.pages[0].metadata.totalElements ? (
            <Typography sx={ { paddingTop: theme.spacing(1) } }>
              {
                smartLearning?.pages[0].metadata.totalElements
              } contenuti disponibili
            </Typography>
          ) : null
        }
        {
          smartLearningIsInitialLoading ? (
            <Spinner />
          ) :
            (
              smartLearning?.pages?.[0]?.smartLearningObjects &&
              smartLearning.pages[0].smartLearningObjects.length > 0
            )
              ? (
                <>
                  <CardsBox>
                    {
                      smartLearning?.pages?.map((page) => (
                        page?.smartLearningObjects?.map((card, index) => (
                          <CarouselCard
                            key={ `carousel-explore-zoom-2--card-${index}` }
                            booked={ card.status !== STATUS_LO.N && card.status !== STATUS_LO.T }
                            categories={ card.topicTags || [] }
                            coverPublicURL={ card?.coverPublicURL }
                            disabled={ false }
                            duration={ card.duration }
                            ecm_specialization={ card.ecm_specialization }
                            ecmRegistration={ card.ecmRegistration }
                            enrollType={ card.enrollType }
                            endDate={ createEndDate(card) }
                            expirationDate={ card.expirationDate }
                            id={ card?.id }
                            isAutoSubscribeEnable={ card.isAutoSubscribeEnable }
                            isFavourite={ card.isFavourite }
                            isMandatory ={ card.isMandatory }
                            isToj={ card.isToj }
                            learningObjectType={ card?.learningObjectType || "" }
                            learningObjectTypology={ card.learningObjectTypology }
                            percentageOfCompletion={ card.percentageOfCompletion }
                            shortDescription={ card.shortDescription }
                            startDate={ createStartDate(card) }
                            status={ card.status }
                            title={ card.title }
                            canAccessNow={ card.canAccessNow }
                          />
                        ))
                      ))
                    }
                  </CardsBox>
                  {
                    (smartLearningHasNextPage && !smartLearningIsFetchingNextPage) ? (
                      <Button
                        disabled={ !smartLearningHasNextPage }
                        disableRipple
                        onClick={ () => smartLearningFetchNextPage() }
                        sx={ {
                          gap:"0.5rem",
                          margin:"0 auto",
                          width:"fit-content"
                        } }
                      >
                        { t("load_more") }
                        <Icon
                          color={ theme.customColors.systemPrimary02 }
                          icon="dropdown"
                          size={ 20 }
                        />
                      </Button>
                    ) : null
                  }
                  {
                    smartLearningIsFetchingNextPage ? (
                      <Spinner />
                    ) : null
                  }
                </>
              ) : null
        }
      </Stack>
    </GridMain>
  );
}
