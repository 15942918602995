/* eslint-disable */
import { SwipeableDrawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import {
  ContentContainer,
  CloseDrawerButton,
  OpenDrawerButton,
  WidgetContainer
} from "./WidgetDrawer.styles";
import {
  Calendar,
  CardGoals,
  CardRanking,
  CardUserGoals,
  CardUserProgress,
  KeepLearningCard
  // NewsCard
} from "../../components";
import { useAuthStore } from "../../stores";
import { useOutletContext } from "react-router-dom";

export function WidgetDrawer() {
  const { showTutorialWidget }: any = useOutletContext();
  const [isOpened, setIsOpened] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smartConfiguration = useAuthStore(state => state.smartConfiguration);

  const widgetsAreEnabled =
    smartConfiguration?.leaderboard === true
    || smartConfiguration?.widgetBadge === true
    || smartConfiguration?.widgetContinueLearning === true
    || smartConfiguration?.widgetCalendar === true;

  React.useEffect(() => {
    setIsOpened(showTutorialWidget)
  }, [showTutorialWidget])

  return widgetsAreEnabled ? (
    <>
      <OpenDrawerButton
        className={ !isOpened ? "visible" : "" }
        accent={ "red" }
        icon={ "arrow_widget_open" }
        onClick={ () => { setIsOpened(true) } }
      />
      <SwipeableDrawer
        open={ isOpened }
        anchor={ "right" }
        onClose={ () => { setIsOpened(false) } }
        onOpen={ () => { setIsOpened(true) } }
        PaperProps= { {
          sx: {
            background: "transparent",
            boxShadow:"none",
            height: "calc(100% - 6rem)",
            overflowY:"visible",
            top:  isMobile ? "8.8rem" : " 5rem",
            [theme.breakpoints.down("sm")]:{
              maxWidth: "calc(100% - 40px)",
              right: "20px"
            },

            ".MuiPaper-root":{
              overflowX: "hidden",
              overflowY: "auto",

              "&:hover": {
                "&::-webkit-scrollbar-thumb":{
                  backgroundColor: theme.customColors.backgroundSecondary
                }
              },

              "::-webkit-scrollbar": {
                width: "6px"

              },

              "::-webkit-scrollbar-thumb":{
                backgroundColor: "transparent",
                borderRadius: "8px"
              }
            }

          }
        } }
        slotProps={ { backdrop: { classes: { root: "backdrop-widget" } } } }
        sx= { { position: "relative",  zIndex:"1400" } }
      >
        <ContentContainer>
          {
            // force the widgets to re-render so they
            // trigger "fetching on mount" for the queries
            isOpened ? (
              <WidgetContainer>
          <CloseDrawerButton
            className={ isOpened ? "visible" : "" }
            icon={ "arrow_widget_close" }
            onClick={ () => { setIsOpened(false) } }
          />
                <Calendar />
                <CardRanking isUserPage={ false }/>
                <KeepLearningCard />
                <CardGoals />
                { /* <NewsCard { ...NewsCardVariants.Default } /> */ }
                <CardUserProgress isProfilePage={ false }/>
                <CardUserGoals color={theme.customColors.textWidget} isProfilePage={ false }/>
              </WidgetContainer>
            ) : null
          }
        </ContentContainer>
      </SwipeableDrawer>
    </>
  ) : null;
}
