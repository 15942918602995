import {
  InlineModal,
  StyledIframe
} from "./ScormWrapper.styles";
import { useLanguageStore } from "../../stores";
import type { Level } from "../../types";

export function ScormWrapper({
  activityId,
  // close,
  courseId,
  // fullScreen=false,
  isOpen,
  learningObjectTypology,
  parentId
} : {
  activityId: number
  // close: () => void
  courseId: string | null
  // fullScreen?: boolean
  isOpen: boolean
  learningObjectTypology: Level
  parentId: string | null
}) {
  const uri = `${window.location.origin}/esplora/scorm/${activityId}/${learningObjectTypology}`;
  const language = useLanguageStore(state => state.language)?.id;
  const params = `courseId=${courseId ?? null}&rootId=${parentId ?? null}&lang=${language}`;

  return (
    <InlineModal $open={ isOpen } >
      <StyledIframe
        src={ `${uri}?${params}` }
        title={ `iframe-scorm-${learningObjectTypology}` }
      />
    </InlineModal>
  );
}
