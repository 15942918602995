import { TabsContentProps } from "./TabsContent.types";
import { TabsContentStakeholders } from "./TabsContentStakeholders/TabsContentStakeholders.component";
import {
  TabsContentActivities,
  TabsContentCommunity,
  TabsContentDetail,
  TabsContentDetailWatch,
  TabsContentEditions,
  TabsContentInstruments,
  TabsContentRelated,
  TabsContentExercises
} from "../../components";
import { TAB_ITEMS } from "../../consts";

export function TabsContent({
  contentActivities,
  contentEditions,
  contentLinks,
  currentLO,
  contentRelated,
  contentTools,
  exercises,
  isDetailPage,
  sharedPosts,
  setOpenModalEcm,
  tabType,
  titleEditions,
  currentTeachers,
  currentTutors,
  ...props
}: TabsContentProps) {

  switch (tabType) {
  case TAB_ITEMS.ACTIVITIES:
    return (
      contentActivities && currentLO ? (
        <TabsContentActivities
          contentActivities={ contentActivities }
          currentLO={ currentLO }
          setOpenModalEcm={ setOpenModalEcm }
          title = { currentLO?.title ?? "" }
          { ...props }
        />
      ) : null
    );

  case TAB_ITEMS.COMMUNITY:
    return (
      sharedPosts  ? (
        <TabsContentCommunity
          loId={ currentLO?.id.toString() ?? "" }
          sharedPosts={ sharedPosts }
        />
      ) : null
    );

  case TAB_ITEMS.DETAIL:
    return (
      <>
        {
          (isDetailPage==true && currentLO) ? (
            <TabsContentDetail
              currentLO={ currentLO }
              currentTeachers={ currentTeachers }
              currentTutors={ currentTutors }
              isDetailsPage={ isDetailPage }
              { ...props }
            />
          ) : null
        }
        {
          (isDetailPage === false && currentLO) ? (
            <TabsContentDetailWatch
              currentLO={ currentLO }
              isDetailsPage={ isDetailPage }
              { ...props }
            />
          ) : null
        }
      </>
    );

  case TAB_ITEMS.EDITIONS:
    return (
      contentEditions && titleEditions ? (
        <TabsContentEditions
          currentLO={ currentLO }
          currentTeachers={ currentTeachers }
          contentEditions={ contentEditions }
          titleEditions={ titleEditions }
          rootId={ props.rootId }
          courseId={ props.courseId }
          { ...props }
        />
      ) : null
    );

  case TAB_ITEMS.INSTRUMENTS:
    if (contentLinks && contentTools === undefined) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else if (contentLinks === null && contentTools) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else if (contentLinks && contentTools) {
      return (
        <TabsContentInstruments
          contentLinks={ contentLinks }
          contentTools={ contentTools }
          currentLO={ currentLO }
          { ...props }
        />
      );
    } else { return null }

  case TAB_ITEMS.RELATED:
    return (
      contentRelated ? (
        <TabsContentRelated
          contentRelated={ contentRelated }
          currentLO={ currentLO }
          { ...props }
        />
      ) : null
    );

  case TAB_ITEMS.EXERCISES:
    if(exercises && currentTeachers
    ){
      return (
        <TabsContentExercises
          teachers={ currentTeachers }
          editionId={ contentEditions?.find(edition => edition.status === "E")?.id ?? 0 }
          exercises={ exercises }
        />
      );
    } else {
      return null;
    }

  case TAB_ITEMS.STAKEHOLDERS:
    if(currentLO?.stakeholders){
      return (
        <TabsContentStakeholders
          stakeholders={ currentLO.stakeholders }
        />
      );
    } else {
      return null;
    }
  default:
    return (
      <></>
    );
  }
}
