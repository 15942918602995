import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import logoFallback from "../../assets/digited_logo.png";
import logoMobileFallback from "../../assets/logoMobile.png";
import { GuardedLink, Icon, Logo } from "../../components";
import { useAuthStore } from "../../stores";
import { getEnvironmentVariables } from "../../utils/general";


export const Maintenance = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const inititiveUrl =  smartConfiguration?.urlDomain
    ? `${smartConfiguration?.urlDomain}.${smartConfiguration.urlCorporateDomain}`
    : smartConfiguration?.urlCorporateDomain
      ?  `${smartConfiguration?.urlCorporateDomain}`
      : window.location.host;

  console.log("inititiveUrl", inititiveUrl);


  const { digitedLogo, digidetLogoMobile, digitedLogoHeight } = getEnvironmentVariables();

  let isGlobalMaintenance = false;
  let corporate = false;
  if(sessionStorage.getItem("maintenanceData")) {
    const data = JSON.parse(sessionStorage.getItem("maintenanceData")!);
    isGlobalMaintenance = data.isGlobalMaintenance;
    corporate = data.corporate;
  }

  return <div style={ { height: "100vh", display: "flex", flexDirection: "column" } }>
    <header style={ { padding: "20px" } }>
      <Logo
        alt="logo"
        width="auto"
        // eslint-disable-next-line max-len
        height={ digitedLogoHeight ? digitedLogoHeight + "px" : "32px" }
        src={ isMobile ? digidetLogoMobile ?? logoMobileFallback : digitedLogo ?? logoFallback }
      />
    </header>
    <section style={ {
      alignItems: "center",
      display: "flex",
      flex: "1 0 auto",
      flexDirection: "column",
      justifyContent: "center"
    } }>
      <GuardedLink
        alignSelf="flex-start"
        color="info"
        onClick={ () => corporate ? navigate("/logout") : navigate("/access/portal", { state: { maintenancePage: true } }) }
        href={ "/" }
        variant="text"
        startIcon={
          <Icon
            color={ theme.customColors.systemPrimary01 }
            icon="arrow_left"
            size={ 20 }
          />
        }
        sx={ {
          fontSize: (isMobile ? "0.75rem" : "1.125rem"),
          fontWeight: 400,
          lineHeight: isMobile ? (11 / 6) : (11 / 9),
          minWidth: isMobile ? "auto" : "128px",
          padding: isMobile ? "16px 0 0 0" : undefined
        } }
      >
        { t("back") }
      </GuardedLink>
      { !isGlobalMaintenance && <div style={ { width: "100%" } }>
        <iframe src={ `https://${inititiveUrl}/maintenance-landing-page/Maintenance.html` } title="Maintenance page" style={ {
          border: 0,
          width: "100%",
          height: "calc(100vh - 110px)"
        } }></iframe>
      </div>
      }
      { isGlobalMaintenance &&
        <div style={ { flex: "1 0 auto", alignContent: "center", width: "100%" } }>
          <Typography
            fontSize={ "2rem" }
            fontWeight={ 700 }
            color={ theme.customColors.textPrimary }
            textAlign={ "center" }
            sx={ { marginBottom: "20px" } }
          >
            { t("maintenance-title") }
          </Typography>
          <Typography
            fontSize={ "1rem" }
            fontWeight={ 700 }
            color={ theme.customColors.textPrimary }
            textAlign={ "center" }
          >
            { t("maintenance-description") }
          </Typography>
        </div>
      }
    </section>
  </div>;
};
