import {
  alpha,
  Divider,
  Stack,
  StackProps,
  Theme,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";
import { Icon } from "../Icon";

export const OverlayDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.customColors.borderTag,
  margin: "0 11.5px"
}));

export const OverlayStack = styled(Stack)<
  Omit<
    StackProps,
    "component"
  > & {
    component: ElementType
  }
>(({ theme }) => ({
  bottom: 0,
  cursor:"pointer",
  position: "absolute",
  right: 0,
  // top: "92.24%",
  // transform: "translateY(-50%)",
  width: "3rem",
  zIndex:theme.zIndex.appBar,

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "static",
    transform: "none"
  }
}));

export const OverlayGroupStack = styled(Stack)(({ theme }) => ({
  paddingBlock: theme.spacing(1.5),
  position: "relative",

  ">div":{
    //paddingInline: theme.spacing(2)
  },

  "&::before": {
    backgroundColor: theme.customColors.systemSecondary05 && alpha(theme.customColors.systemSecondary05, 0.8),
    borderRadius: "0.5rem",
    boxShadow: `0.25rem 0.25rem 0.75rem ${theme.palette.common?.black && alpha(theme.palette.common?.black, 0.25)}`,
    content: "''",
    height: "100%",
    left: 0,
    opacity: 0.6,
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: -1
  },

  [theme.breakpoints.down("sm")]: {
    boxShadow: `0.25rem 0.25rem 0.75rem ${theme.palette.common?.black && alpha(theme.palette.common?.black, 0.25)}`,
    padding: theme.spacing(1),
    paddingBlock: theme.spacing(1),

    "&::before": {
      content: "none"
    },

    "& a": { margin: "auto" },
    "& a div": { flexDirection: "row" },
    backgroundColor: theme.customColors.systemSecondary05 && alpha(theme.customColors.systemSecondary05, 0.6),
    borderRadius: "0.5rem",
    flexDirection: "row",
    position: "static",
    transform: "none"
  }
}));

export const OverlayTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.systemPrimary02,
  fontSize: "0.75rem"
}));

export const CtaOverlayIcon = styled(Icon)(({ icon, theme }:{icon:string, theme: Theme}) => ({
  "&":
    { "path":
      {
        fill:  theme.customColors.systemPrimary02,
        transform:
          icon === "Icons_rating_active"
            ? "scale(1.2) translate(-190px, -190px)"
            : icon ===
            "Star3-5" ? "scale(1.2) translate(-170px, -190px)" :
              icon === "Icons_salva-preferito_glow" ?
                "scale(1.2) translate(-50px, -50px)"
                : undefined
      }
    }
}));
