import {
  Stack,
  Typography,
  Button
} from "@mui/material";
import {
  styled
} from "@mui/material/styles";
import { StyledCommentsSection } from "../../components/CommentsSection/CommentsSection.style";

export const TopicCardContainerStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  padding: theme.spacing(3),
  transition:"height .6s linear",


  "& .MuiChip-root": {
    border: `2px solid ${theme.customColors.borderTag}`,
    borderRadius: "8px",

    "& span": {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "1px",
      lineHeight: "20px"
    }
  },

  [`${StyledCommentsSection}`]:{
    "&.visible":{
      borderTop:`1px solid ${theme.customColors.border}`,
      paddingTop:theme.spacing(3)
    }

  },
  [`${theme.breakpoints.down("sm")}`]:{
    padding: theme.spacing(3, 2),

    "& .MuiChip-label": {
      fontSize: "12px !important",
      lineHeight: "14px !important"
    }

  }
}));

export const TopicCardTop = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap:theme.spacing(2),
  justifyContent:"space-between",
  marginBottom:theme.spacing(1)

}));
export const TopicCardMainInfo = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap:theme.spacing(2)

}));

export const TopicCardBody = styled(Stack)(({ theme }) => ({
  minHeight:"150px",
  position:"relative",

  "> p, > button, > div":{
    paddingRight:"88px",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "16px",
      paddingRight:"0px"
    }
  },


  "button":{
    fontSize: "18px",
    fontWeight: "500",
    gap:"10px",
    justifyContent: "flex-start",
    lineHeight: "22px",
    padding: 0,
    width: "fit-content"
  }

}));

export const OwnerNameTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "18px"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  fontSize: "48px",
  fontWeight: "700",
  lineHeight: "56px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "24px",
    lineHeight: "30px"
  }
}));

export const BodyTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  flexGrow:1,
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21px",
  maxWidth:"100%",
  overflowWrap:"break-word",

  [theme.breakpoints.down("sm")]: {
    paddingRight: "0"
  }
}));

export const LinkTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.backgroundPrimaryCta,
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "22px"
}));

export const LinkTitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "22px"
}));

export const LinkInfoTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textDisabledAlternative,
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "22px"
}));

export const ActionLink = styled(Button)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "22px",
  minWidth:"unset",
  padding: "0px",
  textDecoration: "underline",
  textTransform: "none",

  "&:hover": {
    background: "none",
    textDecoration: "underline"
  },

  [`${theme.breakpoints.down("sm")}`]:{
    fontSize:theme.spacing(1.5)
  }
}));

export const TopicTypography = styled(Typography)(({ theme }) => ({
  alignItems: "center",
  borderRadius: "8px",
  color: theme.customColors.textWidget,
  display:"flex",
  gap:theme.spacing(1),
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "21px",
  padding: "7.5px 8px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "12px",
    maxHeight: "24px"
  }
}));

export const InfoTypography = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "21px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    lineHeight: "14px"
  }
}));
