import {
  add,
  isAfter,
  format
} from "date-fns";
import type { JavaBasicDate } from "../types";

/**
 * dateIsExpiring,
 * checks if a date is at least an X number of days in the future
 * @param date a date to check
 * @param days the amount of the interval
 * @returns a boolean indicating if the date is less than X days away
 */
export function dateIsExpiring({
  date,
  days
} :{date: JavaBasicDate, days: number}) {
  return isAfter(add(new Date(), { days }), new Date(date.join("/") || ""));
}

/**
 * secondsToDHMSTime,
 * (NOTE: switch to Intl.DurationFormat when available))
 * converts a duration in seconds to a duration string with the
 * format DD HH mm ss (formatting options are not customizable)
 * @param secondsToConvert duration in seconds
 * @returns a duration string
 */
export function secondsToDHMSTime(secondsToConvert: number) {
  const seconds = secondsToConvert % 60;
  const totalMinutes = secondsToConvert / 60;
  const minutes = Math.floor(totalMinutes) % 60;
  const totalHours = totalMinutes / 60;
  const hours = Math.floor(totalHours % 24);
  const totalDays = totalHours / 24;
  const days = Math.floor(totalDays);
  let res: string[] = [];
  if(days > 0) { res.push(`${ days }d`) }
  if(hours > 0) { res.push(`${ hours }h`) }
  if(minutes > 0) { res.push(`${ minutes }m`) }
  if(seconds > 0) { res.push(`${ seconds }s`) }
  return res.join(" ");
}

/**
 * secondsToHours,
 * NOTE: implements explicit type checking because this adapter
 * will be used with values exposed by an api
 * converts a duration in seconds to a duration string expressing
 * time in hours with a decimal point
 * @param secondsToConvert
 * @returns a duration string
 */
/* REPLACED WITH date-fns METHOD */
// export function secondsToHours(secondsToConvert: number | string) {
//   if (typeof secondsToConvert === "string") {
//     return Number(Number.parseFloat(secondsToConvert) / 60 / 60).toFixed(1);
//   } else if (typeof secondsToConvert === "number") {
//     return Number(secondsToConvert / 60 / 60).toFixed(1);
//   } else {
//     throw new TypeError("secondsToConvert should be a number or a string");
//   }
// }

/**
 * secondsToMinutes,
 * NOTE: implements explicit type checking because this adapter
 * will be used with values exposed by an api
 * converts a duration in seconds to a duration string expressing
 * time in minutes as an integer
 * @param secondsToConvert
 * @returns a duration string
 */
/* REPLACED WITH date-fns METHOD */
// export function secondsToMinutes(secondsToConvert: number | string) {
//   if (typeof secondsToConvert === "string") {
//     return Math.floor(Number.parseInt(secondsToConvert) / 60).toString();
//   } else if (typeof secondsToConvert === "number") {
//     return Math.floor(secondsToConvert / 60).toString();
//   } else {
//     throw new TypeError("secondsToConvert should be a number or a string");
//   }
// }

export function hasPassedExpiration(expirationDate?: JavaBasicDate) {
  // const aYearFromNow = new Date();
  // aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
  return expirationDate
    ? ( new Date() > new Date(expirationDate.toString()))
    // : ( new Date() > new Date(aYearFromNow)); // literally impossible...
    : false;
}

export function arrayToDate(date: number[]) {
  try {
    const [ year, month, day ] = date;
    return new Date(year, month-1, day);
  } catch(error) {
    // eslint-disable-next-line no-console
    console.error("arrayToDate: ", error);
  }
}

export function toJavaBasicDate(date: Date) {
  return format(date, "yyyy-MM-dd").split("-").map(x => parseInt(x)) as JavaBasicDate;
}

export function stringifyJavaBasicDate(date: JavaBasicDate) {
  return date.map((number) => number.toString().padStart(2, "0")).join("/");
}

/**
 * secondsTo8061Duration,
 * https://stackoverflow.com/questions/57737451/convert-seconds-to-iso-8601-duration-with-javascript
 * @param seconds
 * @returns ISO8061 duration string
 */
export function secondsTo8061Duration(seconds: number) {
  const SECONDS_PER_SECOND = 1;
  const SECONDS_PER_MINUTE = 60;
  const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
  const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;

  const designations: [string, number][] = [
    ["D", SECONDS_PER_DAY],
    ["H", SECONDS_PER_HOUR],
    ["M", SECONDS_PER_MINUTE],
    ["S", SECONDS_PER_SECOND]
  ];

  let duration = "PT";
  let remainder = seconds;

  designations.forEach(([sign, split]) => {
    const value = Math.floor(remainder / split);
    remainder = remainder % split;
    if (value) { duration += `${value}${sign}` }
  });

  if (duration == "PT") { duration = "PT0S" }

  return duration;
}

export function getTimezoneFormattedDate(date?: string) {
  const dateTime = date ? new Date(date) : new Date();
  const offset = -dateTime.getTimezoneOffset();
  const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, "0");
  const minutes = String(Math.abs(offset) % 60).padStart(2, "0");
  const sign = offset >= 0 ? "+" : "-";
  return `${sign}${hours}:${minutes}`;
}

