import {
  Box,
  Grid,
  GridProps,
  Typography
} from "@mui/material";

import { styled } from "@mui/material/styles";

export const CardProfileBox = styled(Grid)<GridProps>(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius:"8px",
  padding:theme.spacing(3),
  width:"100%",

  [theme.breakpoints.down("sm")]:{
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    minHeight:"246px",
    padding:theme.spacing(2),
    width:"100%"
  }

})) as typeof Grid; // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'

export const Description= styled(Box)(({ theme }) => ({
  overflow: "hidden",

  "a":{
    display:"flex",
    fontWeight:700,
    paddingBottom:"1.5rem",
    textDecoration:"underline"
  },
  "h5":{
    fontSize:theme.spacing(4),
    paddingBottom:theme.spacing(1)
  },
  "p":{
    paddingBottom:theme.spacing(1)
  },
  [theme.breakpoints.down("sm")]:{
    "a":{
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      paddingBottom:"16px"
    },

    display: "flex",
    flexDirection: "column",

    "h5":{
      fontSize:theme.spacing(2),
      lineHeight: "1.3125rem",
      paddingBottom: "6px"
    },
    "p":{
      fontSize: "12px",
      lineHeight: "0.875rem",
      paddingBottom:theme.spacing(0.6) }
  }

}));

export const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(3),

  [theme.breakpoints.down("sm")]:{
    gap: "15px",
    height: "100%"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  fontSize:"1.5rem",
  fontWeight:700,
  lineHeight:1.2,
  paddingBottom:theme.spacing(4),

  [theme.breakpoints.down("sm")]:{
    fontSize:"0.875rem",
    lineHeight: "0.875rem",
    paddingBottom: 0
  }
}));

