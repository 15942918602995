import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TabsContentProps } from "./TabsContent.types";
import {
  CardEdition,
  GridBox
} from "../../components";

export function TabsContentEditions({
  contentEditions,
  courseId,
  currentLO,
  rootId,
  titleEditions,
  currentTeachers=[]
} :
  & Pick<
  TabsContentProps,
  | "courseId"
  | "currentLO"
  | "rootId"
  | "currentTeachers"
  >
  & Required<
    Pick<
    TabsContentProps,
    | "contentEditions"
    | "titleEditions"
    >
  >
) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Typography
        fontSize="20px"
        lineHeight="21px"
        marginBottom={ theme.spacing(3) }
      >
        { titleEditions }
      </Typography>
      <GridBox
        columns={ isMobile ? 1 : 2 }
      >
        {
          currentLO && contentEditions?.map((edition, index) => (
            <CardEdition
              courseId={ courseId }
              key={ index }
              booked={ Boolean(edition?.status === "E") }
              bookedSeats={ edition.occupiedPlaces }
              editionId={ edition.id }
              endTime={ edition.endTime }
              enrollType={ currentLO.enrollType }
              enrollmentStatus={ currentLO.enrollmentStatus || "" }
              gMapsUrl={ edition.gMapsUrl }
              hasEditionEnrolled={ Boolean(contentEditions.find(edition => edition.status === "E") ) }
              id={ currentLO.id }
              learningObjectTypology={ currentLO?.learningObjectTypology }
              link={ edition.link || null }
              location={ edition.location }
              maxSeats={ edition.totalPlaces }
              rooms={ edition.rooms }
              rootId={ rootId }
              startTime={ edition.startTime }
              status={ currentLO?.status || "N" }
              statusEdition={ edition.status }
              currentTeachers={ currentTeachers }
              selfEnrollment={ currentLO?.selfEnrollment }
              currentLO={ currentLO }
            />))
        }
      </GridBox>
    </>
  );
}
