/* eslint-disable max-len */

import { Typography, Grid } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useState } from "react";
import ProgressBar from "react-customizable-progressbar";
import { useTranslation } from "react-i18next";

import type { UserProgress, UserProgressItem, userProgressField } from "types";

import {
  CardProgressBox,
  CardProgressItemsBox,
  StyledPercentageItem,
  StyledTitleSummary,
  StyledTooltip
} from "./CardUserProgress.styles";
import { UserProgressProps } from "./CardUserProgress.types";
import { Icon } from "../../components";

import { useGetLearnerGoalsConfigsQuery, useLearnerProgressQuery } from "../../queries";



export function CardUserProgress({
  isProfilePage
}: UserProgressProps) {
  const theme = useTheme();

  const { data: userProgress, isSuccess: userProgressSuccess } = useLearnerProgressQuery();
  const {
    data: userGoalsConfig,
    isSuccess: userGoalConfigIsSuccess,
    isLoading:userGoalConfigIsLoading
  } = useGetLearnerGoalsConfigsQuery();


  const progressEnabled =  userGoalConfigIsSuccess && !userGoalConfigIsLoading && userGoalsConfig.enabled &&
  userGoalsConfig.yourProgress.enabled;

  const { t } = useTranslation();


  const progress = setProgressFields(userProgress as UserProgress);



  const [tooltipVisible, setTooltipVisible]=useState<number | null>(null);
  return (
    progressEnabled && userProgress?.data?.total?.hours && userProgress && userProgressSuccess ?
      <CardProgressBox
        className="widget-item"
        $profilePage={ isProfilePage }
      >
        <Typography
          fontWeight={ 700 }
          fontSize={ isProfilePage ? theme.spacing(3) : undefined }
          sx={ {
            marginRight: "10px",

            [theme.breakpoints.down("sm")]:{
              fontSize: theme.spacing(1.75)
            }

          } }
        >
          { t("your_progress") }
        </Typography>

        <Typography
          sx={ {
            display:isProfilePage ? "flex" : "none",
            [theme.breakpoints.down("sm")]:{
              fontSize: theme.spacing(1.75)
            }

          } }>
          { t("progress_text") }
        </Typography>


        <CardProgressItemsBox>
          { progress.flatMap((item, index) =>
            <>
              <StyledPercentageItem
                key={ `progressItem-${index}` }
                $left={ item.left as string }
                $right={ item.right as string }
              >
                <ProgressBar
                  className={ item.classname }
                  radius={ item.radius  ?? 0 }
                  progress={ item.progress ? item.progress as number * 100 : 0 }
                  cut={ item.cut }
                  strokeColor={ theme.customColors.accentVariantB }
                  pointerStrokeWidth={ 0 }
                  strokeWidth={ 8 }
                  trackStrokeWidth={ 8 }
                  rotate={ item.rotate }
                  trackStrokeColor={ alpha(theme.customColors.textWidget, 0.4) }
                  initialAnimationDelay={ 3000 }
                  initialAnimation={ true }
                  trackTransition=".1s ease"
                  transition="1s ease"
                />
              </StyledPercentageItem>

            </>

          )
          }
          <StyledTitleSummary>
            {
              (Math.round(userProgress?.data.total.completedHours * 100) / 100) + "/" + (Math.round(userProgress?.data.total.hours * 100) / 100)
            }
            <span>{ t("completed_other") }</span>
          </StyledTitleSummary>

          <Grid
            alignSelf={ "flex-end" }
            display={ "flex" }
            flexDirection={ "row" }
            justifyContent={ "space-around" }
            height={ "24%" }
            width={ "78%" }
            margin={ "0 auto" }
            position={ "relative" }
            zIndex={ 1 }
          >
            {
              progress.map((item, index)=> (
                <Grid
                  alignSelf={ item.iconAlignement }

                  key={ index }
                  onMouseOver={ ()=> {
                    setTooltipVisible(index);
                  } }
                  onMouseLeave={ ()=> {
                    setTooltipVisible(null);
                  } }
                  sx={ {
                    alignSelf:progress.length >= 3 || progress.length === 1 ? "flex-end" :"flex-start",
                    cursor:"pointer",
                    overflow:"visible",
                    position:"relative",

                    "&:nth-child(2)":{
                      alignSelf:progress.length === 3 ? "flex-start" : undefined,
                      left:progress.length === 3 ? "-20px" : undefined
                    }
                  } }
                >
                  <StyledTooltip
                    $bottom="40px"
                    $left={ "-180%" }
                    $right={ "50%"  }
                    $top={ "unset" }
                    $visible={ index === tooltipVisible }
                  >
                    <div className="tooltip-wrap">
                      { item.label }
                      <span> { (Math.round(item?.completed as number * 100) / 100) + "/" +  (Math.round(item?.total as number * 100) / 100) }</span>
                    </div>

                  </StyledTooltip>
                  <Icon
                    color={ theme.customColors.textWidget }
                    icon={ item.icon as string }
                    size={ 20 }

                  />
                </Grid>

              ))
            }
          </Grid>
        </CardProgressItemsBox>

      </CardProgressBox> :
      null
  );

  function setProgressArrayKey(userProgress: UserProgress ){
    let progressArray = [] as string[];
    if(userProgress?.data?.suggestedByManager?.hours){
      progressArray.push("suggestedByManager");
    }
    if(userProgress?.data?.mandatory?.hours){
      progressArray.push("mandatory");
    }
    if(userProgress?.data?.suggestedByHR?.hours){
      progressArray.push("suggestedByHR");
    }
    if(userProgress?.data?.suggestedByAI?.hours){
      progressArray.push("suggestedByAI");
    }
    return progressArray;

  }
  function setFieldType(field:string){
    switch(field){

    case "suggestedByManager":
      return {
        iconName: "manager_suggested",
        label: t("manager_suggested")
      };
    case "mandatory":
      return {
        iconName: "Icons_obbligatorio",
        label: t("mandatory")
      };
    case "suggestedByHR":
      return {
        iconName: "Icons_invito",
        label: t("hrSuggested")
      };
    case "suggestedByAI":
      return {
        iconName: "AI",
        label: t("ai_suggested")
      };
    default:
      return {
        iconName: "AI",
        label: t("ai_suggested")
      };
    }
  }
  function setProgressBarTypes(progressNumber:number, indexNumber:number){

    switch(progressNumber){
    case 1:
      return{
        className:"",
        cut:30,
        left:"unset",
        radius:100,
        right:"unset",
        rotate:106
      };
    case 2:
      return{
        className:indexNumber === 0 ? "progress-left" : "",
        cut: 240,
        left:"unset",
        radius:100,
        right:"unset",
        rotate:280
      };
    case 3:
      return{
        className:indexNumber === 0 ? "progress-left" : "",
        cut:indexNumber === 1 ? 60 : 240,
        left:indexNumber === 1 ? "17px" : "unset",
        radius:indexNumber === 1 ? 80 : 100,
        right:"unset",
        rotate:indexNumber === 1 ? 120 : 280
      };
    case 4:
      return{
        className:indexNumber < 2 ? "progress-left" : "",
        cut:240,
        left: indexNumber === 1 ? "24px": "unset",
        radius:indexNumber === 1 || indexNumber === 2 ? 80 : 100,
        right:indexNumber === 2 ? "24px" : "unset",
        rotate:indexNumber === 1 || indexNumber === 2 ? 285 : 280
      };
    default:{
      return {
        className:indexNumber < 2 ? "progress-left" : "",
        cut:240,
        left: indexNumber === 1 ? "24px": "unset",
        radius:indexNumber === 1 || indexNumber === 2 ? 80 : 100,
        right:indexNumber === 2 ? "24px" : "unset",
        rotate:indexNumber === 1 || indexNumber === 2 ? 284 : 280
      };
    }
    }

  }

  function setProgressFields(userProgress: UserProgress ):UserProgressItem[]|[]{
    const progressFieldArray = setProgressArrayKey(userProgress) as userProgressField;

    return  progressFieldArray.flatMap((item, index) => {
      const userProgressItem = userProgress.data?.[item];

      if(userProgressItem){
        return {
          classname: setProgressBarTypes(progressFieldArray.length, index )?.className,
          completed: userProgressItem.completedHours ?
            userProgressItem.completedHours : 0,
          cut:setProgressBarTypes(progressFieldArray.length, index)?.cut,
          field: item,
          icon: setFieldType(item).iconName,
          iconAlignement: "center",
          label: setFieldType(item).label,
          left: setProgressBarTypes(progressFieldArray.length, index)?.left,
          maxOffsetX: index === progressFieldArray.length ? 1470 : 0,
          minOffsetX: index === progressFieldArray.length ? 1480 : 0,
          progress: userProgressItem?.completedHours / userProgressItem?.hours,
          radius: setProgressBarTypes(progressFieldArray.length, index)?.radius,
          right: setProgressBarTypes(progressFieldArray.length, index)?.right,
          rotate:setProgressBarTypes(progressFieldArray.length, index)?.rotate,
          total:userProgressItem?.hours
        } as UserProgressItem;
      }else {
        return [];
      }
    });
  }
}
