/* eslint-disable */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  useLocation,
  useParams
} from "react-router-dom";
import {
  GridMain,
  Modal,
  Spinner,
  Tabs,
  Video
} from "../../components";
import {
  LEVEL,
  TAB_ITEMS_CONFIG
} from "../../consts";
import {
  useLearningObjectQuery,
  useRelatedLearningObjectQuery,
  useStartOrResumeQuery,
  useToolsQuery
} from "../../queries";
import { useSharedPosts } from "../../queries/community";
import { useNavigate } from "../../services";
import { useAuthStore } from "../../stores";
import type {
  Course,
  Level,
  Root
} from "../../types";
import { getError } from "../../utils/error";
import {
  filterTabTypes,
  getExploreTabProps
} from "../../utils/explore";

const EXTERNAL_LEVEL = {
  [LEVEL.DA_SCORM]: LEVEL.DA_SCORM,
  [LEVEL.DA_XAPI]: LEVEL.DA_XAPI,
  [LEVEL.DA_SURVEY]: LEVEL.DA_SURVEY,
  [LEVEL.DA_YOUTUBE]: LEVEL.DA_YOUTUBE
} as const;

export function ExploreWatch() {

  const setSpinnerLoginVisible = useAuthStore(state => state.setSpinnerLoginVisible);
  setSpinnerLoginVisible(false);
  const { t } = useTranslation();
  const { id: idString, learningObjectTypology } = useParams<{
    id: string
    learningObjectTypology: Level
  }>();
  const id = idString ? parseInt(idString) : NaN;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { state: routerState } = useLocation();

  const smartConfiguration = useAuthStore(state => state.smartConfiguration);
  const enableCommunityFlag = useAuthStore(state => state.corporateInfo?.enable_community_flag);

  const routerStateRootId = routerState?.rootId
    ? routerState?.rootId
    : routerState?.loRootId
      ? routerState?.loRootId
      : null;

  const {
    data: learningObject,
    error:learningObjectError,
    fetchStatus,
    isLoading: learningObjectIsLoading,
    isError:learningObjectIsError,
    refetch:refetchStartOresume
  } = useStartOrResumeQuery({
    learningObjectId: id,
    learningObjectTypology: (learningObjectTypology as Level),
    parentId: routerStateRootId ? routerStateRootId : routerState?.courseId ? routerState?.courseId : id
  });

  useEffect(()=> {
    if(learningObjectError){
      setShowModal(true);
    }
  }, [learningObjectError]);

  const courseDetailsId = learningObject &&  "courseDetails" in learningObject && learningObject?.courseDetails?.id ?
    learningObject?.courseDetails?.id : routerState?.courseId ? routerState?.courseId : null;

  const rootDetailsId = learningObject &&  "rootDetails" in learningObject && learningObject?.rootDetails?.id ?
    learningObject?.rootDetails?.id : routerState?.rootId ? routerState.rootId : null;

  // const isExternal = learningObjectTypology === EXTERNAL_LEVEL.DA_SCORM
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_XAPI
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_SURVEY
  //   || learningObjectTypology === EXTERNAL_LEVEL.DA_YOUTUBE;

  const {
    data: parentCourse,
    refetch: refetchParentCourse
  } = useLearningObjectQuery({
    enabled:Boolean(courseDetailsId),
    id: courseDetailsId ?? "",
    learningObjectTypology: LEVEL.COURSE,
    queryKey: [`parent-course-${id}`],
    rootId:rootDetailsId ?? ""
  });

  const {
    data: parentRoot,
    refetch:refetchParentRoot
  } = useLearningObjectQuery({
    enabled: Boolean(rootDetailsId),
    id: rootDetailsId ?? "",
    learningObjectTypology: LEVEL.PATH,
    onlyLearningActivity: true,
    queryKey: [`parent-root-${id}`]
  });


  const { data: related } = useRelatedLearningObjectQuery({
    learningObjectId: id?.toString() ?? ""
  });

  const communityFlag = enableCommunityFlag === true && smartConfiguration?.funcCommunity === true && smartConfiguration?.communityShareContent;

  const { data: sharedPosts } = useSharedPosts({ enabled: communityFlag, loId: id });

  const { data: tools } = useToolsQuery({
    learningObjectId: learningObject?.id?.toString() ?? ""
  });

  useEffect(()=> {
    if(learningObjectError){
      setShowModal(true);
    }
  }, [learningObjectError]);

  const activities = (parentCourse && "learningActivities" in parentCourse)
    ? parentCourse.learningActivities
    : [];

  const filteredTabTypes =
  learningObject?.learningObjectTypology ? (
    filterTabTypes({
      activities,
      learningObject,
      related,
      sharedPosts,
      tabItems: TAB_ITEMS_CONFIG[learningObject?.learningObjectTypology].tabItems,
      tools
    })
  ) : [];

  const contentTabs = filteredTabTypes?.flatMap((tabType) => (
    learningObject ? (
      getExploreTabProps({
        activities,
        courseId: parentCourse?.id,
        isDetailPage: false,
        learningObject,
        parentCourse,
        parentRoot,
        related,
        rootId: routerStateRootId,
        sharedPosts,
        t,
        tabType,
        tools
      })) : []
  ));

  const typology = learningObject?.learningObjectTypology;
  const isExternal = typology === EXTERNAL_LEVEL.DA_SCORM
    || typology === EXTERNAL_LEVEL.DA_XAPI
    || typology === EXTERNAL_LEVEL.DA_SURVEY
    || typology === EXTERNAL_LEVEL.DA_YOUTUBE;


  // MayBe is Necessary if returned sync LO
  // if (
  //   learningObject?.learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
  //   || learningObject?.learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
  //   || learningObject?.learningObjectTypology === LEVEL.DA_LINKEDIN
  // ){
  //   return (
  //     <Navigate
  //       to={ `/esplora/dettaglio/${learningObject.id}/${learningObject.learningObjectTypology}` }
  //       state={ {
  //         courseId: courseDetailsId,
  //         goBackPath: `/esplora/dettaglio/${id}/${learningObjectTypology}`,
  //         rootId: rootDetailsId
  //       } }
  //     />
  //   );
  // }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // useEffect(() => {

  //   const handleVisibilityChange = () => {
  //     if (!document.hidden){
  //       refetchStartOresume();
  //       if(rootDetailsId){
  //         refetchParentRoot();
  //       }
  //       if(courseDetailsId){
  //         refetchParentCourse();
  //       }
  //     }
  //   };

  //   // document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  window.addEventListener("message", (e) => {
    if (e.data == "closeModal" && learningObject?.isTest) {
      window.location.reload();
    }
  });

  // const tabContents = getTabTypesContents({
  //   learningObject,
  //   rootId: parentRoot ? parentRoot.id : undefined
  // });

  return (
    <>
      {
        (learningObjectIsLoading && fetchStatus !== "idle" ) ? (
          <Spinner/>
        ) : (
          !learningObject ? (
            <p>Nothing Found</p>
          ) :
            (
              learningObject?.learningObjectTypology === LEVEL.DA_PHYSICAL_CLASS
            || learningObject?.learningObjectTypology === LEVEL.DA_VIRTUAL_CLASS
            || learningObject?.learningObjectTypology === LEVEL.DA_URL_RES
            || learningObject?.learningObjectTypology === LEVEL.DA_LINKEDIN
            || learningObject?.learningObjectTypology === LEVEL.DA_LTI
            || learningObject?.learningObjectTypology === LEVEL.DA_FINAL_BALANCE
            || learningObject?.learningObjectTypology === LEVEL.DA_MEETING
            || learningObject?.learningObjectTypology === LEVEL.DA_GOALS
            || learningObject?.learningObjectTypology === LEVEL.DA_MATERIAL
            ) ?
              <Navigate
                to={ `/esplora/dettaglio/${learningObject.id}/${learningObject.learningObjectTypology}` }
                state={ {
                  courseId: courseDetailsId,
                  // goBackPath: rootDetailsId ? `/esplora/dettaglio/${rootDetailsId}/${LEVEL.PATH}` : courseDetailsId ?  `/esplora/dettaglio/${courseDetailsId}/${LEVEL.COURSE}` :  `/esplora/dettaglio/${id}/${learningObjectTypology}`,
                  rootId: rootDetailsId
                } }
              />
              :
              (
                <>
                  <Video
                    key={learningObject.id}
                    autoplay={ true }
                    courseDetails={ parentCourse ? parentCourse as Course : learningObject?.courseDetails }
                    courseId={ courseDetailsId || null }
                    coverFallBack={ learningObject.coverPublicURL }
                    isExternal={isExternal}
                    height="750px"
                    id={ learningObject?.id }
                    isMandatory={ learningObject.isMandatory }
                    isFavourite={ learningObject?.isFavourite }
                    isStandAlone={ !routerStateRootId && !routerState?.courseId }
                    learningObjectType={ learningObject?.learningObjectType }
                    learningObjectTypology={ learningObject?.learningObjectTypology  }
                    loStarted={ {
                      id:id,
                      learningObjectTypology:learningObjectTypology as Level,
                      parentId:routerStateRootId ? routerStateRootId : routerState?.courseId ? routerState?.courseId : id
                    } }
                    moduleNumber={  courseDetailsId || rootDetailsId
                      ? learningObject?.prereqOrderNumber + 1 : undefined  }
                    parentId={ rootDetailsId }
                    rating={ learningObject?.averageRatingScore ?? 0 }
                    refetchParentCourse={ refetchParentCourse }
                    refetchParentRoot={ refetchParentRoot }
                    rootDetails={ parentRoot as Root || parentCourse as Course }
                    shortDescription={ learningObject?.shortDescription }
                    status={ learningObject?.status }
                    title={ learningObject?.title }
                    videoId={ learningObject?.brightCoverId?.toString()  }
                    tentativeId={ learningObject?.tentativeId }
                    canAccessNow={ learningObject?.canAccessNow }
                    canAccessUntil={ learningObject?.canAccessUntil }
                  />
                  <GridMain
                    padding={ "0 0 80px" }
                  >
                    <Tabs
                      titleInset={ true }
                      contents={ contentTabs }
                    />
                  </GridMain>
                </>
              )
        )
      }
      <Modal
        description={ getError(learningObjectError)?.[0]?.message ?? "" }
        open={ learningObjectIsError && showModal }
        title={ getError(learningObjectError)?.[0]?.name ?? "" }
        onClose={ ()=> {
          setShowModal(false);
          navigate(-1);
        } }
      />
    </>
  );
}
