import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CardGrid,
  CardBodyGrid,
  DescriptionTypography,
  ImageBox,
  TitleTypography
} from "./SharedItemCard.styles";
import { CardProps } from "./SharedItemCard.types";
import {
  CardCategories,
  ButtonLink,
  Icon
} from "..";
import { useNavbarStore } from "../../stores";
import { TruncatedText } from "../TruncatedText";

export function SharedItemCard({

  coverPublicURL,
  id,
  learningObjectTypology,
  shortDescription,
  title,
  topicTags,
  ...props
}: CardProps) {

  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const{ state:routerState } = useLocation();

  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  return (
    <CardGrid container { ...props }>
      <Grid
        width={ isMobile ? "100%" : "165px"  }
        height={ isMobile ? "170px" : "212px" }
        sx={ {
          borderRadius:1
        } }
      >
        <ImageBox
          alt="card cover"
          component="img"
          src={ coverPublicURL }
        />
      </Grid>
      <CardBodyGrid >

        <TitleTypography variant="h1">
          <TruncatedText text={ title } maxLength={ 35 } />
        </TitleTypography>

        { topicTags && topicTags?.length > 0 ?
          <CardCategories categories={ topicTags  }
            sx={ {
              [`${theme.breakpoints.down("sm")}`]:{
                marginTop: "-8px"
              }
            } }/> : null
        }

        <DescriptionTypography>
          <TruncatedText text={ shortDescription } maxLength={ 60 } />
        </DescriptionTypography>
        <ButtonLink
          sx={ {
            color: theme.customColors.backgroundPrimaryCta,
            fontSize: isMobile ? "14px" : "1.0625rem",
            fontWeight:500,
            gap:1.25,
            padding:0
          } }
          onClick={ ()=> {
            navigate(`/esplora/dettaglio/${id}/${learningObjectTypology}`,{ state:{
              ...routerState,
              isFromCommunity:"/community"
            } });
            setNavbarItem("EXPLORE");
          } }
        >
          {
            learningObjectTypology === "PATH" ? t("root_go") :
              learningObjectTypology === "COURSE" ? t("course_go") :
                t("activity_go")
          }
          <Icon
            icon="arrow_right_horizontal"
            color={ theme.customColors.backgroundPrimaryCta }
            size={ 18 }
          />
        </ButtonLink>

      </CardBodyGrid>

    </CardGrid>
  );
}
