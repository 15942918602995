import { Box, Grid, Modal, Typography } from "@mui/material";
import { useTheme  } from "@mui/material/styles";
import * as React from "react";
import { style } from "./style";
import { ModalBasicProps } from "./type";

export function ModalBasic({ open, handleClose, title, content, size }: ModalBasicProps) {
  const theme = useTheme();

  return (
    <Modal
      open={ open }
      onClose={ handleClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={ {
        zIndex: 1400
      } }
    >
      <Box
        sx={ {
          bgcolor: theme.customColors.backgroundPrimary,
          border: `2px solid ${ theme.customColors.border }`,
          ...style,
          ...size,
          position: "relative"
        } }
      >
        <Typography
          id="modal-modal-title"
          variant="h5"
          sx={ {
            fontWeight: 700,
            marginLeft: "20px"
          } }>
          { title }
        </Typography>
        <Grid container sx={ { my: 4, justifyContent: "center" } }>
          { content }
        </Grid>
      </Box>
    </Modal>
  );
}
