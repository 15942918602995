import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const barHeight = "8px";
const barWidth = "100%";

export const ProgressTypography = styled(Typography)(()=> ({
  ".bold": {
    fontSize: "0.75rem",
    fontWeight: 700,
    lineHeight: 1.15
  },
  fontSize: "0.875rem",
  lineHeight: 1.3,
  textTransform: "capitalize"
}));

export const Bar = styled("span")(() => ({
  borderRadius: `calc(${barHeight} / 2)`,
  display: "block",
  height: barHeight
}));

export const BackgroundBar = styled(Bar)(({ theme }) => ({
  backgroundColor: theme.customColors.systemPrimary01Alternative02,
  width: barWidth
}));

export const ForegroundBar = styled(Bar)<{
  $progressAmount: string
}>(({
  $progressAmount,
  theme
}) => ({
  backgroundColor: theme.customColors.accentVariantB,
  boxShadow:`0px 0px 4px ${theme.customColors.accentVariantB}`,
  width: `${$progressAmount}%`
}));
