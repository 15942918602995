import { useTranslation } from "react-i18next";
import {
  StakeholderCardAvatarContainer,
  StakeholderCardAvatarOverlay,
  StakeholderCardContainer,
  StakeholderCardInfoContainer, StakeholderCardName, StakeholderCardType
} from "./StakeholderCard.styles";
import {
  CAROUSEL_TYPE
} from "../../../consts";
import { TypographyTitle } from "../../../styles/global_styles";
import {
  Carousel
} from "../../index";
import { TabsContentProps } from "../TabsContent.types";

export function TabsContentStakeholders({
  stakeholders
} :
  Pick<
    TabsContentProps,
    | "stakeholders"
  >
) {
  const { t } = useTranslation();

  return (
    <>
      <TypographyTitle>
        { t("stakeholders") }
      </TypographyTitle>
      { stakeholders && (
        <Carousel
          id={ "tabs-content-activities" }
          carouselType={ CAROUSEL_TYPE.ZOOM_RELATED }
          label={ "tabs-content-activities" }
        >
          { stakeholders.filter((stakeholder) => stakeholder.visStakeholder).map((stakeholder, index) => {
            return  (
              <StakeholderCardContainer key={ `stkhldr_${index}` }>
                <StakeholderCardAvatarContainer>
                  { stakeholder.imageProfile && <img alt={ stakeholder.name } style={ { width: "100%", height: "100%", borderRadius: 15, zIndex: -5 } } src={ stakeholder.imageProfile }/> }
                </StakeholderCardAvatarContainer>
                <StakeholderCardAvatarOverlay/>
                <StakeholderCardInfoContainer>
                  <StakeholderCardName>{ stakeholder.name }</StakeholderCardName>
                  <StakeholderCardType>{ stakeholder.type }</StakeholderCardType>
                </StakeholderCardInfoContainer>
              </StakeholderCardContainer>
            );
          })
          }
        </Carousel>
      ) }
    </>
  );
}
