import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { DataPickerCstProps } from "./types";

const DataPickerCst: React.FC<DataPickerCstProps> = (props) => {
  const {
    id,
    label,
    value = null,
    onChange,
    customWidth,
    error,
    errorMessage,
    disabled,
    required,
    sx,
    maxDate,
    minDate
  } = props;

  const theme = useTheme();

  return (
    <Box
      id={ `${ id }` }
      sx={ {
        display: "flex",
        minWidth: "200px",
        width: customWidth ? customWidth : "auto",
        ...sx
      } }
    >
      <LocalizationProvider dateAdapter={ AdapterDateFns }>
        <DatePicker
          label={ label }
          value={ value }
          onChange={ onChange }
          maxDate={ maxDate ? maxDate : null }
          minDate={ minDate ? minDate : null }
          sx={ {
            width: "100%",
            marginBottom: "5px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.customColors.border
              }
            },
            "& .MuiFormLabel-root": {
              color: theme.customColors.textPrimary,
              pointerEvents: "none" // Prevent interaction
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: theme.customColors.borderPrimaryCta
            },
            "& .MuiSvgIcon-root": {
              color: theme.customColors.textPrimary,
              marginRight: 0, // Adjust margin if needed
              position: "relative" // Position adjustment if required
            },
            "& .MuiInputBase-root.Mui-focused fieldset": {
              borderColor: theme.customColors.borderPrimaryCta
            }
          } }
          slotProps={ {
            textField: {
              required: required,
              size: "medium",
              error: error,
              helperText: (
                <span
                  style={ {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 2
                  } }
                >
                  { error ? errorMessage : "" }
                </span>
              )
            },
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10]
                  }
                }
              ],
              sx: {
                zIndex: 1500,
                "& .MuiPickersLayout-root": {
                  backgroundColor: theme.customColors.backgroundPrimary
                },
                "& .MuiPickersArrowSwitcher-root button": {
                  color: theme.customColors.textPrimary
                },
                "& .MuiIconButton-root": {
                  color: theme.customColors.textPrimary
                }
              }
            }
          } }
          disabled={ disabled }
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DataPickerCst;
