import {
  Stack,
  type StackProps
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { PropsWithChildren } from "react";

const BackgroundStack = styled(Stack)<
  StackProps & { src?: string }
>(({ src, theme }) => ({
  alignItems: "center",
  background: `${theme.linearGradients.gradientDAlternative09}${src ? ", url(" + src + ")" : ""}`,
  backgroundRepeat:"no-repeat",
  backgroundSize:"cover",
  flexGrow: 1,

  "> *": {
    marginTop: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3)
    }
  }
}));

export function Background({ children }: PropsWithChildren) {
  return (
    <BackgroundStack>
      { children }
    </BackgroundStack>
  );
}
