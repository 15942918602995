import {
  Link as MuiLink,
  LinkProps as MuiLinkProps,
  useTheme
} from "@mui/material";
import { AllHTMLAttributes, forwardRef } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";


export type LinkProps = MuiLinkProps & Omit<RouterLinkProps, "to">

/** LINK OVERRIDE,
 * forwards mui links to react-router-6 links
 * (use mui link props with react-router links)
 */
export const LinkBehavior = forwardRef<
 HTMLAnchorElement,
 Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ ref } to={ href } { ...other } />;
});
LinkBehavior.displayName = "Link";

export const Link = forwardRef<
  HTMLAnchorElement,
  AllHTMLAttributes<HTMLAnchorElement> & MuiLinkProps & Omit<RouterLinkProps, "to"> // aka, LinkProps
>(({ children, ...props }, ref) => {
  const theme = useTheme();

  return (
    <MuiLink
      ref={ ref }
      lineHeight="100%"
      { ...props }
      sx={ {
        color:theme.customColors.backgroundPrimaryCta,
        hover: { textDecoration: "none" },
        textDecoration: "none"
      } }
    >
      { children }
    </MuiLink>
  );
});
Link.displayName = "Link";
