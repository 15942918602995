import { useTranslation } from "react-i18next";
import {
  DetailTypography,
  HeroCardStack,
  TextGrid,
  TitleTypography
} from "./HeroCard.styles";
import { HeroCardProps } from "./HeroCard.types";
import { Button, Link } from "../../components";
import { useNavbarStore } from "../../stores/navbarStore";

export function HeroCard({
  coverPublicURL,
  $topImageVisible,
  shortDescription,
  title,
  ...props
}: HeroCardProps) {
  const { t } = useTranslation();
  const setNavbarItem = useNavbarStore(state => state.setNavbarItem);

  return (
    <HeroCardStack
      component="section"
      $coverPublicURL={ coverPublicURL }
      $topImageVisible={ $topImageVisible }
      gap={ 1.5 }
      { ...props }
    >
      <TextGrid>
        <TitleTypography>
          { t(title) }
        </TitleTypography>
        <DetailTypography>
          { t(shortDescription) }
        </DetailTypography>
        <Link href="/esplora"
          onClick={ ()=> {
            setNavbarItem("EXPLORE");
          } }
        >
          <Button
            variant={ "contained" }
          >
            { t("go_to_explore") }
          </Button>
        </Link>
      </TextGrid>

    </HeroCardStack>
  );
}
