/* eslint-disable max-len */
import {
  Box,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  format,
  isSameDay,
  minutesToHours
} from "date-fns";
import it from "date-fns/locale/it";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActionButton } from "./ActionButton.component";
import { ActionSelect } from "./ActionSelect.component";
import {
  ContainerStack,
  DateContainerStack,
  EventsStack,
  DurationStack,
  DayTypography,
  MonthTypography,
  InfoTypography,
  DurationTypography,
  TitleStack,
  TitleTypography,
  NoEventsStack,
  NoEventsTypography
} from "./CalendarCarouselCard.style";
import { LevelLabel } from "./LevelLabel.component";
import { TypeLabel } from "./TypeLabel.component";
import {
  ButtonLink,
  Link,
  type DayEvents
} from "../../components";
import { switchLocaleImport } from "../../components/Calendar/LocaleLogic";
import { CONTENT_TYPE } from "../../consts";
import { useDownloadIcs } from "../../queries";
import { useLanguageStore } from "../../stores";
import type {
  ContentType,
  JavaBasicDate,
  eventCourse,
  eventItem
} from "../../types";
import {
  calendarTimeAsDateTime,
  hasPassedCurrentDate
} from "../../utils/calendar";
import { Icon } from "../Icon";

export function CalendarCarouselCard({
  events,
  firstSmartCourseId,
  scrollAction,
  selectAction
}: {
  events: DayEvents[]
  firstSmartCourseId?: number
  scrollAction: () => void
  selectAction: (
    action: string,
    date: Date,
    slotId: number,
    start: string,
    end: string
  ) => void
}) {
  const theme = useTheme();
  const { t } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { mutate: mutateDownloadIcs, data: icsBody  } = useDownloadIcs();

  const language = useLanguageStore(state => state.language)?.id;

  const [locale, setLocale] = useState<Locale>(it);

  useEffect(() => {
    (async () => {
      try {
        const newLocale = await switchLocaleImport(language);
        setLocale(newLocale.default);
      } catch(err) {
        // eslint-disable-next-line no-console
        console.error("import date-fns locale:", err);
      }
    })();
  }, [language]);

  useEffect(() => {
    if(icsBody) {
      var element = document.createElement("a");

      element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(icsBody?.response as string));
      element.setAttribute("download", `${icsBody?.filename}` + ".ics" );

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }, [icsBody]);

  function getEventsObj(courses: eventCourse[], isSingleCourse = false, courseId?: number, loType?: string) {
    let events = [] as eventItem[];

    if(isSingleCourse && courseId && loType) {
      events.push(        {
        loId: courseId,
        loType: loType
      });
    } else {
      courses.forEach(course => {
        events.push(        {
          loId: course.id,
          loType: course.learningObjectType
        });
      });
    }

    return events;
  }

  return <ContainerStack
    alignItems="center"
    direction="column"
    justifyContent={ events.length === 3 ? "space-between" : "flex-start" }
    spacing={ events.length === 3 ? 0 : 2 }
  >
    { events.map((day, idx) =>
      <DateContainerStack
        key={ `${day?.date?.toString()}_${idx}` }
        direction="row"
        divider={
          <Divider
            orientation="vertical"
            sx={ { borderColor: theme.customColors.border, height: "auto" } }
          />
        }
        spacing={ 1 }
      >
        <Stack sx={ {
          alignItems: "center",
          color: theme.customColors.textWidget,
          ".icon-download": {
            cursor: "pointer",
            paddingTop: "5px"
          }
        } }>
          <DayTypography>{ 
            format(new Date(day?.date?.toString()?.replaceAll(",","/")  || ""), "dd", { locale: locale }) }</DayTypography>
          <MonthTypography>{ 
            format(new Date(day?.date?.toString()?.replaceAll(",","/")  || ""), "MMM", { locale: locale }) }
          </MonthTypography>
          { day.courses.length > 0 ?
            <Icon
              className="icon icon-download"
              icon={ "download" }
              width={ "20px" }
              height={ "20px" }
              color={ theme.customColors.textWidget }

              onClick={ () => {
                mutateDownloadIcs({
                  // eslint-disable-next-line max-len
                  date: `${day.date[0]}-${format(new Date(day?.date?.toString() || ""), "MM", { locale: locale })}-${format(new Date(day?.date?.toString() || ""), "dd", { locale: locale })}`,
                  events: getEventsObj(day.courses as eventCourse[]) }); } }
            />
            : null }
        </Stack>
        <EventsStack
          divider={
            <Divider sx={ { borderColor: theme.customColors.border , margin: "1rem 0" } }/>
          }
        >
          { day.courses.length > 0 ?
            day.courses.map((course) => {
              if (course.dayCourseType === "empty") return; // typescript fails to check this is impossible

              if (course.learningObjectType === CONTENT_TYPE.SMART_LEARNING_SLOT && course.date) {

                const endTime = calendarTimeAsDateTime({ date: course.date, time: course.endTime }).toISOString();
                const startTime = calendarTimeAsDateTime({ date: course.date, time: course.startTime }).toISOString();

                // const dayTime = calendarTimeAsDateTime({ date: day.date, time: course.startTime });
                // const hasPassed = isAfter(new Date(), dayTime);
                const hasPassed = hasPassedCurrentDate({ date: day.date, time: course.startTime });

                return (
                  <Stack
                    key={ `course-${course.id}` }
                    gap="8px"
                  >
                    <Stack
                      direction="row"
                      sx={ {
                        alignItems: "center",
                        height: "34px",
                        justifyContent: "space-between",

                        ".icon-download": {
                          cursor: "pointer",
                          marginLeft: "auto",
                          paddingRight: "10px"
                        }
                      } }
                    >
                      <Stack direction="row" spacing={ 1 } sx={ { alignitems: "center" } }>
                        <Icon
                          icon={ "smart_learning" }
                          width={ "1rem" }
                          color={ theme.customColors.systemSecondary04 }
                        />
                        <InfoTypography
                          color={ theme.customColors.systemSecondary04 }
                        >
                          { t("smart_learning") }
                        </InfoTypography>
                      </Stack>
                      <Stack
                        className={ "action-sl" }
                        direction={ "row" }
                      >
                        <ActionSelect
                          id={ course.id }
                          date={ new Date(day?.date?.toString() || "") }
                          selectAction={ selectAction }
                          start={ formatTime(course.startTime ?? "00:00") }
                          end={ formatTime(course.endTime ?? "00:00") }
                        />
                      </Stack>
                    </Stack>
                    <TitleStack
                      color={ theme.customColors.textWidget }
                      direction={ { sm: "row", xs: "column" } }
                      alignItems={ { sm: "center", xs: "flex-start" } }
                      gap={ "8px" }
                    >
                      <TitleTypography>{ t("learning_time") }</TitleTypography>
                    </TitleStack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      color={ theme.customColors.textWidget }
                    >
                      <Stack
                        alignItems="center"
                        direction={ "row" }
                        gap={ "8px" }
                      >
                        <Icon
                          icon={ "Icons_time" }
                          size={ "17px" }
                          color={ theme.customColors.textWidget }
                        />
                        <Typography>
                          { smartLearningTime(course.startTime ?? "0:0", course.endTime ?? "0:0") }
                        </Typography>
                      </Stack>
                      {
                        (course.id === firstSmartCourseId || hasPassed) ? (
                          <ButtonLink
                            sx={ {
                              fontSize: "1.125rem",
                              fontWeight: "500",
                              gap:"0.625rem",
                              padding: 0,

                              "& .icon path":{
                                fill:theme.palette.primary?.main
                              },

                              "&.Mui-disabled": {
                                background: "none",
                                color: theme.customColors.textDisabled,

                                "& .icon path":{
                                  fill: theme.customColors.textDisabled
                                }
                              }
                            } }
                            onClick={ () => { scrollAction() } }
                            disabled={ hasPassed }
                          >
                            { t("go_to_contents") }
                            <Icon icon={ "arrow_right_horizontal" } size={ "20px" } />
                          </ButtonLink>
                        ) : (
                          <Box
                            className={ "link-smart-details" }
                            sx={ {
                              fontSize: "1.125rem",
                              fontWeight: "500",
                              gap:"0.625rem",
                              padding: 0,

                              "& .icon path":{
                                fill:theme.palette.primary?.main
                              }
                            } }
                          >
                            <Link
                              disabled={ hasPassed }
                              href={ `/smart-learning?endTime=${endTime}&startTime=${startTime}` }
                              sx={ {
                                fontSize: "1.125rem",
                                fontWeight: "500",
                                padding: 0,

                                "& .icon path":{
                                  fill:theme.palette.primary?.main
                                }
                              } }
                            >
                              <Stack
                                direction={ "row" }
                                gap= { "0.625rem" }
                              >
                                { t("go_to_contents") }
                                <Icon icon={ "arrow_right_horizontal" } size={ "20px" } />
                              </Stack>
                            </Link>
                          </Box>
                        )
                      }
                    </Stack>
                  </Stack>
                );
              }
              const infoColor = getInfoColor({
                date: course.date,
                expirationDate: course.expirationDate ?? undefined,
                isMandatory: course.isMandatory ?? undefined,
                learningObjectType: course.learningObjectType,
                theme: theme
              });
              //const isExpiring = dateIsExpiring({ date: course.date, days: 10 });
              const durationNumber = course.dayCourseType === "learningObject"
                ? parseFloat(`${course.duration}`)
                : NaN;
              return (
                <div key={ `course-${course.id}` }>
                  <Stack direction="row"
                    sx={ { alignItems: "center", height: "34px", justifyContent: "space-between",

                      ".icon-download": {
                        cursor: "pointer",
                        marginLeft: "auto",
                        paddingRight: "10px"
                      } } }
                  >
                    <Stack direction="row" spacing={ 1 } sx={ { alignitems: "center" } }>
                      {
                        (() => {
                          const label = getLabel({
                            date: course.date,
                            expirationDate: course.expirationDate ?? undefined,
                            isMandatory: course.isMandatory ?? undefined,
                            learningObjectType: course.learningObjectType
                          });
                          if(label == "expiring") {
                            return <>
                              <Icon icon={ "Icons_scadenza-e-edizioni" } width={ "1rem" } color={ infoColor } />
                              <InfoTypography color={ infoColor }>{ t("expiring") }</InfoTypography>
                            </>;
                          } else if (label == "mandatory") {
                            return <>
                              <Icon icon={ "Icons_info" } width={ "1rem" } color={ infoColor } />
                              <InfoTypography color={ infoColor }>{ t("mandatory") }</InfoTypography>
                            </>;
                          } else {
                            return null;
                          }
                        })()
                      }
                      { /* isExpiring && course.isMandatory ? (
                        <>
                          <Icon icon={ "Icons_scadenza-e-edizioni" } width={ "1rem" } color={ infoColor } />
                          <InfoTypography color={ infoColor }>{ t("expiring") }</InfoTypography>
                        </>
                      ) : course.isMandatory ? (
                        <>
                          <Icon icon={ "Icons_info" } width={ "1rem" } color={ infoColor } />
                          <InfoTypography color={ infoColor }>{ t("mandatory") }</InfoTypography>
                        </>
                      ) : null
                      */ }
                    </Stack>
                    <Icon
                      className="icon-download"
                      icon={ "download" }
                      width={ "20px" }
                      height={ "20px" }
                      color={ theme.customColors.systemPrimary01 }

                      onClick={ () => {
                        mutateDownloadIcs({
                          // eslint-disable-next-line max-len
                          date: `${day.date[0]}-${format(new Date(day?.date?.toString() || ""), "MM", { locale: locale })}-${format(new Date(day?.date?.toString() || ""), "dd", { locale: locale })}`,
                          events: getEventsObj(day.courses as eventCourse[], true, course.id, course.learningObjectType) }); } }
                    />
                    {
                      !isNaN(durationNumber) && durationNumber > 0 &&
                        <DurationStack direction="row" spacing={ 1 }>
                          <Icon icon={ "Icons_time" } width={ "1rem" }/>
                          <DurationTypography>
                            { Number(durationNumber) > 59 ?
                            // t("hour", { count: minutesToHours(parseFloat(`${course.duration}`)) }) :
                            // t("minutes", { count: parseFloat(`${course.duration}`) })
                              t("hour", { count: minutesToHours(durationNumber) }) :
                              t("minutes", { count: durationNumber })
                            }
                          </DurationTypography>
                        </DurationStack>
                    }
                  </Stack>
                  <Stack
                    direction="row"
                    divider={
                      <Divider
                        orientation="vertical"
                        sx={ {
                          borderColor: theme.customColors.borderTag,
                          height: "auto",
                          marginBottom: "4px !important",
                          marginTop: "4px !important",
                          width: "1px"
                        } }
                      />
                    }
                    spacing={ 1 }
                  >
                    <TypeLabel
                      learningObjectType={ course.learningObjectType }
                      learningObjectTypology={ course.learningObjectTypology ?? undefined }
                    />
                    <LevelLabel learningObjectTypology={ course.learningObjectTypology ?? undefined }/>
                  </Stack>
                  <TitleStack
                    direction={ { sm: "row", xs: "column" } }
                    alignItems={ { sm: "center", xs: "flex-start" } }
                    gap={ isMobile ? "0" : "8px" }
                  >
                    <TitleTypography>{ course.title ?? undefined }</TitleTypography>
                    <ActionButton course={ course }/>
                  </TitleStack>
                </div>
              );
            }) : (
              <NoEventsStack key={ `no-events-${day?.date?.toString()}` }>
                <NoEventsTypography>{ t("no_scheduled_event") }</NoEventsTypography>
              </NoEventsStack>
            )
          }
        </EventsStack>
      </DateContainerStack>
    ) }
  </ContainerStack>;
}

function getLabel({
  date,
  expirationDate,
  isMandatory,
  learningObjectType
} : {
  date: JavaBasicDate | null,
  expirationDate?: JavaBasicDate
  isMandatory?: boolean,
  learningObjectType: "ASYNC" 
  | "BLENDED" | "DIGITAL" | "LIVE" | "LIVE_CLASSROOM" | "SYNC" | "VIRTUAL_CLASSROOM" | "SMART_LEARNING_SLOT",
}) {
  if(isMandatory) {
    if(learningObjectType == "ASYNC" || learningObjectType == "BLENDED" || learningObjectType == "DIGITAL") {  //digital
      if(date && expirationDate && isSameDay(new Date(date.join("/")), new Date(expirationDate.join("/"))))
      // const date = new Date(format(new Date(date ?? ""), "yyyy-MM-dd"));
      // const currentDate = new Date(format(new Date(), "yyyy-MM-dd")); //current date with no hours/minutes;
      // const exp = new Date(expirationDate ?? "");
      // if(compareAsc(datee, exp) > 0)
      {
        return "expiring";
      } else {
        return "";
      }
    } else {
      return "mandatory";
    }
  } else {
    if (
      learningObjectType == "LIVE"
      || learningObjectType == "LIVE_CLASSROOM"
      || learningObjectType == "VIRTUAL_CLASSROOM"
      || learningObjectType == "SYNC"
    ) { //live
      return "";
    }
  }
}

function getInfoColor({
  date,
  expirationDate,
  isMandatory,
  learningObjectType,
  theme
} : {
  date: JavaBasicDate | null
  expirationDate?: JavaBasicDate
  isMandatory?: boolean
  learningObjectType: ContentType
  theme: Theme
}) {
  if(isMandatory) {
    if (
      learningObjectType == "ASYNC"
      || learningObjectType == "BLENDED"
    ) { // digital
      if (
        date && expirationDate
        && isSameDay(new Date(date.join("/")), new Date(expirationDate.join("/")))
      ) {
        return theme.customColors.textWarning;
      } else {
        return theme.customColors.textPrimary;
      }
    } else {
      return theme.customColors.textMandatory;
    }
  } else if (learningObjectType === "SYNC") {
    return theme.customColors.textPrimary;
  }
}

function smartLearningTime(startTime: string, endTime: string) {
  const splittedStartTime = startTime.split(" : ");
  const newStartTime = `${splittedStartTime[0].padStart(2, "0")}:${splittedStartTime[1].padStart(2, "0")}`;
  const splittedEndTime = endTime.split(" : ");
  const newEndTime = `${splittedEndTime[0].padStart(2, "0")}:${splittedEndTime[1].padStart(2, "0")}`;

  return `${newStartTime} - ${newEndTime}`;
}

function formatTime(time: string) {
  const splittedTime = time.split(" : ");
  return `${splittedTime[0].padStart(2, "0")}:${splittedTime[1].padStart(2, "0")}`;
}
