import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { useParams } from "react-router-dom";
import { CardTabsLinkStack } from "./CardTabsLink.styles";
import type { CardTabsAttachmentProps } from "./CardTabsLink.types";
import {
  Icon,
  Link
} from "..";
import { useToolsLinkQuery } from "../../queries";

export function CardTabsAttachment({
  dimension,
  disabled,
  id: attachmentId,
  name,
  shortDescription,
  ...props
}: CardTabsAttachmentProps) {
  const theme = useTheme();
  const { id } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data: toolLink } = useToolsLinkQuery({
    enabled: !disabled,
    learningObjectId: id ?? "",
    toolId: attachmentId
  });

  return (
    <CardTabsLinkStack
      { ...props }
      className={ disabled ? "not-allowed" : "pointer" }
    >
      {
        shortDescription ? (
          <Typography
            fontWeight={ 300 }
            fontSize="1rem"
            lineHeight={ 11 / 8 }
            sx={ {
              [theme.breakpoints.down("sm")]: {
                fontSize:"0.875rem"
              },
              color: theme.customColors.textWidget
            } }
          >
            { shortDescription }
          </Typography>
        ) : null
      }
      <Stack
        alignItems="center"
        gap={ 1 }
        direction="row"
        sx={ {
          ".icon": {
            "path": {
              fill: disabled ? theme.customColors.textDisabled : theme.customColors.textWidget
            }
          }
        } }

      >
        <Link
          color="primary"
          href={ toolLink?.publicUrl }
          download={ Boolean(toolLink?.publicUrl) }
          fontSize={ isMobile ? "0.875rem" : "1.125rem" }
          target={ "_blank" }
          rel="noreferrer"
          style={ {
            color:disabled ? theme.customColors.textDisabled : theme.customColors.textWidget,
            cursor:disabled ? "not-allowed !important" : "pointer",
            pointerEvents:disabled ? "none" : "auto"
          } }
        >
          { name }
        </Link>
        <Icon
          icon={ "download" }
          width={ "20px" }
          height={ "20px" }
          color={ theme.customColors.textWidget }
        />
      </Stack>
      {
        <Typography
          fontSize="0.75rem"
          lineHeight={ 11 / 6 }
          sx={ {
            [theme.breakpoints.down("sm")]: {
              fontSize:"0.75rem"
            },
            color: theme.customColors.textWidget
          } }
        >
          { bytesToMegabytes(Number(dimension)) }
        </Typography>
      }
    </CardTabsLinkStack>
  );
}

function bytesToMegabytes(bytes: number) {
  const mb = bytes / 1000000;
  return `${mb.toFixed(2)} MB`;
}
