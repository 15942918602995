import {
  Box,
  Stack,
  type StackProps
} from "@mui/material";
import {
  alpha,
  styled,
  useTheme
} from "@mui/material/styles";

const CategoryWrapper = styled(Box)(({ theme }) => ({
  background: alpha(theme.customColors.backgroundPrimary, 0.6),
  border: `1px solid ${theme.customColors.borderTag}`,
  borderRadius: "4px",
  height: "fit-content",
  marginBottom: "8px",
  marginRight: "8px",
  maxWidth: "calc(50% - 8px)",
  padding: "8px"
}));

const CategoryText = styled(Box)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize:  "0.75rem",
  fontWeight: 700,
  height: "fit-content",
  lineHeight: 1.2,
  textTransform: "uppercase",

  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "1",
  display: "-webkit-box",
  overflow: "hidden",
  overflowWrap:"break-word",
  textOverflow: "ellipsis"
}));

export function CardCategories({
  categories,
  isHome=false,
  ...props
} : StackProps & {
  categories : string[]
  isHome?: boolean
}) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      width="100%"
      flexWrap={ "wrap" }
      sx={ {
        [theme.breakpoints.down("sm")]: {
          marginTop: isHome ? "-8px" : "0"
        }
      } }
      { ...props }
    >
      { categories && categories?.map((category: string) => (
        <CategoryWrapper
          key={ `category--${category}` }
        >
          <CategoryText>
            { category }
          </CategoryText>
        </CategoryWrapper>
      )) }
    </Stack>
  );
}
