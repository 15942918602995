import image from "../../assets/empty-favorites.png";

/* eslint-disable max-len */
export const Default = {
  coverPublicURL: image,
  shortDescription: "contents_explore",
  title: "favourites_empty"
};

export const HeroCardBookmarkVariants = {
  $topImageVisible: true,
  coverPublicURL: image,
  shortDescription: "contents_explore",
  title: "favourites_empty"
};
/* eslint-enable max-len */
