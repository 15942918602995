import { Box, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

export const Overlay = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  height: "100%",
  width: "100%",
  backgroundColor: alpha(theme.customColors.backgroundPrimary, 0.9),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: 10,
  zIndex: 2999
}));

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.linearGradients.gradientB,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "60vw"
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: 42,
  fontWeight: "bold",
  textAlign: "center",
  color: theme.customColors.textPrimary,
  [theme.breakpoints.down("sm")]: {
    fontSize: 32
  }
}));

export const Message = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  textAlign: "center",
  color: theme.customColors.textPrimary,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14
  }
}));

export const Divider = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: "60%"
}));
