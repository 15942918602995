import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  CardGrid,
  CardBodyGrid,
  DescriptionTypography,
  ImageBox,
  TitleTypography
} from "./Card.styles";
import { CardProps } from "./Card.types";
import {
  CardCta,
  CardCategories,
  CardSignature,
  CardType,
  Link
} from "../../components";
import { TruncatedText } from "../TruncatedText";

export function Card({
  categories,
  coverPublicURL,
  duration,
  ecmRegistration,
  ecmType,
  editionNumber,
  endDate,
  enrollType,
  expirationDate,
  grandParentId,
  id,
  ecm_specialization,
  isSurvey,
  isTest,
  learningActivityNumber,
  learningObjectType,
  learningObjectTypology,
  parentId,
  percentageOfCompletion,
  resources,
  shortDescription,
  startDate,
  status,
  title,
  canAccessNow,
  credits,
  ...props
}: CardProps) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CardGrid container { ...props }>
      { !!isMobile &&
        <CardBodyGrid item>
          <CardType
            color={ theme.customColors.textPrimary }
            ecmType={ ecmType }
            ecm_specialization={ ecm_specialization }
            isTest={  Boolean(isTest) }
            isSurvey={ Boolean(isSurvey) }
            learningObjectTypology={ learningObjectTypology }
            learningObjectType={ learningObjectType }
            sx={ {
              fontSize: "0.75rem",
              fontWeight: "700",
              lineHeight: "0.875rem"
            } }
          />
          <TitleTypography variant="h4">
            <TruncatedText text={ title } maxLength={ 35 } />
          </TitleTypography>
          { categories && categories?.length > 0 ?
            <CardCategories categories={ categories  }/> : null
          }
        </CardBodyGrid>
      }
      <Grid
        item
        xs={ false }
        width={ isMobile ? "156px" : "313px" }
        margin={ "0 0 8px" }
        position={ "relative" }
        height={ isMobile ? "117px" : "236px" }
        sx={ {
          filter: "drop-shadow(25px 25px 80px rgba(21, 21, 21, 0.4))",
          [theme.breakpoints.down("sm")]: {
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            marginTop: "8px",
            padding: "0 !important",
            width: "100%"
          }
        } }
      >
        <Link href={ `/esplora/dettaglio/${id}/${learningObjectTypology}` } state={ {
          courseId: parentId,
          rootId:grandParentId
        } }>
          <ImageBox
            alt="card cover"
            component="img"
            src={ coverPublicURL }
          />
        </Link>
        { isMobile && <CardSignature
          editionNumber={ editionNumber }
          startDate={ startDate }
          endDate={ endDate }
          learningActivityNumber={ learningActivityNumber }
          learningObjectTypology={ learningObjectTypology }
          duration={ duration }
          direction="column"
          justifyContent={ "flex-end" }
          gap={ 1 }
          resources={ resources || 0 }
          xs={ false }
          expirationDate={ expirationDate }
          canAccessNow={ canAccessNow }
          sx={ {
            fontSize: "0.75rem",
            marginLeft: "16px !important"
          } }
        /> }
      </Grid>
      <CardBodyGrid item xs={ isMobile? 12 : 6.5 } container>
        { !isMobile &&
          <CardType
            color={ theme.customColors.textPrimary }
            isTest={ Boolean(isSurvey) }
            isSurvey={ Boolean(isTest) }
            learningObjectTypology={ learningObjectTypology }
            learningObjectType={ learningObjectType }
          />
        }
        {  !isMobile &&
          <TitleTypography variant="h3">
            <TruncatedText text={ title } maxLength={ 35 } />
          </TitleTypography>
        }

        { categories && !isMobile && categories?.length > 0 ?
          <CardCategories categories={ categories  }/> : null
        }

        <DescriptionTypography>
          <TruncatedText text={ shortDescription } maxLength={ 60 } />
        </DescriptionTypography>
        <CardCta
          ecmRegistration={ ecmRegistration }
          ecm_specialization={ ecm_specialization }
          disableFavourites={ Boolean(isTest || isSurvey) }
          enrollType={ enrollType }
          id={ id }
          isCard={ true }
          learningObjectType={ learningObjectType }
          learningObjectTypology={ learningObjectTypology }
          percentageOfCompletion={ percentageOfCompletion }
          status={ status }
          parentId={ parentId }
          grandParentId={ grandParentId }
          canAccessNow={ canAccessNow }
          credits={ credits }
        />
      </CardBodyGrid>
      { !isMobile && <CardSignature
        editionNumber={ editionNumber }
        startDate={ startDate }
        endDate={ endDate }
        color={ theme.customColors.textPrimary }
        learningActivityNumber={ learningActivityNumber }
        learningObjectTypology={ learningObjectTypology }
        duration={ duration }
        direction="column"
        justifyContent={ "flex-end" }
        gap={ 1 }
        resources={ resources || 0 }
        xs={ 2 }
        expirationDate={ expirationDate }
        canAccessNow={ canAccessNow }
      /> }
    </CardGrid>
  );
}
