import {
  Box,
  Grid,
  Typography,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputGroupProps, Option } from "./types";
import { Button } from "../../Button/Button.component";
import { Icon } from "../../Icon";

const InputGroup: React.FC<InputGroupProps> = ({
  label,
  fields,
  value = [],
  onChange,
  disabled = false,
  onFieldChange
}) => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const [currentGroup, setCurrentGroup] = useState<Record<string, { value: string | number; label?: string }>>({ });
  const [dynamicOptions, setDynamicOptions] = useState<{
    [key: string]: Option[];
  }>({ });

  const handleFieldChange = async (
    fieldKey: string,
    fieldValue: number | string,
    fieldLabel?: string
  ) => {
    setCurrentGroup(prevGroup => ({
      ...prevGroup,
      [fieldKey]: {
        value: fieldValue,
        label: typeof fieldLabel === "string" ? fieldLabel : String(fieldLabel)
      }
    }));
  };

  const handleAddGroup = () => {
    if (Object.keys(currentGroup).length > 0) {
      onChange([...value, currentGroup]);
      setCurrentGroup({ });
      setDynamicOptions({ });
    }
  };

  console.log("group", value);

  const handleDeleteGroup = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  return (
    <Box
      sx={ {
        border: `1px solid ${ theme.customColors.border }`,
        padding: "16px",
        borderRadius: "4px"
      } }
    >
      { label && (
        <Typography
          variant="h6"
          gutterBottom
          sx={ {
            marginBottom: "16px",
            marginLeft: "10px",
            color: theme.customColors.textPrimary
          } }
        >
          { label }
        </Typography>
      ) }
      <Grid container spacing={ 2 }>
        { fields.map((field) => {
          const options =
            field.type === "select"
              ? dynamicOptions[field.accessorKey] || field.options || []
              : undefined;

          return (
            <Grid key={ field.accessorKey } item xs={ 12 } md={ 6 }>
              { field.type === "text" && (
                <TextField
                  label={ field.labelHeader[i18n.language] || field.label }
                  value={ currentGroup[field.accessorKey]?.value || "" }
                  onChange={ (e) =>
                    handleFieldChange(field.accessorKey, e.target.value)
                  }
                  required={ field.required }
                  fullWidth
                  disabled={ disabled }
                  sx={ {
                    "& .MuiInputBase-root": {
                      color: theme.customColors.textPrimary,
                      backgroundColor: theme.customColors.backgroundPrimary
                    }
                  } }
                />
              ) }
              { field.type === "select" && (
                <FormControl
                  fullWidth
                  disabled={ Boolean(
                    disabled ||
                      (field.dependentOn && !currentGroup[field.dependentOn]) ||
                      options?.length === 0
                  ) }
                  sx={ {
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.customColors.border
                      }
                    },
                    "& .Mui-focused.MuiInputLabel-shrink": {
                      color: theme.customColors.borderPrimaryCta
                    },
                    "& .MuiFormLabel-root": {
                      color: theme.customColors.textPrimary
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme.customColors.textPrimary
                    },
                    "& .ddihpQ.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.customColors.border
                    },
                    "& .kjpuir.Mui-disabled": {
                      color: theme.customColors.textDisabled
                    }
                  } }
                >
                  <InputLabel>
                    { field.labelHeader[i18n.language] || field.label }
                  </InputLabel>
                  <Select
                    value={ currentGroup[field.accessorKey]?.value || "" }
                    inputProps={ { notched: true } }
                    label={ field.labelHeader[i18n.language] || field.label }
                    onChange={ (e) => {
                      const selectedOption = options!.find(
                        (option) => option.value === e.target.value
                      );
                      handleFieldChange(
                        field.accessorKey,
                        e.target.value,
                        selectedOption?.label
                      );
                      if (!field.dependentOn) onFieldChange(e.target.value);
                    } }
                    required={ field.required }
                    sx={ {
                      "&.MuiInputBase-root": {
                        color: theme.customColors.textPrimary,
                        "&.Mui-focused fieldset": {
                          borderColor: theme.customColors.border
                        }
                      },
                      "&.MuiInputBase-root.Mui-focused fieldset": {
                        borderColor: theme.customColors.borderPrimaryCta
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme.customColors.textPrimary
                      }
                    } }
                  >
                    { options &&
                      options.map((option) => (
                        <MenuItem key={ option.value } value={ option.value } sx={ {
                          color: theme.customColors.textPrimary,
                          backgroundColor: theme.customColors.backgroundPrimary
                        } }>
                          { option.label }
                        </MenuItem>
                      )) }
                  </Select>
                </FormControl>
              ) }
            </Grid>
          );
        }) }
        <Grid item xs={ 12 } sx={ { textAlign: "end" } }>
          <Button
            variant="contained"
            onClick={ Object.keys(currentGroup).length ? handleAddGroup : () => { } }
            startIcon={
              <Icon
                icon="Icons_add"
                size={ 15 }
                color={ !Object.keys(currentGroup).length ? theme.customColors.textDisabledAlternative : theme.customColors.textPrimaryCta }
              />
            }
            disabled={ !Object.keys(currentGroup).length }
            sx={ {
              marginTop: "16px",
              [theme.breakpoints.down("sm")]: { width: "100%" }
            } }
          >
            { t("add") }
          </Button>
        </Grid>
      </Grid>

      { value && value.length > 0 && (
        <Box mt={ 2 }>
          { value.map((group: Record<string, { value: string | number; label?: string }>, index: number) => (
            <Box
              key={ index }
              sx={ {
                display: "flex",
                alignItems: "start",
                gap: "5px",
                justifyContent: "space-between",
                padding: "8px",
                borderTop: `1px solid ${ theme.customColors.borderTag }`,
                marginBottom: "8px",
                color: theme.customColors.textPrimary,
                backgroundColor: theme.customColors.backgroundPrimary
              } }
            >
              { fields.map((field) => {
                if (group[field.accessorKey]?.value)
                  return (
                    <Typography
                      key={ field.accessorKey }
                      sx={ {
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        flex: 1
                      } }
                    >
                      <strong>
                        { field.labelHeader[i18n.language] || field.label }:
                      </strong>{ " " }
                      { group[field.accessorKey]?.label ||
                        group[field.accessorKey]?.value }
                    </Typography>
                  );
              }) }
              <Button
                onClick={ () => handleDeleteGroup(index) }
                sx={ {
                  p: 0,
                  minWidth: "auto",
                  height: "24px",
                  [theme.breakpoints.down("sm")]: {
                    order: 3,
                    alignSelf: "flex-end"
                  }
                } }
              >
                <Icon
                  icon="Icons_delete"
                  size={ 15 }
                  color={ theme.customColors.backgroundPrimaryCta }
                />
              </Button>
            </Box>
          )) }
        </Box>
      ) }
    </Box>
  );
};

export default InputGroup;
