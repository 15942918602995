import {
  Grid,
  GridProps,
  Stack,
  StackProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ElementType } from "react";
import { OverlayStack } from "../CtaOverlay/CtaOverlay.styles";

export const DetailTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  lineHeight: 1.1875,
  marginBottom:"11px",
  maxWidth:"75%",

  "-webkit-box-orient": "vertical",
  "-webkit-line-clamp": "3",

  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",

  [theme.breakpoints.down("sm")]: {
    fontSize:"0.875rem",
    marginBottom:"10px",
    maxWidth:"100%"
  }
}));

export const HeroBannerStack = styled(Stack)<
  & StackProps
  & {
    component: ElementType } // monkeypatch to fix property 'component' does not exist on type 'intrinsicattributes'
>(({ theme }) => ({

  justifyContent:"flex-end",
  minHeight: "750px",
  position:"relative",
  width: "100%",

  margin:"auto",
  maxWidth: "1440px",

  "> * ":{
    zIndex:1
  },

  ".bookmark":{
    display:"none"
  },
  ".button-back": {
    color: theme.customColors.systemPrimary01,
    fontSize:"1.141rem",
    fontWeight:"400",
    gap:"9px",
    minWidth:"unset",
    padding:"0",
    position:"absolute",

    left:"42px",
    top:"32px",

    ".icon":{
      height:"18px",
      width:"13px"
    },

    [theme.breakpoints.down("sm")]: {
      left: "20px",
      minWidth: "auto",
      top: "16px"
    }
  },
  ".card-signature .icon": {
    height:"24px",
    width:"24px"
  },
  ".status-box": {
    left:"-42px",
    marginBottom:"42px",
    position:"relative",
    top:"50",

    height:"60px",
    width:"60px"

  },
  ".type": {
    fontSize: "1.25rem",
    fontWeight: "700",
    lineHeight:"1.05",
    marginBottom:"5px",
    textTransform:"upperCase"
  },

  "& .MuiChip-root": {
    border: `2.925px solid ${theme.customColors.borderTag}`,
    borderRadius: "0.4875rem",

    "& span": {
      fontSize: "1.125rem",
      fontWeight: "700",
      letterSpacing: "1.95px",
      lineHeight: "0.9375rem"
    }
  },

  [theme.breakpoints.down("sm")]: {
    //background: `url(${coverPublicURL})`,
    //backgroundRepeat:"no-repeat",
    //backgroundSize: "contain",
    gap:theme.spacing(1),
    minHeight:"514px",

    ".button-back": {
      fontSize:"0.75rem",
      left:"20px",

      ".icon":{
        height:"15px",
        width:"11px"
      }
    },
    ".card-cta":{
      ">div":{
        alignItems: "flex-start",
        flexDirection: "column",
        gap: theme.spacing(1),
        maxWidth:"248px",
        width:"100%",

        "a": {
          marginTop: theme.spacing(1)
        }
      }
    },
    ".card-signature .icon": {
      height:"20px",
      width:"20px"
    },
    ".status-box": {
      height:"40px",
      width:"40px",

      left:"-20px",

      ".icon":{
        height:"19px",
        width:"19px"
      }

    },
    ".type":{
      fontSize:"1rem"
    },

    [`${OverlayStack}`]:{
      marginTop:"18px"
    }

  }

}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  fontSize: "3.5rem",
  fontWeight:"700",
  lineHeight: 1.125,
  maxWidth:"50%",

  [theme.breakpoints.down("sm")]: {
    fontSize:"1.5rem",
    maxWidth:"100%"
  }
}));

export const StyledBadgeHero = styled(Grid)<GridProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.customColors.backgroundAccent,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  color: theme.customColors.textPrimaryAlternative,
  display: "flex",
  fontSize:theme.spacing(3),
  fontWeight:500,
  gap: "1rem",
  justifyContent: "center",
  left: "0",
  lineHeight: theme.spacing(3.75),
  padding: "15px 24px",
  position: "absolute",
  width: "fit-content",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    padding: "8px 16px"
  }
}));

export const StyledIconLock = styled(Grid)(() => ({
  display: "flex",
  height:"fit-content",
  width: "fit-content",

  inset:"0",
  position: "absolute",

  margin:"auto"

}));
