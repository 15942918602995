import { Stack, StackProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonLink } from "../../components";

export const NotFoundStack = styled(Stack)<
  StackProps & { bg?: string }
>(({ bg, theme }) => ({
  background: bg ? `${theme.linearGradients.gradientD}, url(${bg})` : `${theme.linearGradients.gradientD})`,
  height:"100vh",
  maxWidth: "calc(100vw - (100vw - 100%))",
  minWidth: "100%",
  paddingTop: theme.spacing(0.5)
}));

export const StyledButtonLink = styled(ButtonLink)(({ theme }) => ({
  color: theme.customColors.textPrimary,
  fontSize: "1.141rem",
  fontWeight: 400,
  gap: theme.spacing(1),
  justifyContent: "flex-start",
  padding: "32px 42px 37px",
  textAlign: "start",

  "&:hover":{
    background:"transparent"
  }
}));
