import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  ButtonLink,
  Icon,
  SignupSummaryCapabilities,
  SignupSummaryKnowledge

} from "../../components";
import { ONBOARDING_TYPE } from "../../consts";
import { useAuthStore } from "../../stores";
import type { onBoardingType } from "../../types";


export function ContentKnowledge (){

  const corporateInfo = useAuthStore(state => state.corporateInfo);
  const userInfo = useAuthStore(state => state.userInfo);

  const { t }=useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const userCapabilities = userInfo?.capabilities;
  const userPersonal = userInfo?.personal_domains;
  const userExecutives = userInfo?.executive_domains;

  const onBoardingType = setOnboardingType({
    capabilities:Boolean(corporateInfo?.onboarding_executive_job),
    executives:Boolean(corporateInfo?.onboarding_executive_job),
    personal:Boolean(corporateInfo?.onboarding_personal_job)
  });


  return (
    <>

      {
        corporateInfo?.onboarding_executive_job ?
          <SignupSummaryKnowledge
            isProfilePage={ true }
            domains={ userInfo?.executive_domains }
            description={ t("your_knowledge_description") }
            linkModify="/signup/skills"
            title={ t("your_executive_knowledge") }
            onboardingAddText={
              (
                Boolean(userInfo?.personal_domains && userInfo?.personal_domains.length) ||
                Boolean( userInfo?.capabilities && userInfo.capabilities.length)
              ) ?  t("go_personalize") : undefined
            }
            noItemsText={ t("no_executive_knowledge_text") }
            noItemsToShow={  !(userInfo?.executive_domains && userInfo?.executive_domains?.length) }
          /> : null

      }

      {
        corporateInfo?.onboarding_personal_job ?
          <SignupSummaryKnowledge
            isProfilePage={ true }
            domains={ userInfo?.personal_domains }
            description={ t("your_knowledge_description") }
            linkModify="/signup/skills"
            title={ t("your_personal_knowledge") }
            onboardingAddText={
              (
                Boolean(userInfo?.executive_domains && userInfo?.executive_domains.length) ||
                Boolean( userInfo?.capabilities && userInfo.capabilities.length)
              ) ?  t("go_personalize") : undefined
            }
            noItemsText={ t("no_personal_knowledge_text") }
            noItemsToShow={  !(userInfo?.personal_domains && userInfo?.personal_domains?.length) }
          /> : null
      }

      {
        corporateInfo?.onboarding_capability ?
          <SignupSummaryCapabilities
            isProfilePage={ true }
            capabilities={ userInfo?.capabilities }
            onboardingAddText={
              (
                Boolean(userInfo?.executive_domains && userInfo?.executive_domains.length) ||
              Boolean( userInfo?.personal_domains && userInfo?.personal_domains.length)
              ) ?  t("go_personalize") : undefined
            }
            noItemsText={ t("no_capabilities_text") }
            noItemsToShow={  !(userInfo?.capabilities && userInfo?.capabilities.length) }
          /> : null
      }



      {

        setModifyEnabled(onBoardingType)   ?
          <Stack
            flexDirection={ "column" }
            maxWidth={ isMobile ? "100%" : "60%" }
          >
            <Typography
              fontSize={ isMobile ? "0.875rem !important" : "1.25rem" }
              fontWeight={ 400 }
              lineHeight={ isMobile ? (21 / 16) : 1.05 }
              width={ "80%" }
            >
              { t("no_knowledge_text") }
            </Typography>

            <ButtonLink
              href={ "/signup/skills" }
              sx={ {
                fontSize:isMobile ? "0.75rem" : theme.spacing(2.25),
                fontWeight: 500,
                gap:theme.spacing(1.25),
                justifyContent:"flex-start",
                marginTop: "16px",
                padding:0,
                width:"100%"
              } }

            >
              { t("go_personalize") }
              <Icon
                icon={ "arrow_right_horizontal" }
                size={ 20 }
                color={ theme.customColors.backgroundPrimaryCta }/>
            </ButtonLink>
          </Stack> :
          null
      }

    </>
  );

  function setModifyEnabled(type:onBoardingType){
    switch(type){
    case ONBOARDING_TYPE.ALL :
      return !userCapabilities?.length && !userExecutives?.length && !userPersonal?.length;
    case ONBOARDING_TYPE.CAPABILITIES_EXECUTIVES:
      return !userCapabilities?.length && !userExecutives?.length;
    case ONBOARDING_TYPE.PERSONAL_EXECUTIVES:
      return !userExecutives?.length && !userPersonal?.length;
    case ONBOARDING_TYPE.PERSONAL_CAPABILITIES:
      return !userCapabilities?.length  && !userPersonal?.length;
    default:  return false;

    }
  }
  function setOnboardingType(
    {
      capabilities,
      executives,
      personal
    }:
    {
      personal:boolean,
      capabilities:boolean,
      executives:boolean
}){
    if(capabilities && executives && personal){
      return ONBOARDING_TYPE.ALL as onBoardingType;
    }
    else if(!capabilities && executives && personal ){
      return ONBOARDING_TYPE.PERSONAL_EXECUTIVES as onBoardingType;
    }
    else if(!executives && capabilities && personal){
      return ONBOARDING_TYPE.PERSONAL_CAPABILITIES as onBoardingType;
    }
    else if(!personal && executives && capabilities){
      return ONBOARDING_TYPE.CAPABILITIES_EXECUTIVES as onBoardingType;
    }
    else return ONBOARDING_TYPE.ALL as onBoardingType;
  }
}
