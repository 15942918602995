import {
  useQuery,
  useQueryClient,
  type QueryKey
} from "@tanstack/react-query";
import { ERRORS } from "../consts";
import {
  apiAdapter,
  fetcherFactory
} from "../services";
import { useAuthStore } from "../stores";
import type {
  LearningActivity,
  Level
} from "../types";
import { getTimezoneFormattedDate } from "../utils/datetime";
import {
  getDetailsPath,
  getEnvironmentVariables
} from "../utils/general";

const { basePath } = getEnvironmentVariables();

export function useStartOrResumeQuery({
  enabled=true,
  learningObjectId,
  learningObjectTypology,
  parentId="",
  queryKey=[]
} : {
  enabled?: boolean
  learningObjectId: number
  learningObjectTypology: Level
  parentId?: string
  queryKey?: string[]
}) {
  const fetcher = fetcherFactory();

  const corporateId = useAuthStore(state => state.userData?.organization_id);
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";
  const setUnauthorized = useAuthStore(state => state.setUnAuthorized);

  const objectType = getDetailsPath(learningObjectTypology).path;

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learningObjects/${learningObjectId}/startOrResume`;
  const params = `parentId=${parentId}`;

  return useQuery<
    unknown,
    unknown,
    LearningActivity,
    QueryKey
  >({
    enabled: Boolean(
      enabled
      && accessToken
      && corporateId
      && initiativeId
      && learningObjectId
      && objectType
      && sessionToken
    ),
    queryFn: () => fetcher(
      `${basePath}${uri}?${params}`,
      {
        body: JSON.stringify({
          "objectType": objectType
        }),
        headers: {
          "authorization": accessToken,
          "x-ada-session-token": sessionToken,
          "x-user-timezone": getTimezoneFormattedDate()
        },
        method: "POST"
      })
      .then((res) => {
        return res.json();
      })
      .then((data: LearningActivity) => (
        apiAdapter([data])[0]
      ))
      .catch((error) => {
        if (error === ERRORS.UNAUTHORIZED) {
          setUnauthorized(true);
          throw error; // rethrow so that react query doesn't complain about undefined return value
        } else {
          throw error;
        }
      }),
    queryKey: [accessToken, sessionToken, uri, params, objectType, ...queryKey]
  });
}

export function useInvalidateStartOrResumeQuery({
  learningObjectId,
  learningObjectTypology,
  parentId="",
  queryKey=[]
} : {
  learningObjectId: string
  learningObjectTypology: Level | null
  parentId?: string
  queryKey?: string[]
}) {
  const queryClient = useQueryClient();
  const corporateId = useAuthStore(state => state.userData?.organization_id);
  const accessToken = useAuthStore(state => state.session?.getAccessToken().getJwtToken()) ?? "";
  const initiativeId = useAuthStore(state => state.userData?.initiative_id);
  const sessionToken = useAuthStore(state => state.sessionToken) ?? "";

  const objectType = learningObjectTypology ? getDetailsPath(learningObjectTypology).path : null;

  const uri = `/learning-catalogue/${corporateId}/${initiativeId}/learningObjects/${learningObjectId}/startOrResume`;

  const params = `parentId=${parentId}`;

  const invalidateQueryStartOrResume = ()=> {
    queryClient.invalidateQueries({
      queryKey: [accessToken, sessionToken, uri, params, objectType, ...queryKey]
    });
  };

  return { invalidateQueryStartOrResume };
}
