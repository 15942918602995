import { useTheme } from "@mui/material/styles";
import {
  useEffect, useState
} from "react";
import { useLocation } from "react-router";
import {
  ScrollToTopButton,
  ScrollToTopButtonContent,
  ScrollToTopButtonIcon
} from "./ScrollToTopButton.styles";

export function ScrollToTop(){
  const { pathname } = useLocation();
  const theme = useTheme();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    // if the browser is able to handle scroll restoration
    // on its own steal control from it :D
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      setIsButtonVisible((window.scrollY > 0));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    });
  };

  return (
    <ScrollToTopButton onClick={ scrollToTop } theme={ theme } isVisible={ isButtonVisible }>
      <ScrollToTopButtonContent theme={ theme }>
        <ScrollToTopButtonIcon icon={ "arrow_widget_open" } size={ 19 } theme={ theme }/>
      </ScrollToTopButtonContent>
    </ScrollToTopButton>
  );
}

