import { Stack, Typography, Box, BoxProps } from "@mui/material";
import { common } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import { HTMLProps } from "react";

export const ImageBox = styled(Box)<
  BoxProps & HTMLProps<HTMLImageElement>
>(({ theme }) => ({
  aspectRatio: "1.06",
  borderRadius:"4px 0 0 4px",
  //boxShadow:"16px 16px 22px rgba(0, 0, 0, 0.4)",
  objectFit: "cover",
  padding:  "6px 0",
  paddingBlock: " 0 !important",

  height: "auto",
  width: "5.375rem",

  [theme.breakpoints.down("sm")]: {
    width: "2.5rem"
  }
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: 7 / 6,
  overflow: "hidden",
  textOverflow: "ellipsis",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,
  whiteSpace: "nowrap",
  zIndex: 1100,

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
    lineHeight: "0.875rem"
    // margin: "auto 0",
    // position: "absolute",
    // top: "-1rem"
  }
}));

export const ProgressTypography = styled(Typography)(({ theme }) => ({
  ".primary": {
    color: theme.palette.primary?.main
  },
  color: theme.customColors.textPrimary,
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "0.875rem",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`,
  [theme.breakpoints.down("sm")]: {
    margin: "auto 0",
    position: "absolute",
    top: "-1rem"
  }

  // "& + div span span": {
  //   boxShadow: `0px 0px 4px ${theme.customColors.systemSecondary01}`
  // }
}));

export const LabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.customColors.textWidget,
  fontSize: "1rem",
  fontWeight: "700",
  lineHeight: "1.5625rem",
  textShadow: `0 0 1.25rem ${alpha(theme.palette.background?.paper ?? common["black"], 0.25)}`
}));

export const InfoStack = styled(Stack)(({ theme }) => ({
  //margin: "auto 0.4rem",
  padding: theme.spacing(1),
  width: "calc(100% - 102px)",

  "& > div:first-child" : {
    minHeight: "20px"
  },

  "& .MuiChip-root" : {
    height: "auto",
    padding: "0",
    "span": {
      fontSize: "0.625rem",
      letterSpacing: "1px",
      padding: theme.spacing(0.5, 1)
    }
  },

  "& > div:last-child" : {
    "p": {
      display: "none",
      fontSize: "0.75rem",
      "span": { fontSize: "0.75rem" }
    },
    "span": { height: "0.25rem" }
  },
  [theme.breakpoints.down("sm")]: {
    overflow: "auto",
    padding: theme.spacing(0.25, 0.75),
    width: "100%",


    "& > div:last-child": {
      // position: "absolute",
      // top: "1.3rem",
      // width: "100%"
    },
    "& > div:last-child p": {
      display: "none"
    },
    position: "relative"
  }

}));

export const CourseStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "8px",
  boxShadow: "0px 5px 20px 0px #00000029",
  cursor: "pointer",
  width: "100%"
}));

export const KeepLearningStack = styled(Stack)(({ theme }) => ({
  background: theme.linearGradients.gradientB,
  borderRadius: "0.5rem",
  overflowX: "hidden",
  padding: theme.spacing(3, 2, 2.875, 2),
  [theme.breakpoints.down("sm")]: {
    padding: "16px"
  },

  "& span span": {
    boxShadow: `0px 0px 4px ${theme.customColors.systemSecondary01}`
  },

  "& .swiper-initialized": {
    overflow: "visible"
  },

  "& .swiper-pagination": {
    bottom: "0px !important"
  },

  ".swiper-slide-next, .swiper-slide-prev": {
    opacity: "0",
    transition: "opacity 500ms"
  },

  ".swiper-slide-active": {
    opacity: "1"
  }
}));
