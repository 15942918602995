import { Tooltip, Typography } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface TruncatedTextProps {
  text: string;
  maxLength: number;
  showTooltip?: boolean;
  href?: string;
}

export function TruncatedText ({ text, maxLength, showTooltip = true, href }: TruncatedTextProps) {

  const [showMobileTooltip, setShowMobileTooltip] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Tooltip
      title={ (showTooltip && text.length > maxLength) ? (
        <Typography sx={ { fontSize: "14px" } }>{ text }</Typography>
      ) : null }
      placement={ "top" }
      open={ showMobileTooltip }
      onOpen={ () => setShowMobileTooltip(true) }
      onClose={ () => setShowMobileTooltip(false) }
      PopperProps={ { style: { pointerEvents: "none", zIndex: 10001 } } }
      slotProps={ {
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -5]
              }
            }
          ]
        },
        tooltip: {
          style: {
            backgroundColor: alpha(theme.customColors.backgroundPrimary, 0.9),
            color: theme.customColors.textPrimary
          }
        }
      } }
    >
      <span role={ "presentation" } onClick={ () => {
        setShowMobileTooltip(true);
        if(href) navigate(href);
      } } style={ { cursor: (href) ? "pointer" : "inherit" } }>
        { text.length > maxLength ? `${text.slice(0, maxLength)}...` : text }
      </span>
    </Tooltip>
  );
}
