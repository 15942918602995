import { Box, Grid, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import type { DescriptionProps } from "./Description.types";
import {
  CardCategories,
  CardSignature,
  Icon
} from "../../components";

import { useType } from "../../hooks";
import { TypographyDescription } from "../../styles/global_styles";
import { TitleTypography } from "../Card/Card.styles";
import { TruncatedText } from "../TruncatedText";

const StyledDescriptionComponent = styled(Grid)(({ theme })=> (
  {

    display:"flex",
    flexDirection:"column",
    gap:"1rem",

    ">span":{
      color:theme.customColors.accentVariantA,
      fontSize:"1.25rem",
      fontWeight:"700",
      textTransform:"uppercase"
    },

    [theme.breakpoints.down("sm")]:{
      gap:".5rem",
      "span":{
        fontSize:"0.95rem"
      },
      [`${TitleTypography}`]:{
        fontSize:"1.25rem"
      }
    }
  }

));

export function Description({
  badge,
  certificate,
  categories,
  date,
  duration,
  isStandAlone,
  learningObjectType,
  learningObjectTypology,
  shortDescription,
  title,
  canAccessNow
}: DescriptionProps) {

  const typeObjectLearning = useType(learningObjectType, learningObjectTypology);
  const theme = useTheme();
  const navigate=useNavigate();
  /*  const learningObjectTypologyType = useLevel(learningObjectTypology);*/

  return (
    <StyledDescriptionComponent>
      {
        isStandAlone ?
          <>
            <span>{ typeObjectLearning }</span>
            <TitleTypography variant="h3">
              <TruncatedText text={ title ?? "" } maxLength={ 35 } />
            </TitleTypography>
            { categories && categories?.length > 0 ?
              <CardCategories categories={ categories  }/> : null
            }
          </>

          :
          null
      }
      {
        (duration || date) ? (
          <CardSignature
            xs={ 0 }
            direction="row"
            gap={ "28px" }
            justifyContent={ "flex-start" }
            duration={ duration }
            expirationDate={ date }
            learningActivityNumber={ 0 }
            learningObjectTypology={ learningObjectTypology }
            resources={ 0 }
            canAccessNow={ canAccessNow }
          />
        ) : null
      }

      <Grid
        alignItems={ "center" }
        display={ "flex" }
        position={ "relative" }
        gap={ 2 }
      >

        {

          (badge || certificate) ?

            <Box
              onClick={ (e)=> {
                e.preventDefault();
                if(badge?.isEnable || certificate?.isEnable){
                  navigate("/profile");
                }
                else {
                  e.preventDefault();
                }

              } }
              sx={ {
                cursor: (badge?.isEnable || certificate?.isEnable) ? "pointer" :"not-allowed",
                position:"relative"
              } }
            >
              <Icon
                color={ (badge?.isEnable || certificate?.isEnable) ?
                  theme.customColors.systemPrimary02 :
                  theme.customColors.systemPrimary01 }
                icon={ "Icons_security_03" }
                size={ 24 }
                style={ {
                  marginLeft: theme.spacing(0.2)
                } }

              />

            </Box>  :

            null
        }
      </Grid>


      <TypographyDescription>
        <TruncatedText text={ shortDescription ?? "" } maxLength={ 60 } />
      </TypographyDescription>
    </StyledDescriptionComponent>

  );
}
